import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IThreatCampaignsProps {
  id: string;
}

export const getThreatCampaign = ({ id }: IThreatCampaignsProps) => {
  return Brm.threatCampaignApi.getThreatCampaignByID(id);
};

interface IUseThreatCampaign {
  id: string;
  options?: QueryOption<typeof getThreatCampaign>;
}

/**
 * React-query hook for getting threat data source
 *
 * @param {string} id -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatCampaign = ({ id, options }: IUseThreatCampaign) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatCampaign.campaign(id),
    queryFn: () => getThreatCampaign({ id }),
  });
};

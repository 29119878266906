import * as React from "react";

import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { UploadButton, LoadingSpinner as Loading, ErrorBanner, ButtonSpacer, ButtonWrapper } from "components/elements";

import { TYPE, COMMON } from "constants/brm";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import GenericModal from "components/modals/GenericModal";
import { AdminApi, DefenseApi } from "features/brm";

import {
  createColumnName,
  createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";

export const DefendCatalogTable = ({ setSelectedRows, setUploadModalIsOpen }: any) => {
  const { isThreatAnalyst } = useRoles();

  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [alertOpen, setAlertOpen] = React.useState(false);
  // const [editMode /* , setEditMode */] = React.useState(false);
  const updatedName = React.useRef("");

  const { data: selfData } = AdminApi.useSelf({ options: {} });
  const { data, error } = DefenseApi.useDefendCatalogs();
  // const { data: data2, error: error2 } = DefenseApi.useDefensiveTactics();

  const createButton = React.useCallback(
    (cellProps) => {
      const currentRow = cellProps.cell.row.original;
      if (isThreatAnalyst && selfData) {
        if (currentRow.id === COMMON.defaultUuid) {
          return (
            <ButtonWrapper>
              {/* <ButtonSpacer /> */}
              {/* {exportButton} */}
              <ButtonSpacer />
            </ButtonWrapper>
          );
        }
        return (
          <ButtonWrapper>
            {/* {exportButton} */}
            {(selfData.organization === COMMON.defaultUuid && currentRow.organization.id === COMMON.defaultUuid) ||
            selfData.organization === currentRow.organization.id ? (
              <UploadButton onClick={() => setUploadModalIsOpen(true)} />
            ) : null}
            {/* 
            <UploadButton
              onClick={() => {
                setUploadModalIsOpen(true);
              }}
            /> */}
          </ButtonWrapper>
        );
      }
      return COMMON.na;
    },
    [isThreatAnalyst, selfData, setUploadModalIsOpen]
  );

  const columns = React.useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, null, updatedName, "mitreD3fend"),
      createColumnMappedNoEdit("org"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, createButton]
  );

  const hideCheckBoxCondition = React.useCallback(
    (row) => {
      if (selfData) {
        // hide if we are the builtin catalog && we aren't in administration org...
        return row.original.organization.id === COMMON.defaultUuid && selfData.organization !== COMMON.defaultUuid;
      }
      return true;
    },
    [selfData]
  );

  if (error) {
    return <ErrorBanner msg="Error while loading D3FEND catalog" />;
  }

  if (data) {
    return (
      <>
        {alertOpen && (
          <GenericModal
            elementId="DefendCatalogTable_alertModal"
            modalIsOpen={alertOpen}
            setModalIsOpen={setAlertOpen}
            headerText="Message"
            bodyText="Please select a D3FEND Catalog."
          />
        )}

        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "DefendCatalogTable_table" }}
            elementName={TYPE.mitreD3fend}
            hideCheckBoxCondition={hideCheckBoxCondition}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

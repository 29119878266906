import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IThreatDataSourcesProps {
  id: string;
}

export const getThreatDataSource = ({ id }: IThreatDataSourcesProps) => {
  return Brm.threatDataSourceApi.getThreatDataSourceByID(id);
};

interface IUseThreatDataSource {
  id: string;
  options?: QueryOption<typeof getThreatDataSource>;
}

/**
 * React-query hook for getting threat data source
 *
 * @param {string} id -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatDataSource = ({ id, options }: IUseThreatDataSource) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatDataSources.dataSource(id),
    queryFn: () => getThreatDataSource({ id }),
  });
};

import Title from "components/elements/StyledTitle";
import { DefenseApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { DefensiveTacticTabs } from "features/mitre-d3fend/components/tabs/DefensiveTacticTabs";

export const DefensiveTactic = () => {
  const { id } = useParams();

  const {
    data: defensiveTactic,
    isError: isDefensiveTacticError,
    error: defensiveTacticError,
  } = DefenseApi.useDefensiveTactic({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isDefensiveTacticError) {
    return <ErrorBanner msg={(defensiveTacticError as any)?.statusText} />;
  }

  if (defensiveTactic) {
    return (
      <>
        <Title>Defensive Tactic: {defensiveTactic.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{defensiveTactic.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{defensiveTactic.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{defensiveTactic.sourceId}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <DefensiveTacticTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./nist-controls-query-key-factory";

export const getNistControl = ({ id }: { id?: string }) => {
  return Brm.nistControlApi.getNistControlByID(id);
};

interface IUseNistControl {
  id?: string;
  options?: QueryOption<typeof getNistControl>;
}

/**
 * React-query hook for getting all NIST Controls
 *
 * @param
 * @returns react-query for getNistControl
 */
export const useNistControl = ({ id, options }: IUseNistControl = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nistControlsQueryKey.control(id),
    queryFn: () => getNistControl({ id }),
  });
};

/** ****************************************
 * FYI THERE ARE TWO ALLOCATED_CONTROL_TABLES components and add forms with the same name... the other is in the features/mitigation...
 * why you ask? I have no idea and no time to find out.
 */

import * as React from "react";
import * as Recoil from "recoil";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { SystemApi, currentProjectState } from "features/brm";
import { ExportButton } from "features/brm/components/elements/ExportButton";
import { GenericAddButton } from "features/brm/components/elements/GenericAddButton";
import { ExportTableModal, useExportTable } from "features/exporter";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading, CenterText } from "components/elements";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import { isImportingStatus } from "utils/filter-utils";

// Styles
import * as S from "brm/styles/details-table.styles";
// Api
import { RoutePath } from "routes/route-paths";
import { AddAllocatedDigitalArtifactsDialog } from "features/mitre-digitalArtifacts/components/dialogs/AddAllocatedDigitalArtifactDialog";

interface IMitreDigitalArtifactsDetailsTableProps {
  elementNameInUrl: string;
  selectedElement: any;
  tableTitle: string;
  setIsControlCatalogEditingEnabled?: (input: boolean) => void | undefined;
}

export const MitreDigitalArtifactsDetailsTable = ({
  elementNameInUrl,
  selectedElement,
  tableTitle,
  setIsControlCatalogEditingEnabled,
}: IMitreDigitalArtifactsDetailsTableProps) => {
  const currentProject = Recoil.useRecoilValue(currentProjectState);
  const isSubmissionPending = Recoil.useRecoilValue(isSubmissionPendingState);

  const [, setSelectedRowId] = React.useState([]);

  const { isThreatAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible: isVisibleExport, toggle: toggleExport } = useModal();

  const isImporting = currentProject ? isImportingStatus(currentProject) : true;
  const isImportingAndSubmissionDone = !(isImporting || isSubmissionPending);
  const { isVisible: isAddVisible, toggle: toggleAdd } = useModal();

  const columns = React.useMemo(
    () =>
      isThreatAnalyst
        ? [
            createColumnNameDetails(RoutePath.AllocatedControl.replace(":id", "")),
            createColumnMappedNoEdit("attackercat"),
          ]
        : [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("descriptionThreat")],
    [isThreatAnalyst]
  );

  const { data: daData, isError: isGetDaDataError } = SystemApi.useElementMitreDigitalArtifacts({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    options: {
      enabled: !!selectedElement.id,
      onSuccess: (data) => {
        if (setIsControlCatalogEditingEnabled) {
          setIsControlCatalogEditingEnabled(!(Array.isArray(data) && data.length));
        }
      },
    },
  });

  if (isThreatAnalyst) {
    return <CenterText msg="Mitre Digital Artifacts is not applicable" />;
  }

  if (isGetDaDataError) {
    return <ErrorBanner msg="Error while attempting to load Mitre Digital Artifacts data" />;
  }

  if (!isImportingAndSubmissionDone) {
    return <Loading />;
  }

  if (daData) {
    return (
      <S.DetailsContainer id="MitreDigitalArtifactsTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisibleExport} toggle={toggleExport} />
        <AddAllocatedDigitalArtifactsDialog
          elementName={elementNameInUrl}
          elementId={selectedElement.id}
          isVisible={isAddVisible}
          toggle={toggleAdd}
        />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={daData}
              columns={columns}
              setSelectedElement={setSelectedRowId}
              customProps={{ id: "MitreDigitalArtifactsTable_detailsTable" }}
              showRowSelect={false}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailButtonTopCorner>
            <GenericAddButton onClick={() => toggleAdd()} tooltip="Add Allocated Digital Artifact" />
            <ExportButton onClick={() => toggleExport()} disableExport={disableExport} />
          </S.DetailButtonTopCorner>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

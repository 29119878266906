import { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { BrmMainTable } from "brm/tables/BrmTables";
import CustomTable from "components/elements/CustomTableDivStyled";
import { createColumnMappedNoEdit, createColumnBooleanNoEditMapped } from "brm/tables/services/column/columnFactory";
import { useQueryClient } from "@tanstack/react-query";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useGetElementCveFindings } from "brm/tables/details/api";
import { projectIdState } from "atoms/atoms-admin";
import { useRoles } from "features/brm";

export const CveFidingTable = () => {
  const { isRiskAnalyst } = useRoles();

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("cwe"),
      createColumnMappedNoEdit("cve"),
      createColumnMappedNoEdit("severity"),
      createColumnMappedNoEdit("score"),
      createColumnMappedNoEdit("vector"),
      createColumnBooleanNoEditMapped("fixed"),
      createColumnMappedNoEdit("artifact"),
      createColumnMappedNoEdit("description"),
    ],
    []
  );

  const [, setSelectedFindings] = useState([]);
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);

  const queryClient = useQueryClient();
  const { data: getCveFindingsData, isError: isGetCveFindingsDataError } = useGetElementCveFindings({
    elementId: projectId,
    elementName: "projects",
    projectId,
    options: { enabled: isRiskAnalyst },
  });

  useEffect(() => {
    queryClient.invalidateQueries(["getElementFindings", variantId]);
  }, [queryClient, variantId]);

  if (isGetCveFindingsDataError) {
    return <ErrorBanner msg="Error while loading CVEfindings data" />;
  }

  if (getCveFindingsData) {
    return (
      <CustomTable id="CveFidingTable">
        <BrmMainTable
          data={getCveFindingsData}
          columns={columns}
          // setSelectedElement={setSelectedFindings}
          setSelectedRows={setSelectedFindings}
          customProps={{ id: "CveFidingTable_table" }}
          showRowSelect={false}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IThreatSoftwareIdsProps {
  id?: string;
}
export const getThreatSoftwareId = ({ id }: IThreatSoftwareIdsProps) => {
  return Brm.threatSoftwareApi.getThreatSoftwareByID(id);
};

interface IUseThreatSoftwareId {
  id: string;
  options?: QueryOption<typeof getThreatSoftwareId>;
}

/**
 * React-query hook for getting threat software
 *
 * @param {string} id -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatSoftwareId = ({ id, options }: IUseThreatSoftwareId) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatSoftware.software(id),
    queryFn: () => getThreatSoftwareId({ id }),
  });
};

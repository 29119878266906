// import * as ReactQuery from "react-query";
// import * as Brm from "services/brm";
// import { QueryOption } from "libs/react-query";
// import * as KeyFactory from "./import-query-key-factory";

// interface IGetSubmissionStatus {
//   projectId: string;
//   context: string;
//   runId: string;
// }

// const getSubmissionStatus = async ({ projectId, context, runId }: IGetSubmissionStatus) => {
//   return Brm.genericImportApi.submissionStatus(context, projectId, runId);
// };

// interface IUseSubmissionStatus {
//   projectId: string;
//   context: string;
//   runId: string;
//   options?: QueryOption<typeof getSubmissionStatus>;
// }

// export const useSubmissionStatus = ({ projectId, context, runId, options }: IUseSubmissionStatus) => {
//   // When context = ImportContext.baseline => projectId = baselineId.
//   return ReactQuery.useQuery({
//     ...options,
//     queryKey: KeyFactory.importKeys.submissionStatus(projectId, context, runId),
//     queryFn: () => getSubmissionStatus({ projectId, context, runId }),
//   });
// };

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import { ImportContext, ImportContextValues } from "../../constants";
import * as KeyFactory from "./import-query-key-factory";

interface IGetImportStatus {
  projectId: string;
  context: ImportContextValues;
  runId: string;
}

interface ImporterFunctionMap {
  [key: string]: any;
}

const getSubmissionStatus = async ({ projectId, context, runId }: IGetImportStatus) => {
  const importer: ImporterFunctionMap = {
    [ImportContext.bor]: () => Brm.borImportApi.submissionStatusBOR(projectId, runId),
    [ImportContext.sbom]: () => Brm.scanBorImportApi.importScanSubmissionStatus(projectId, runId),
    [ImportContext.baseline]: () => Brm.baselineImportApi.submissionStatusBaseline(projectId, runId),
    [ImportContext.savepoint]: () => Brm.savePointImportApi.submissionStatusSavepoint(projectId, runId),
    [ImportContext.sctm]: () => Brm.sctmImportApi.submissionStatusSctm(projectId, runId),
    [ImportContext.tra]: () => Brm.traImportApi.submissionStatusTra(projectId, runId),
    [ImportContext.system]: () => Brm.systemImportApi.submissionStatusSystem(projectId, runId),
  };

  if (!Object.keys(importer).some((k) => k === context)) {
    throw new Error(`Unsupported import context: ${context}`);
  }
  const value = await importer[context]();
  return Promise.resolve(value || []);
};

export interface IUseSubmissionStatus {
  projectId: string;
  context: ImportContextValues;
  runId: string;
  options?: QueryOption<typeof getSubmissionStatus>;
}

export const useSubmissionStatus = ({ projectId, runId, context, options }: IUseSubmissionStatus) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.importKeys.submissionStatus(projectId, context, runId),
    queryFn: () => getSubmissionStatus({ projectId, context, runId }),
  });
};

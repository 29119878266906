const TYPE = {
  affectedPlatform: "Affected Plaform",
  offensiveTactic: "Offensive Tactic",
  offensiveTechnique: "Offensive Technique",
  targetAsset: "Target Asset",
  threatDataSource: "Threat Data Source",
  threatSoftware: "ThreatSoftware",
  asset: "Asset",
  assetCat: "Asset Category",
  attackKind: "Attack Kind",
  attackMechanism: "Attack Mechanism",
  attackTactic: "Attack Tactic",
  attack: "Attack",
  attackPath: "Attack Path",
  ue: "Undesired Event",
  harmCat: "Harm Category",
  riskCat: "Risk Category",
  risk: "Risk",
  trevCat: "Threat Event Category",
  trevPat: "Threat Event Pattern",
  trev: "Threat Event",
  vulnerability: "Vulnerability",
  vulnerabilityCat: "Vulnerability Category",
  activity: "Activity",
  bus: "Bus",
  capability: "Capability",
  dataflow: "Dataflow",
  datatype: "Datatype",
  elementCat: "Element Category",
  exchange: "Exchange",
  link: "Link",
  mission: "Mission",
  node: "Node",
  person: "Person",
  resource: "Resource",
  structuralCat: "Structural Category",
  systemAsset: "System Asset",
  systemAssetCat: "System Asset Category",
  systemAssetType: "System Asset Type",
  categorizedEntity: "Categorized Entity",
  classificationCat: "Classification Category",
  securityObjective: "Security Objective",
  accessPoint: "Access Point",
  accessPointCat: "Access Point Category",
  entryPoint: "Entry Point",
  entryPointCat: "Entry Point Category",
  attackSurface: "Attack Surface",
  attackSurfaceCat: "Attack Surface Category",
  attackVector: "Attack Vector",
  attackVectorCat: "Attack Vector Category",
  allocatedControl: "Allocated Control",
  sctm: "SCTM",
  controlCatalog: "Control Catalog",
  controlFamily: "Control Family",
  controlType: "Control Type",
  standardBaseline: "Baseline",
  attacker: "Attacker",
  attackerCat: "Attacker Category",
  threatCatalog: "Threat Catalog",
  threatGroup: "Threat Group",
  threatTier: "Threat Tier",
  mitreD3fend: "MITRE D3FEND",
  defensiveTactics: "Defensive Tactics",
  defensiveTechniques: "Defensive Techniques",
  digitalArtifacts: "Digital Artifacts",
  cciControls: "CCI Controls",
  nistControls: "NIST Controls",
  mitreAttack: "Mitre Attack",
  installation: "Installation",
  project: "Project",
  user: "User",
  organization: "Organization",
  metadata: "Metadata",
  variant: "Variant",
  module: "Module",
  system: "System",
  knowledgebase: "Knowledgebase",
  ruleset: "Ruleset",
  rule: "Rule",
  help: "Help",
};

export default TYPE;

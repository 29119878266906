import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./defensive-tactics-query-key-factory";

interface IDefensiveTacticProps {
  id: string;
}

export const getDefensiveTactic = ({ id }: IDefensiveTacticProps) => {
  return Brm.defensiveTacticApi.getDefensiveTacticByID(id);
};

interface IUseDefensiveTactic {
  id: string;
  options?: QueryOption<typeof getDefensiveTactic>;
}

/**
 * React-query hook for getting defensive tactic
 *
 * @param
 * @returns react-query for getDefensiveTactic
 */
export const useDefensiveTactic = ({ id, options }: IUseDefensiveTactic) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.defensiveTacticsQueryKey.tactic(id),
    queryFn: () => getDefensiveTactic({ id }),
  });
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./cci-controls-query-key-factory";

export const getCciControl = ({ id }: { id?: string }) => {
  return Brm.cciControlApi.getCciControlByID(id);
};

interface IUseCciControl {
  id?: string;
  options?: QueryOption<typeof getCciControl>;
}

/**
 * React-query hook for getting CCI Control
 *
 * @param
 * @returns react-query for getDefendCatalogs
 */
export const useCciControl = ({ id, options }: IUseCciControl = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.cciControlsQueryKey.control(id),
    queryFn: () => getCciControl({ id }),
  });
};

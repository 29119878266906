import * as ReactQuery from "@tanstack/react-query";

// Services
import { sctmApi } from "services/brm/mitigation-service";
import { variantApi } from "services/brm/component-service";

import * as mitigationKeyFactory from "features/brm/api/mitigation/mitigation-query-key-factory";

const getSctmFullDetails = async (id) => {
  let sctmToDisplay = {};
  const sctmData = await sctmApi.getSCTMByID(id);
  sctmToDisplay = sctmData;

  const sctmVariantData = await sctmApi.getSCTMVariant(id);
  sctmToDisplay.variant = sctmVariantData;

  const ctrlCatalogData = await variantApi.getVariantCatalog(sctmVariantData.id);
  sctmToDisplay.controlCatalog = ctrlCatalogData;

  const sctmBaselineData = await sctmApi.getSCTMBaseline(id);
  sctmToDisplay.baseline = sctmBaselineData;

  return sctmToDisplay;
};

export const useSctmById = ({ sctmId, options = {} }) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: mitigationKeyFactory.sctmKeys.sctmFullDetails(sctmId),
    queryFn: () => getSctmFullDetails(sctmId),
  });
};

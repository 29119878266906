import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IGetGlobalThreatMetadata {
  name?: string;
  type?: string;
}
export const getGlobalThreatMetadata = ({ name, type }: IGetGlobalThreatMetadata) => {
  return Brm.gtMetaApi.findGtMeta({ name, type });
};

interface IUseGlobalThreatMetadata {
  name?: string;
  type?: string;
  options?: QueryOption<typeof getGlobalThreatMetadata>;
}

/**
 * React-query hook for getting global threat metadata
 *
 * @param {string} name - name of mechanism (e.g. 'capec-176') or name of vulncat (e.g. 'iaaa')
 * @param {string} type - type of global threat metadata. Only two options: 'mechanism' or 'vulncat'
 * @param {object} options - react-query configuration
 * @returns react-query for getGlobalThreatMetadata
 */
export const useGlobalThreatMetadata = ({ name, type, options }: IUseGlobalThreatMetadata) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.globalThreatMetaKeys.meta(name, type),
    queryFn: () => getGlobalThreatMetadata({ name, type }),
  });
};

import { useEffect, useState } from "react";
import { controlCatalogIdState, controlCatalogAddedState, controlFamilyIdState } from "atoms/atoms-global-control";
import { useRecoilState, useResetRecoilState } from "recoil";
import errorUtils from "utils/error-utils";
import { controlCatalogApi } from "services/brm/global-control-service";
import getReactSelectArray from "utils/react-select-array";

import Select from "react-select";
import { customStyles } from "components/elements/ReactSelectCustomStyles";
import { useRoles } from "hooks";

const ControlCatalogDropdownSelection = () => {
  const { isControlsCoordinator } = useRoles();
  const [controlCatalogId, setControlCatalogId] = useRecoilState(controlCatalogIdState);
  const [controlCatalogAdded, setControlCatalogAdded] = useRecoilState(controlCatalogAddedState);
  const resetControlFamilyId = useResetRecoilState(controlFamilyIdState);
  const [controlCatalogList, setControlCatalogList] = useState([]);
  const [defaultCatalog, setDefaultCatalog] = useState("");

  const handleCatalogSelectChange = async (selectedItems) => {
    const item = selectedItems || {};
    setControlCatalogId(item.value);
    resetControlFamilyId();
  };

  useEffect(() => {
    async function getData() {
      try {
        const res = await controlCatalogApi.findControlCatalogWithHttpInfo();

        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setControlCatalogList(getReactSelectArray(res.data));
          setControlCatalogAdded(false);

          // if controlCatalogId atom is null then choose the default control catalog
          if (controlCatalogId === null) {
            res.data.forEach((catalog) => {
              if (catalog.isDefault) {
                setControlCatalogId(catalog.id);
                setDefaultCatalog({ value: catalog.id, label: catalog.name });
              }
            });
          } else {
            const { data } = await controlCatalogApi.getControlCatalogByIDWithHttpInfo(controlCatalogId);

            if (data) {
              setDefaultCatalog({ value: data.id, label: data.name });
            }
          }
        }
      } catch (err) {
        console.error("err in Get control catalog  : ", err);
      }
    }

    if (isControlsCoordinator) {
      getData();
    }
  }, [controlCatalogAdded, setControlCatalogAdded, controlCatalogId, setControlCatalogId, isControlsCoordinator]);

  // displaying variant dropdown for control coordinators role for now.

  return (
    <span title="Control Catalog Selection">
      <Select
        menuPortalTarget={document.body}
        isMulti={false}
        options={controlCatalogList}
        value={controlCatalogId ? defaultCatalog : ""}
        onChange={handleCatalogSelectChange}
        placeholder="Select Control Catalog..."
        styles={customStyles}
        classNamePrefix="catalogDropdown"
        id="ControlCatalogDropdownSelection_catalogDropdown"
      />
    </span>
  );
};

export default ControlCatalogDropdownSelection;

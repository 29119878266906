import { useEffect } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { RoutePath } from "routes/route-paths";
import userRoles from "services/brm/user-roles";
import { useRecoilValue } from "recoil";

import { useRoles } from "features/brm/hooks/useRoles";
import { userRoleState } from "atoms/atoms-admin";
import { AdminApi } from "features/brm";
import userContentPermissions from "./userContentPermissions";

const adminRole = userRoles.properties[userRoles.ADMIN].key;
const superAdminRole = userRoles.properties[userRoles.SUPER_ADMIN].key;
const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
const controlCoordinatorRole = userRoles.properties[userRoles.CONTROLS_COORDINATOR].key;
const systemEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
const riskAnalystRole = userRoles.properties[userRoles.RISK_ANALYST].key;
const threatAnalystRole = userRoles.properties[userRoles.THREAT_ANALYST].key;

function useCheckUserPermission(userId, projectId, role, key) {
  const navigate = useNavigate();
  const { isSystemEngineer, isRiskAnalyst, isThreatAnalyst } = useRoles();
  const userRoleVal = useRecoilValue(userRoleState);

  const { hasProjectPermission } = AdminApi.useUserProjectPermission({
    userId,
    projectId,
    options: {
      enabled: !!userId && !!projectId,
    },
  });

  const isProjectAwareRole = isSystemEngineer || isRiskAnalyst || isThreatAnalyst;
  const userProjectPermission = isProjectAwareRole && projectId ? hasProjectPermission : true;

  useEffect(() => {
    // Ensure the permissions query has returned before navigating.
    if (isProjectAwareRole && !!projectId && !hasProjectPermission) {
      return;
    }
    // console.log("key", key, "role:", role);

    try {
      if ((userRoleVal && role && !userContentPermissions[key][role]) || !userProjectPermission) {
        switch (role) {
          case adminRole: {
            navigate(RoutePath.Projects);
            break;
          }
          case superAdminRole: {
            navigate(RoutePath.Installations);
            break;
          }
          case cyberSecurityExpertRole: {
            navigate(RoutePath.Knowledgebases);
            break;
          }
          case controlCoordinatorRole: {
            navigate(RoutePath.ControlCatalogs);
            break;
          }
          case systemEngineerRole: {
            // console.log("permissions check....", userProjectPermission, projectPermission, projectId);
            if (projectId) {
              navigate(generatePath(RoutePath.Project, { id: projectId }));
            } else {
              navigate(RoutePath.Projects);
            }
            break;
          }
          case riskAnalystRole: {
            if (projectId) {
              // navigate(generatePath(RoutePath.Project, { id: projectId }));
              navigate(RoutePath.SystemDiagram);
            } else {
              navigate(RoutePath.Projects);
            }
            break;
          }
          case threatAnalystRole: {
            navigate(RoutePath.ThreatGroups);
            break;
          }
          default: {
            navigate(RoutePath.Home);
          }
        }
      }
    } catch (err) {
      console.error("Error when checking user permissions for page ", err);
    }
  }, [role, key, userRoleVal, navigate, userProjectPermission, projectId, hasProjectPermission, isProjectAwareRole]);
}

export default useCheckUserPermission;

import { MdOutlineUploadFile } from "react-icons/md";
import SecondaryButton from "components/elements/SecondaryStyledButton";

import { MouseEvent } from "react";

interface IUploadButtonProps {
  onClick: (event: MouseEvent) => void;
  uploadTitle?: string;
}

export const SbomUploadButton = ({ onClick, uploadTitle = "Scan" }: IUploadButtonProps) => (
  <SecondaryButton title={uploadTitle} id="SbomUploadButton_button" onClick={onClick}>
    <MdOutlineUploadFile />
  </SecondaryButton>
);

import PropTypes from "prop-types";
import { useState, useMemo, useEffect } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { AddButton, DeleteButton, SystemApi, useRoles } from "features/brm";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { RoutePath } from "routes/route-paths";
import { TYPE } from "constants/brm";
import { queryClient } from "libs/react-query";
import NodeAddForm from "./forms/NodeAddForm";

const NodeTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete = false, setRefresh, tableTitle }) => {
  const { isSystemEngineer, isRiskAnalyst, isThreatAnalyst } = useRoles();
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Node.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("structcat"),
      createColumnBooleanNoEditMapped("isInternal"),
    ],
    []
  );

  const deleteNode = SystemApi.useDeleteElementNodes();

  const [, setSelectedNode] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const projectId = useRecoilValue(projectIdState);

  const { data: nodeData, isError: isNodesDataError } = SystemApi.useElementNodes({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    options: {
      enabled: !!selectedElement?.id && !!elementNameInUrl && (isSystemEngineer || isRiskAnalyst || isThreatAnalyst),
    },
  });

  const addNode = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(
        selectedRows.map((each) =>
          deleteNode.mutateAsync({
            elementId: selectedElement.id,
            elementName: elementNameInUrl,
            nodeId: each.id,
          })
        )
      );
      setDeleteModalIsOpen(false);
      setSelectedRows([]);
      queryClient.invalidateQueries(SystemApi.nodeKeys.all);
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteNode, elementNameInUrl, selectedElement.id, selectedRows]);

  if (isNodesDataError) {
    return <ErrorBanner mgs="Error while attempt to load node data" />;
  }

  if (nodeData) {
    return (
      <S.DetailsContainer id="NodeTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "NodeTable_detailPanel",
            modalIsOpen,
            setModalIsOpen,
            <NodeAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Node"
          )}

        {deleteModalIsOpen &&
          systemModals.deleteModal(
            "NodeTable_detailsPanel",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName={TYPE.node}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.node
          )}

        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={nodeData}
              columns={columns}
              setSelectedElement={setSelectedNode}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "NodeTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && selectedRows.length > 0 && <DeleteButton md onClick={() => setDeleteModalIsOpen(true)} />}
            {showAdd && <AddButton md onClick={addNode} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

NodeTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default NodeTable;

import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import SystemAssetTable from "brm/tables/details/SystemAssetTable";
import CategorizedEntityTable from "brm/tables/details/CategorizedEntityTable";
import PropTypes from "prop-types";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { DetailTableType } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";

const systemMenu = [
  DetailTableType.EXCHANGES,
  DetailTableType.NODES,
  DetailTableType.ACTIVITIES,
  DetailTableType.DATAFLOWS,
  DetailTableType.PERSON,
  DetailTableType.CAPABILITIES,
  DetailTableType.MISSIONS,
  DetailTableType.CHARACTERISTICS,
];

const riskMenu = [DetailTableType.RISKS, DetailTableType.ASSETS, DetailTableType.UNDESIRED_EVENTS];

const accessMenu = [
  DetailTableType.ACCESS_POINTS,
  DetailTableType.ATTACK_SURFACES,
  DetailTableType.ATTACK_VECTORS,
  DetailTableType.ENTRY_POINTS,
];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS, DetailTableType.MITRE_D3FEND];

const attacksMenu = [
  DetailTableType.THREAT_EVENTS,
  DetailTableType.ATTACKS,
  DetailTableType.ATTACKERS,
  DetailTableType.MITRE_ATTACK,
];

const vulnerabilityMenu = [DetailTableType.VULNERABILITY_CONDITIONS, DetailTableType.VULNERABILITY_FINDINGS];

const DatatypeDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.DATATYPES.key;
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const [selectedContext, setSelectedContext] = React.useState(DetailTableType.EXCHANGES.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(DetailTableType.VULNERABILITY_CONDITIONS.key);
  const [selectedAccessContext, setSelectedAccessContext] = React.useState(DetailTableType.ACCESS_POINTS.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [curr, setCurr] = React.useState("");

  return (
    <Tabs>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedContext}
            curr={curr}
          />
        </Tab>
        {(isRiskAnalyst || isSystemEngineer) && (
          <Tab onClick={() => setCurr(DetailTableType.CATEGORIZATION.name)}>{DetailTableType.CATEGORIZATION.name}</Tab>
        )}
        <Tab onClick={() => setCurr(DetailTableType.ACCESS.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.ACCESS.name}
            menu={accessMenu}
            setSelectedContext={setSelectedAccessContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM_ASSETS.name)}>{DetailTableType.SYSTEM_ASSETS.name}</Tab>
        {isRiskAnalyst && (
          <>
            <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.RISK.name}
                menu={riskMenu}
                setSelectedContext={setSelectedRiskContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.ATTACK.name}
                menu={attacksMenu}
                setSelectedContext={setSelectedAttackContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.VULNERABILITIES.name}
                menu={vulnerabilityMenu}
                setSelectedContext={setSelectedVulContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.CONTROLS.name}
                menu={controlsMenu}
                setSelectedContext={setSelectedControlsContext}
                curr={curr}
              />
            </Tab>
          </>
        )}
      </TabList>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedContext}
        />
      </TabPanel>
      {(isRiskAnalyst || isSystemEngineer) && (
        <>
          <TabPanel>
            <CategorizedEntityTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
          </TabPanel>
        </>
      )}
      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedAccessContext}
        />
      </TabPanel>
      <TabPanel>
        <SystemAssetTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          tableTitle="System Assets"
        />
      </TabPanel>

      {isRiskAnalyst && (
        <>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedRiskContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedAttackContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedVulContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedControlsContext}
            />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
};

DatatypeDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default DatatypeDetails;

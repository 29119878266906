import * as ReactQuery from "@tanstack/react-query";
// import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { getThreatDataSource } from "features/brm/api/threat/globalThreat/getThreatDataSource";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IUseOffensiveTechniqueTactics {
  dataSources: string[];
  options?: QueryOption<typeof getThreatDataSource>;
}

/**
 * React-query hook for getting offensive techniques
 *
 * @param {string[]} dataSources -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatDataSourcesById = ({ dataSources, options }: IUseOffensiveTechniqueTactics) => {
  return ReactQuery.useQueries({
    queries: dataSources.map((id: string) => ({
      queryKey: KeyFactory.threatDataSources.dataSource(id),
      queryFn: () => getThreatDataSource({ id }),
      staleTime: Infinity,
      ...options,
    })),
  });
};

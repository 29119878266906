import * as React from "react";
import * as Recoil from "recoil";
import * as BrmGql from "generated/graphql";
import { projectIdState, variantIdState, selectedInstanceState } from "atoms";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";
import { UrlSearchParams, isCurrentProjectMitigatingState } from "features/brm";

import {
  createColumnName,
  createColumnMappedNoEdit,
  createColumnControlsCount,
  createColumnVulLevelMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";

const CONTROL_TAB_INDEX = 6;
const tabIndex = `?${UrlSearchParams.Tab}=${CONTROL_TAB_INDEX}`;

const VulnerabilityTable = ({ setSelectedRows, setExportTableData }) => {
  const projectId = Recoil.useRecoilValue(projectIdState);
  const variantId = Recoil.useRecoilValue(variantIdState);
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);
  const { isRiskAnalyst } = useRoles();
  const updatedControlsCount = React.useRef("");
  const isProjMitigating = Recoil.useRecoilValue(isCurrentProjectMitigatingState);

  const { data: vulListData, isError: isVulListDataError } = BrmGql.useGetAllVulnerabilitiesQuery(
    { project: projectId, variant: variantId },
    {
      enabled: !!projectId && !!variantId && !isProjMitigating, // ,
      select: (data) => {
        data.vulnerabilities.sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (b > a) {
            return -1;
          }
          return 0;
        });
        return data;
      },
    }
  );

  const columns = React.useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnMappedNoEdit("vulRank"),
        createColumnName(selectedInstance.id, setSelectedInstance, false, null, "vulnerability"),
        createColumnMappedNoEdit("category"),
        createColumnVulLevelMapped("vulLevelCalcStringValue"),
        createColumnMappedNoEdit("anobject"),
        createColumnControlsCount(
          selectedInstance.id,
          setSelectedInstance,
          false,
          updatedControlsCount,
          "vulnerability",
          tabIndex
        ),
        createColumnMappedNoEdit("findingCount"),
        createColumnMappedNoEdit("cveFindingCount"),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "vulnerability"),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("vulLevelCalcStringValue"),
      createColumnMappedNoEdit("anobject"),
    ];
  }, [isRiskAnalyst, selectedInstance.id, setSelectedInstance]);

  if (isVulListDataError) {
    return <ErrorBanner msg="Error while loading Vulnerabilities" />;
  }

  if (!isProjMitigating && vulListData?.vulnerabilities) {
    return (
      <CustomTable>
        <BrmMainTable
          data={vulListData.vulnerabilities}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "VulnerabilityTable_table" }}
          elementName={TYPE.vulnerability}
          setExportTableData={setExportTableData}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

VulnerabilityTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setExportTableData: PropTypes.func,
};

export default VulnerabilityTable;

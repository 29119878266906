import * as React from "react";

import { projectIdState, userRoleState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE, COMMON } from "constants/brm";
import { userRoles } from "services/brm/admin-service";

import {
  createColumnName,
  createColumnActions,
  createColumnBooleanMapped,
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { ThreatApi, AdminApi } from "features/brm";

import * as BrmGql from "generated/graphql";

const threatAnalystRole = userRoles.properties[userRoles.THREAT_ANALYST].key;

const ThreatGroupTable = ({ setSelectedRows, setRefresh }) => {
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const projectId = useRecoilValue(projectIdState);
  const userRole = useRecoilValue(userRoleState);

  const [editMode, setEditMode] = React.useState(false);
  const updatedName = React.useRef("");
  const updatedIsNationState = React.useRef("");
  const updatedTier = React.useRef("");

  const { data: selfData } = AdminApi.useSelf({ options: {} });
  const { data: threatTiers, isError: isGetThreatTierError } = ThreatApi.useThreatTiers();
  const { data: threatGroups, isError: isGetThreatGroupError } = BrmGql.useGetThreatGroupsQuery();
  // const { data: threatGroups2 } = ThreatApi.useThreatGroups();

  // console.log("threatGroups", threatGroups);
  // console.log("threatGroups2", threatGroups2);
  const { mutate: setThreatGroupName } = ThreatApi.useSetThreatGroupName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
    },
  });
  const { mutate: setIsNationState } = ThreatApi.useSetThreatGroupIsNation({
    options: {
      onSettled: () => {
        updatedIsNationState.current = "";
      },
    },
  });
  const { mutate: setTier } = ThreatApi.useSetThreatGroupTier({
    options: {
      onSettled: () => {
        updatedTier.current = "";
      },
    },
  });

  const createButton = React.useCallback(
    (cellProps) => {
      const currentRow = cellProps.cell.row.original;
      if (
        selfData &&
        userRole === threatAnalystRole &&
        currentRow.catalog.id !== COMMON.defaultUuid &&
        currentRow.catalog.organization.id === selfData.organization
      ) {
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                setThreatGroupName({ threatGroupId: selectedInstance.id, name: updatedName.current });
              }

              if (updatedIsNationState.current !== "" && updatedIsNationState !== "Select isNationState") {
                setIsNationState({ threatGroupId: selectedInstance.id, isNation: updatedIsNationState.current });
              }

              if (updatedTier.current !== "" && updatedTier.current !== "Select tier") {
                setTier({ threatGroupId: selectedInstance.id, tier: updatedTier.current });
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return null;
    },

    [userRole, selfData, selectedInstance.id, editMode, setThreatGroupName, setIsNationState, setTier]
  );

  const columns = React.useMemo(() => {
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "threatGroup"),
      createColumnBooleanMapped("isNationState", selectedInstance.id, editMode, updatedIsNationState),
      createColumnMappedNoEdit("catalog"),
      createColumnCategoryMapped("tier", selectedInstance.id, threatTiers, editMode, updatedTier),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [selectedInstance.id, setSelectedInstance, editMode, threatTiers, createButton]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  // const hideCheckBoxCondition = React.useCallback((row) => row.original.catalog.id === COMMON.defaultUuid, []);

  if (isGetThreatGroupError || isGetThreatTierError) {
    return <ErrorBanner msg="Error while loading threat group data" />;
  }

  // console.log("threatGroups", threatGroups);

  if (threatGroups) {
    return (
      <CustomTable>
        <BrmMainTable
          data={threatGroups.threatGroups}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "ThreatGroupTable_table" }}
          elementName={TYPE.threatGroup}
          // hideCheckBoxCondition={hideCheckBoxCondition}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

ThreatGroupTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default ThreatGroupTable;

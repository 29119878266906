import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./digital-artifacts-query-key-factory";

export const getDigitalArtifact = ({ id }: { id?: string }) => {
  return Brm.digitalArtifactApi.getDigitalArtifactByID(id);
};

interface IUseDigitalArtifacts {
  id?: string;
  options?: QueryOption<typeof getDigitalArtifact>;
}

/**
 * React-query hook for getting all digital artifacts
 *
 * @param
 * @returns react-query for getDigitalArtifact
 */
export const useDigitalArtifact = ({ id, options }: IUseDigitalArtifacts = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.digitalArtifactsQueryKey.artifact(id),
    queryFn: () => getDigitalArtifact({ id }),
  });
};

export const FORM_LABEL = {
  nameMandatory: "Name*:",
  note: "Note:",
  categoryMandatory: "Category*:",
  structuralCategoryMandatory: "Structural Category*:",
  nodeMandatory: "Node*:",
  internalMandatory: "Internal*:",
  initialMandatory: "Initial*:",
  finalMandatory: "Final*:",
  storedDataOptional: "Stored Data (Optional):",
  nextOptional: "Next (Optional):",
  parentOptional: "Parent (Optional):",
  exchangeOptional: "Exchange (Optional):",
  ownedDataOptional: "Owned Data (Optional):",
  nodeOptional: "Node (Optional):",
  channelOptional: "Channel (Optional):",
  activityOptional: "Activity (Optional):",
  activitiesOptional: "Activities (Optional):",
  dataOptional: "Data (Optional):",
  personOptional: "Person (Optional):",
  exchangesMandatory: "Exchanges*:",
  inputOptional: "Input (Optional):",
  outputOptional: "Output (Optional):",
  storedOptional: "Stored (Optional):",
  processedOptional: "Processed (Optional):",
  dataMandatory: "Data*:",
  producerMandatory: "Producer*:",
  consumerMandatory: "Consumer*:",
  activityProducerOptional: "Activity Producer (Optional):",
  activityConsumerOptional: "Activity Consumer (Optional):",
  exchangesOptional: "Exchanges (Optional):",
  capabilitiesOptional: "Capabilities (Optional):",
  groupOptional: "Group (Optional):",
  nodesOptional: "Nodes (Optional):",
  channelsOptional: "Channels (Optional):",
  exchangeMandatory: "Exchange*:",
  targetCategory: "Target Category*:",
  objective: "Objective*:",
  severity: "Severity*:",
  criticality: "Criticality*:",
  rfactory: "R Factor*:",

  ownerMandatory: "Owner*:",
  systemAssetTypeOptional: "System Asset Type (Optional):",
  systemAssetCategoryMandatory: "System Asset Category*:",
  cpeOptional: "CPE (Optional):",

  categorizationMandatory: "Categorization (Impact Statements)*:",
  subjectMandatory: "Subject*:",
  classificationMandatory: "Classification*:",
  levelMandatory: "Level*:",

  surfaceOptional: "Surface (Optional):",
  entryPointsOptional: "Entry Points (Optional):",

  attachedUsersOptional: "Attached User(s) (Optional):",
  listedMandatory: "Listed*:",
  organizationMandatory: "Organization*:",
  usersOptional: "User(s) (Optional):",
  usersReadOnlyOptional: "User(s) - Read Only (Optional):",

  sourceTypeMandatory: "Source Type*:",

  standardMandatory: "Standard*:",
  standardOptional: "Standard:",
  strengthMandatory: "Strength*:",
  costMandatory: "Cost*:",
  implGuidanceMandatory: "Impl Guidance*:",
  assmtGuidanceMandatory: "Assmt Guidance*:",
  vulCategoryMandatory: "Vulnerability Category*:",

  versionMandatory: "Version*:",
  resourceMandatory: "Resource*:",
  ttlMandatory: "Time To Live (TTL)*:",

  sourceUrlMandatory: "Source URL*:",
  nationStateMandatory: "Nation State*:",
  tierMandatory: "Tier*:",
  catalogMandatory: "Catalog*:",
  likelihoodMandatory: "Likelihood*:",
  threatCatalogMandatory: "Threat Catalog*:",
  threatCatalog: "Threat Catalog:",
  preferredTargetsMandatory: "Preferred Target(s)*:",
  attackKindMandatory: "Attack Kind*:",
  attackMechanismMandatory: "Attack Mechanism*:",
  attackTacticMandatory: "Attack Tactic*:",
  trevCategoryMandatory: "TREV Category*:",
  trevPatternMandatory: "TREV Pattern*:",
  attackCategoryMandatory: "Attack Category*:",
  attackerCategoryMandatory: "Attacker Category*:",

  availableMeansMandatory: "Available Means*:",
  availableOpportunityMandatory: "Available Opportunity*:",
  threatGroupMandatory: "Threat Group*:",
  personMandatory: "Person*:",
  systemAssetTypeMandatory: "System Asset Type*:",
  threatGroupOptional: "Threat Group (Optional):",
  ttpOptional: "TTP (optional):",
  accessPointOptional: "Access Point (Optional):",
  targetOptional: "Target (Optional):",
  systemAssetOptional: "System Asset (Optional):",
  likelihoodOptional: "Likelihood (Optional):",
  availableMeansOptional: "Available Means (Optional):",
  availableOpportunityOptional: "Available Opportunity (Optional):",
  borrowHostIdMandatory: "Host ID*:",
  borrowDurationMandatory: "Number of Days*:",

  placeholder: "Select",
  inBuses: "Select In Bus(es):",
  groups: "Select Group(s):",
  activity: "Select Activity:",
  attacks: "Select Attack(s):",
  child: "Select Child:",
  capabilities: "Select Capability(s):",
  channels: "Select Channel(s):",
  controlFamily: "Select Control Family:",
  controlTypes: "Select Control Type(s):",
  datas: "Select Data(s):",
  entryPoints: "Select Entry Point(s):",
  eventNodes: "Select Event Node(s):",
  exchanges: "Select Exchange(s):",
  nexts: "Select Next(s):",
  nodes: "Select Node(s):",
  ownedDatas: "Select Owned Data(s):",
  pathNodes: "Select Path Node(s):",
  persons: "Select Person(s):",
  pivots: "Select Pivot(s):",
  users: "Select User(s):",
  storedDatas: "Select Stored Data(s):",
  systemAssets: "Select System Asset(s):",
  systemObjects: "Select System Object(s):",
  threatEvents: "Select Threat Event(s):",
  undesiredEvents: "Select Undesired Event(s):",
  download: "Select file to download: ",
  links: "Select Link(s):",
  tag: "Tag:",
  vulCategory: "Vulnerability Categories:",
  digitalArtifacts: "Select Digital Artifact(s):",
};

export const FORM_PLACEHOLDER = {
  name: "Name",
  note: "Note",
  standard: "Standard",
  strength: "Strength",
  cost: "Cost",
  impleGuidance: "Impl Guidance",
  assmtGuidance: "Assmt Guidance",
  version: "Version",
  resource: "Resource",
  ttl: "TTL",
  sourceURL: "Source URL",
  tag: "Tag",
  borrowHostId: "Host ID of machine being borrowed for",
  borrowDuration: "Days",
};

export const FORM_ERROR = {
  missingFields: "*Please fill out all fields in the form prior to submitting.",
  invalidCharacters: "*Some fields contain invalid characters.",
  uniqueName: "*Name must be unique.",
  preDefinedName: "*Cannot use predefined names.",
  duplicateProjectName: "*A project with this name already exists. Choose a different name.",
  duplicateAttackSurfaceName: "*An attack surface with this name already exists. Choose a different name.",
  duplicateCatEntityName: "*A category entity with this name already exists. Choose a different name.",
  duplicateSysAssetName: "*A system asset with this name already exists. Choose a different name.",
  duplicateSaTypeName: "*A system asset type with this name already exists. Choose a different name.",
  duplicateNodeName: "*A node with this name already exists. Choose a different name.",
  duplicateExchangeName: "*An exchange with this name already exists. Choose a different name.",
  duplicateDatatypeName: "*A datatype with this name already exists. Choose a different name.",
  duplicateActivityeName: "*An activity with this name already exists. Choose a different name.",
  duplicateDataflowName: "*A dataflow with this name already exists. Choose a different name.",
  duplicatePersonName: "*A person with this name already exists. Choose a different name.",
  duplicateResourceName: "*A resource with this name already exists. Choose a different name.",
  duplicateCapabilityName: "*A capability with this name already exists. Choose a different name.",
  duplicateMissionName: "*A mission with this name already exists. Choose a different name.",
};

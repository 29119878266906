import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IThreatDataSourcesProps {
  catalog?: string;
}
export const getThreatDataSources = ({ catalog }: IThreatDataSourcesProps) => {
  return Brm.threatDataSourceApi.findThreatDataSource({ catalog });
};

interface IUseThreatDataSources {
  catalog?: string;
  options?: QueryOption<typeof getThreatDataSources>;
}

/**
 * React-query hook for getting threat data sources
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatDataSources = ({ catalog, options }: IUseThreatDataSources = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatDataSources.catalog(catalog),
    queryFn: () => getThreatDataSources({ catalog }),
  });
};

import {
  // GenericImportApi,
  // GenericExportApi,
  LicensingApi,
  AuditApi,
  SavePointApi,
  BaselineImportApi,
  BorImportApi,
  SCANApi,
  KnowledgebaseApi,
  ControlCatalogApi,
  ThreatCatalogApi,
  D3FENDApi,
  SavePointImportApi,
  SavePointExportApi,
  RarExportApi,
  SctmImportApi,
  SctmExportApi,
  TraImportApi,
  TraExportApi,
  SystemImportApi,
  SystemExportApi,
  ImporterCommonApi,
} from "@kdmanalytics/brm-importer";
import BrmApiClient from "services/brm/BrmApiClient";

export const borImportApi = new BorImportApi(BrmApiClient.instance);
export const scanBorImportApi = new SCANApi(BrmApiClient.instance);
export const knowledgebaseApi = new KnowledgebaseApi(BrmApiClient.instance);
export const controlCatalogIoApi = new ControlCatalogApi(BrmApiClient.instance);
export const threatCatalogIoApi = new ThreatCatalogApi(BrmApiClient.instance);
export const defendCatalogIoApi = new D3FENDApi(BrmApiClient.instance);
export const baselineImportApi = new BaselineImportApi(BrmApiClient.instance);
export const savePointImportApi = new SavePointImportApi(BrmApiClient.instance);
// export const commonImportApi = new ImporterCommonApi(BrmApiClient.instance);
export const savePointExportApi = new SavePointExportApi(BrmApiClient.instance);
export const rarExportApi = new RarExportApi(BrmApiClient.instance);
export const sctmImportApi = new SctmImportApi(BrmApiClient.instance);
export const sctmExportApi = new SctmExportApi(BrmApiClient.instance);
export const traImportApi = new TraImportApi(BrmApiClient.instance);
export const traExportApi = new TraExportApi(BrmApiClient.instance);

const importBrmClientApi = new BrmApiClient();
importBrmClientApi.timeout = 600000;
export const commonImportApi = new ImporterCommonApi(importBrmClientApi);

export const systemImportApi = new SystemImportApi(importBrmClientApi);
export const systemExportApi = new SystemExportApi(BrmApiClient.instance);

// export const genericImportApi = new GenericImportApi(BrmApiClient.instance);
// export const genericExportApi = new GenericExportApi(BrmApiClient.instance);
export const licensingApi = new LicensingApi(BrmApiClient.instance);
export const auditApi = new AuditApi(BrmApiClient.instance);
export const savePointApi = new SavePointApi(BrmApiClient.instance);

import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import Title from "components/elements/StyledTitle";
import ShowHideButton from "components/elements/ShowHideButton";
import { COMMON, TYPE } from "constants/brm";
import { ThreatApi, AdminApi } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { useProject } from "features/brm/hooks/useProject";
import {
  DetailsContainer,
  DetailsContainerParent,
  DetailsContainerSingleTable,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";

import {
  createColumnMapped,
  createColumnActions,
  createColumnBooleanMapped,
  createColumnMappedUrlEdit,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import * as BrmGql from "generated/graphql";
import { ThreatGroupTabs } from "features/global-threat/components/tabs/ThreatGroupTabs";

const listUrl = RoutePath.ThreatGroups;
// const threatAnalystRole = userRoles.properties[userRoles.THREAT_ANALYST].key;

interface INamedElement {
  id: string;
  name: string;
}

export const ThreatGroup = () => {
  const { isThreatAnalyst } = useRoles();
  const [currentProject] = useProject();

  const [selectedElement, setSelectedElement] = useState<INamedElement>({ id: "", name: "" });
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  const updatedIsNationState = useRef("");
  const updatedTier = useRef("");
  const updatedUrl = useRef("");
  const localProjectId = useRef(currentProject?.id);

  const { id } = useParams();
  const navigate = useNavigate();

  // Because the the threat group shortdto doesn't give us enough information about organizations
  // we have to do this work around ... use the graphql query that gets all threat groups but with
  // some of the other information missing and horribly blend the single query and the graphql query
  // and we should be able to get the information that we need to respect read/write permissions
  const {
    data: allThreatGroups,
    isError: isAllThreatGroupsError,
    error: allThreatGroupsError,
  } = BrmGql.useGetThreatGroupsQuery();

  const { data: selfData } = AdminApi.useSelf({ options: {} });

  const {
    data: threatGroupData,
    isError: isThreatGroupDataError,
    error: threatGroupError,
  } = ThreatApi.useThreatGroup({
    threatGroupId: id || "",
    options: {
      enabled: !!id,
      onSuccess: (d) => {
        setSelectedElement(d);
        // setThreatCatalogId(d.catalog);
      },
    },
  });

  // console.log("threatGroupData", threatGroupData);

  const catalogId = threatGroupData?.catalog || "";

  const {
    data: threatCatalogName,
    isError: isThreatCatalogNameError,
    error: threatCatalogNameError,
  } = ThreatApi.useThreatCatalogName({
    threatCatalogId: catalogId,
    options: {
      enabled: !!threatGroupData,
    },
  });

  const {
    data: threatTiers,
    isError: isThreatTiersError,
    error: threatTierError,
  } = ThreatApi.useThreatTiers({
    options: {
      select: (data) => data.map((e: any) => ({ name: e, id: e })),
    },
  });

  const { mutate: setIsNation } = ThreatApi.useSetThreatGroupIsNation({
    options: {
      onSettled: () => {
        updatedIsNationState.current = "";
      },
    },
  });

  const { mutate: setTier } = ThreatApi.useSetThreatGroupTier({
    options: {
      onSettled: () => {
        updatedTier.current = "";
      },
    },
  });

  const { mutate: setSourceUrl } = ThreatApi.useSetThreatGroupSourceUrl({
    options: {
      onSettled: () => {
        updatedUrl.current = "";
      },
    },
  });

  useEffect(() => {
    if (currentProject?.id !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [currentProject, navigate]);

  const modData = useMemo(() => {
    if (threatGroupData && allThreatGroups) {
      const gqlThreatGroup = allThreatGroups.threatGroups?.filter((tg) => tg?.id === threatGroupData.id);

      if (Array.isArray(gqlThreatGroup) && gqlThreatGroup.length && gqlThreatGroup[0]) {
        let threatGroupToDisplay: any = {};
        threatGroupToDisplay = { ...threatGroupData };
        threatGroupToDisplay.catalogName = threatCatalogName?.result;
        threatGroupToDisplay.catalog = { ...gqlThreatGroup[0].catalog };
        return threatGroupToDisplay;
      }
    }
    return undefined;
  }, [allThreatGroups, threatCatalogName?.result, threatGroupData]);

  const createButton = useCallback(
    (cellProps) => {
      const currentRow = cellProps.cell.row.original;
      if (
        selfData &&
        isThreatAnalyst &&
        currentRow.catalog.id !== COMMON.defaultUuid &&
        currentRow.catalog.organization.id === selfData.organization
      ) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={currentRow.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedIsNationState.current !== "" && updatedIsNationState.current !== "Select isNationState") {
                setIsNation({ threatGroupId: selectedRowId, isNation: updatedIsNationState.current });
              }

              if (updatedTier.current !== "" && updatedTier.current !== "Select tier") {
                setTier({ threatGroupId: selectedRowId, tier: updatedTier.current });
              }

              if (updatedUrl.current !== "") {
                setSourceUrl({ threatGroupId: selectedRowId, url: updatedUrl.current });
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return null;
    },

    [selfData, isThreatAnalyst, selectedRowId, editMode, setIsNation, setTier, setSourceUrl]
  );

  const columns = useMemo(
    () => [
      createColumnBooleanMapped("isNationState", selectedRowId, editMode, updatedIsNationState),
      createColumnMapped("tier", selectedRowId, threatTiers, editMode, updatedTier),
      createColumnMappedUrlEdit("url", selectedRowId, editMode, updatedUrl),
      createColumnMappedNoEdit("tcatalog"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, threatTiers, selectedRowId, createButton]
  );

  if (isAllThreatGroupsError) {
    return <ErrorBanner msg={(allThreatGroupsError as any)?.statusText} />;
  }

  if (isThreatGroupDataError) {
    return <ErrorBanner msg={(threatGroupError as any)?.statusText} />;
  }

  if (isThreatTiersError) {
    return <ErrorBanner msg={(threatTierError as any)?.statusText} />;
  }

  if (isThreatCatalogNameError) {
    return <ErrorBanner msg={(threatCatalogNameError as any)?.statusText} />;
  }

  if (modData && selectedElement) {
    return (
      <>
        <Title id="ThreatGroupFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={[modData]}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "ThreatGroupFullDetails_table" }}
              elementName={TYPE.threatGroup}
            />
          </DetailsContainerSingleTable>
        ) : null}

        {selectedElement.id && (
          <DetailsContainerParent>
            <DetailsContainer>
              <ThreatGroupTabs selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }
  return <Loading />;
};

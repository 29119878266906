// import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export const DigitalArtifactTabs = () => {
  return (
    <Tabs>
      <TabList>
        <Tab>Information</Tab>
      </TabList>

      <TabPanel>
        {/* <OffensiveTechniquesDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} /> */}
      </TabPanel>
    </Tabs>
  );
};

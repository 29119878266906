// Components
import systemModals from "components/modals/Modals";
import { AddAllocatedDigitalArtifactForm } from "features/mitre-digitalArtifacts/components/forms/AddAllocatedDigitalArtifactForm";
// Constants
import { TYPE } from "constants/brm";

interface IAddAllocatedDigitalArtifactsDialog {
  elementName: string;
  elementId: string;
  isVisible: boolean;
  toggle: () => void;
}

export const AddAllocatedDigitalArtifactsDialog = ({
  elementName,
  elementId,
  isVisible,
  toggle,
}: IAddAllocatedDigitalArtifactsDialog) => {
  return (
    <>
      {isVisible
        ? systemModals.customModal(
            "AddAllocatedDigitalArtifactDialog",
            isVisible,
            toggle,
            <AddAllocatedDigitalArtifactForm elementName={elementName} elementId={elementId} toggle={toggle} />,
            `Add Allocated ${TYPE.digitalArtifacts}`
          )
        : null}
    </>
  );
};

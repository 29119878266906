import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IAffectedPlatformsProps {
  catalog?: string;
}
export const getAffectedPlatforms = ({ catalog }: IAffectedPlatformsProps) => {
  return Brm.affectedPlatformApi.findAffectedPlatform({ catalog });
};

interface IUseAffectedPlatforms {
  catalog?: string;
  options?: QueryOption<typeof getAffectedPlatforms>;
}

/**
 * React-query hook for getting affected platforms
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useAffectedPlatforms = ({ catalog, options }: IUseAffectedPlatforms = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.affectedPlatformKeys.catalog(catalog),
    queryFn: () => getAffectedPlatforms({ catalog }),
  });
};

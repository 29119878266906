import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import * as AttackApi from "brm/risk-model/attack/api";
import { BrmError, DetailTableType, SystemApi } from "features/brm";

/**
 * custom-hook to retreive all attacks
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} config - configuration for the react-query
 * @returns react-query for attacks
 */

interface IAttacksProps {
  elementId: string;
  elementName: string;
  variantId: string;
  projectId: string;
  config: {};
}

interface IGetElementAttacksProps {
  elementId: string;
  elementName: string;
  variantId: string;
  projectId: string;
  attackData: any;
  targetAttacksData: any;
}

interface IAttacksMap {
  [index: string]: (id?: string) => any;
}

export const getElementAttacks = async ({
  elementId,
  elementName,
  variantId,
  projectId,
  attackData,
  targetAttacksData,
}: IGetElementAttacksProps) => {
  const attacksMap: IAttacksMap = {
    [DetailTableType.LINKS.key]: (id) => Brm.linkApi.getLinkAllAttack(id, { variant: variantId }),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllAttack(id, { variant: variantId }),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllAttack(id, { variant: variantId }),
    [DetailTableType.SYSTEM_ASSET_TYPES.key]: (id) =>
      Brm.systemAssetTypeApi.getSystemAssetTypeAllAttack(id, { variant: variantId }),
    [DetailTableType.SYSTEM_ASSETS.key]: (id) => Brm.systemAssetApi.getSystemAssetAllAttack(id, { variant: variantId }),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllAttack(id, { variant: variantId }),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) =>
      Brm.undesiredEventApi.getUndesiredEventAllAttack(id, { variant: variantId }),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllAttack(id, { variant: variantId }),
    [DetailTableType.VULNERABILITIES.key]: (id) =>
      Brm.vulnerabilityApi.getVulnerabilityAttack(id, { variant: variantId }),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventAllAttack(id, { variant: variantId }),
    [DetailTableType.CONTROL_TYPES.key]: (id) => Brm.controlTypeApi.getControlTypeAllAttack(id, { variant: variantId }),
    // [DetailTableType.TTPS.key]: (id) => Brm.ttpApi.getTTPAllAttack(id, { variant: variantId }),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllAttack(id, { variant: variantId }),
    attackmechanisms: (id) =>
      Brm.attackApi
        .findAttack(projectId, { variant: variantId })
        .filter((attack: { mechanism: { id: string | undefined } }) => attack?.mechanism?.id === id),
    attackkinds: (id) =>
      Brm.attackApi
        .findAttack(projectId, { variant: variantId })
        .filter((attack: { kind: { id: string | undefined } }) => attack?.kind?.id === id),
    attacktactics: (id) =>
      Brm.attackApi
        .findAttack(projectId, { variant: variantId })
        .filter((attack: { tactic: { id: string | undefined } }) => attack?.tactic?.id === id),
    [DetailTableType.ATTACKS.key]: () => attackData,
    [DetailTableType.CAPABILITIES.key]: () => targetAttacksData,
    [DetailTableType.PERSONS.key]: () => targetAttacksData,
    [DetailTableType.MISSIONS.key]: () => targetAttacksData,
    [DetailTableType.DATATYPES.key]: () => targetAttacksData,
    [DetailTableType.RESOURCES.key]: () => targetAttacksData,
    [DetailTableType.DATAFLOWS.key]: () => targetAttacksData,
    [DetailTableType.ACTIVITIES.key]: () => targetAttacksData,
  };

  if (attacksMap[elementName]) {
    const data = await attacksMap[elementName](elementId);
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
};

export const useElementAttacks = ({ elementId, elementName, projectId, variantId, config = {} }: IAttacksProps) => {
  const isTargetElement =
    elementName === DetailTableType.CAPABILITIES.key ||
    elementName === DetailTableType.PERSONS.key ||
    elementName === DetailTableType.MISSIONS.key ||
    elementName === DetailTableType.DATATYPES.key ||
    elementName === DetailTableType.RESOURCES.key ||
    elementName === DetailTableType.DATAFLOWS.key ||
    elementName === DetailTableType.ACTIVITIES.key;

  const { data: attackData } = AttackApi.useAttack({
    attackId: elementId,
    variantId,
    config: {
      enabled: !!elementId && elementName === DetailTableType.ATTACKS.key,
    },
  });

  const { data: targetAttacksData } = SystemApi.useElementAllAttack({
    elementId,
    variantId,
    options: {
      enabled: !!elementId && !!variantId && isTargetElement,
    },
  });

  return useQuery({
    ...config,
    queryKey: AttackApi.attackKeys.element(elementId, elementName, projectId, variantId, attackData, targetAttacksData),
    queryFn: () => getElementAttacks({ elementName, elementId, projectId, variantId, attackData, targetAttacksData }),
    enabled: (isTargetElement && !!targetAttacksData) || !isTargetElement,
  });
};

import { useState, useMemo, useRef, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// Componenets
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
// Styles
import Title from "components/elements/StyledTitle";
import * as S from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";

import ShowHideButton from "components/elements/ShowHideButton";
// Services
import { variantApi } from "services/brm/component-service";
import { sctmApi } from "services/brm/mitigation-service";
import handleApi from "services/brm/api-util";
// Hooks
import useRedirectDetailsPage from "hooks/useRedirectDetailsPage";

// State
import { useRecoilValue, useSetRecoilState } from "recoil";
import { projectIdState } from "atoms";
import { selectedControlCatalogIdState } from "atoms/atoms-global-control";
import {
  createColumnMapped,
  createColumnActions,
  createColumnBooleanNoEditMapped,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnBooleanMapped,
  createColumnBaselineMapped,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
import { COMMON, TYPE } from "constants/brm";
import { MitigationApi, GlobalControlApi, useVariant, useRoles } from "features/brm";
import { RoutePath } from "routes/route-paths";
// import { VariantTab } from "../../components/tabs/VariantTab";
import { VariantTab } from "brm/tabs/VariantTab";

export function VariantOverview() {
  const { isRiskAnalyst } = useRoles();
  const [selectedElement, setSelectedElement] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isControlCatalogEditingEnabled, setIsControlCatalogEditingEnabled] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState("");
  const { id } = useParams();
  const updatedBaseline = useRef("");
  const updatedCompliance = useRef("");
  const updatedCatalog = useRef("");
  const navigate = useNavigate();

  // Global state
  const projectId = useRecoilValue(projectIdState);
  const { variantId, handlers } = useVariant();
  const setSelectedControlCatalogId = useSetRecoilState(selectedControlCatalogIdState);

  const localProjectId = useRef(projectId);
  const listUrl = RoutePath.Variants;

  // console.log("id", id);
  // console.log("variantId", variantId);
  const {
    data: getVariantFullData,
    isError: isGetVariantFullDataError,
    error: getVariantFullDataError,
  } = MitigationApi.useVariantFullDetails({
    variantId: id,
    projectId,
    setSelectedElement,
    setSelectedControlCatalogId,
    options: { enabled: !!id && !!projectId && isRiskAnalyst, cacheTime: 0 },
  });

  const { data: catalogList } = GlobalControlApi.useControlCatalogs({ options: { enabled: isRiskAnalyst } });

  const { mutate: setVariantBaseline } = MitigationApi.useUpdateVariantBaseline();
  const { mutateAsync: setVariantProperty } = MitigationApi.useSetVariantProperty();
  const { mutate: setVariantCompliance } = MitigationApi.useSetVariantCompliance();
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  const setNewCntrlCatalogId = useCallback(
    (newCntrlCatalogId) => {
      if (newCntrlCatalogId) {
        setSelectedControlCatalogId(newCntrlCatalogId);
      }
    },
    [setSelectedControlCatalogId]
  );

  const createButton = useCallback(
    (cellProps) => (
      <ButtonCell
        selectedRowId={selectedRowId}
        elementId={cellProps.cell.row.original.id}
        handleConfirmEditClick={async () => {
          setEditMode(false);

          // console.log("updatedCatalog", updatedCatalog.current);
          if (updatedCatalog.current !== "" && updatedCatalog.current !== "Select cntrlCatalog") {
            // console.log("updatedCatalog2", updatedCatalog.current);
            try {
              await setVariantProperty({
                variantId: id,
                property: "catalog",
                value: updatedCatalog.current,
              });
              updatedCatalog.current = "";
            } catch (err) {
              toast.error(`Setting control catalog caused an error: ${err}`);
            }
          }

          if (updatedBaseline.current !== "" && updatedBaseline.current !== "Select baseline") {
            setVariantBaseline(
              { variantId: id, baselineId: updatedBaseline.current },
              {
                onSuccess: async () => {
                  const { id: sctmId } = await variantApi.getVariantSctm(selectedRowId);
                  // also change the SCTM baseline accordingly, so that they are in sync
                  if (sctmId) {
                    await handleApi(
                      sctmApi.setSCTMBaselineWithHttpInfo(sctmId, {
                        body: updatedBaseline.current,
                      })
                    );
                  }
                  updatedBaseline.current = "";
                },
              }
            );
          }

          if (updatedCompliance.current !== "") {
            setVariantCompliance({ variantId: selectedRowId, isCompliance: updatedCompliance.current });
          }
        }}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    ),
    [selectedRowId, editMode, setVariantBaseline, id, setVariantProperty, setVariantCompliance]
  );

  const makeScoreCell = ({ row }) => {
    if (row.original.initialScore?.value) {
      return row.original.initialScore.value?.toFixed(1);
    }
    return <>-</>;
  };

  const makeMitigatedRiskCell = ({ row }) => {
    if (row.original.mitigatedRisk?.calcValue) {
      return row.original.mitigatedRisk.calcValue?.toFixed(1);
    }
    return <>-</>;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Initial Risk Score",
        accessor: "score.calcValue",
        disableFilters: true,
        Cell: makeScoreCell,
      },
      {
        Header: "Mitigated Risk Score",
        accessor: "mitigatedRisk.calcValue",
        disableFilters: true,
        Cell: makeMitigatedRiskCell,
      },
      createColumnBooleanMapped("isCompliance", selectedRowId, editMode, updatedCompliance),
      createColumnBooleanNoEditMapped("isCloned"),
      createColumnMapped(
        "cntrlCatalog",
        selectedRowId,
        catalogList,
        editMode && isControlCatalogEditingEnabled,
        updatedCatalog,
        setNewCntrlCatalogId
      ),
      createColumnBaselineMapped("baseline", selectedElement?.id, editMode, updatedBaseline),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [
    selectedRowId,
    editMode,
    catalogList,
    isControlCatalogEditingEnabled,
    setNewCntrlCatalogId,
    selectedElement?.id,
    createButton,
  ]);

  useRedirectDetailsPage(projectId, localProjectId, listUrl);

  useEffect(() => {
    if (getVariantFullDataError) {
      handlers.resetVariant();
      toast.error(getVariantFullDataError.message, { id: "getVariantFullDataError", duration: 5000 });
      navigate(listUrl);
    }
  }, [getVariantFullDataError, handlers, isGetVariantFullDataError, listUrl, navigate]);

  useEffect(() => {
    if (variantId !== COMMON.nullUuid && variantId !== id && variantId !== "") {
      navigate(RoutePath.Variant.replace(":id", variantId));
    }
    if (variantId === COMMON.nullUuid && variantId !== id) {
      navigate(listUrl);
    }
  }, [navigate, id, variantId, listUrl]);

  if (getVariantFullData) {
    return (
      <S.FullDetailsContainer id="VariantFullDetails">
        <Title>
          {selectedElement?.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable && (
          <S.DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={getVariantFullData}
              columns={columns}
              setSelectedElement={() => setSelectedElement({ ...getVariantFullData[0], type: TYPE.variant })}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "VariantFullDetails_table" }}
              elementName={TYPE.variant}
            />
          </S.DetailsContainerSingleTable>
        )}

        {selectedElement?.id && (
          <S.DetailsContainerParent showSummaryTable={showSummaryTable}>
            <S.DetailsContainer>
              <VariantTab
                selectedElement={selectedElement}
                setIsControlCatalogEditingEnabled={setIsControlCatalogEditingEnabled}
              />
            </S.DetailsContainer>
          </S.DetailsContainerParent>
        )}
      </S.FullDetailsContainer>
    );
  }

  return <Loading />;
}

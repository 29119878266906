import { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { BrmDetailTable } from "brm/tables/BrmTables";

import { createColumnMappedNoEdit, createColumnBooleanNoEditMapped } from "brm/tables/services/column/columnFactory";
import { CenterText, ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as S from "brm/styles/details-table.styles";
import { DetailTableType, useRoles } from "features/brm";
import { projectIdState } from "atoms";
import { useGetElementCveFindings } from "./api";

interface ICveFindingsTableProps {
  elementNameInUrl: string;
  // selectedElement: {
  //   id: string;
  //   category: string;
  //   object: {
  //     id: string;
  //   };
  // };
  selectedElement: any;
  tableTitle: string;
}

const CveFindingsTable = ({ elementNameInUrl, selectedElement, tableTitle }: ICveFindingsTableProps) => {
  const { isRiskAnalyst } = useRoles();

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("cwe"),
      createColumnMappedNoEdit("cve"),
      createColumnMappedNoEdit("severity"),
      createColumnMappedNoEdit("score"),
      createColumnMappedNoEdit("vector"),
      createColumnBooleanNoEditMapped("fixed"),
      createColumnMappedNoEdit("artifact"),
      createColumnMappedNoEdit("description"),
    ],
    []
  );

  const projectId = useRecoilValue(projectIdState);
  const [, setSelectedAllocatedControl] = useState([]);

  const { data: getCveFindingsData, isError: isgetCveFindingsDataError } = useGetElementCveFindings({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    elementCategory: selectedElement.category,
    nodeId: selectedElement.object?.id,
    projectId,
    options: {
      enabled:
        !!selectedElement.id &&
        isRiskAnalyst &&
        (elementNameInUrl === DetailTableType.NODES.key ||
          elementNameInUrl === DetailTableType.VULNERABILITIES.key ||
          elementNameInUrl === DetailTableType.PROJECTS.key ||
          elementNameInUrl === DetailTableType.RISKS.key),
    },
  });

  if (
    elementNameInUrl !== DetailTableType.NODES.key &&
    elementNameInUrl !== DetailTableType.VULNERABILITIES.key &&
    elementNameInUrl !== DetailTableType.PROJECTS.key &&
    elementNameInUrl !== DetailTableType.RISKS.key
  ) {
    return <CenterText msg="Vulnerability Findings are not applicable " />;
  }

  if (isgetCveFindingsDataError) {
    return <ErrorBanner msg="Error while loading CVE findings data" />;
  }

  if (getCveFindingsData) {
    return (
      <S.DetailsContainer id="CveFindingsTable_detailsPanel">
        <BrmDetailTable
          data={getCveFindingsData}
          columns={columns}
          setSelectedElement={setSelectedAllocatedControl}
          customProps={{ id: "CveFindingsTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

export default CveFindingsTable;

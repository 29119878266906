import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import { ImportContext, ImportContextValues } from "../../constants";

export interface IImportSubmission {
  projectId: string;
  context: ImportContextValues;
  result: any;
}

interface ImporterFunctionMap {
  [key: string]: any;
}

/**
 * Start processing an already uploaded submission
 *
 * @param {string} projectId - uuid of the project
 * @param {string} context - import context
 * @returns
 */
export const importSubmission = async ({ projectId, context, result }: IImportSubmission) => {
  const importer: ImporterFunctionMap = {
    [ImportContext.bor]: () =>
      Brm.borImportApi.importSubmissionBor(projectId, {
        importSubmission: result,
      }),
    [ImportContext.sbom]: () =>
      Brm.scanBorImportApi.importScanSubmission(projectId, {
        importSubmission: result,
      }),
    [ImportContext.baseline]: () =>
      Brm.baselineImportApi.importSubmissionBaseline(projectId, {
        importSubmission: result,
      }),
    [ImportContext.savepoint]: () =>
      Brm.savePointImportApi.importSubmissionSavepoint(projectId, {
        importSubmission: result,
      }),
    [ImportContext.sctm]: () =>
      Brm.sctmImportApi.importSubmissionSctm(projectId, {
        importSubmission: result,
      }),
    [ImportContext.tra]: () =>
      Brm.traImportApi.importSubmissionTra(projectId, {
        importSubmission: result,
      }),
    [ImportContext.system]: () =>
      Brm.systemImportApi.importSubmissionSystem(projectId, {
        importSubmission: result,
      }),
  };

  if (!Object.keys(importer).some((k) => k === context)) {
    throw new Error(`Unsupported import context: ${context}`);
  }

  return importer[context]();
};

interface IUseSubmission {
  options?: MutationOption<typeof importSubmission>;
}

/**
 * Custom hook to start processing an already uploaded submission
 *
 *
 * @param {object} options - react-query options
 */
export const useImportSubmission = ({ options }: IUseSubmission = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: importSubmission,
  });
};

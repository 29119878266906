import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./digital-artifacts-query-key-factory";

export const getDigitalArtifacts = ({ catalog }: { catalog?: string }) => {
  return Brm.digitalArtifactApi.findDigitalArtifact({ catalog });
};

interface IUseDigitalArtifacts {
  catalog?: string;
  options?: QueryOption<typeof getDigitalArtifacts>;
}

/**
 * React-query hook for getting all digital artifacts
 *
 * @param
 * @returns react-query for getDigitalArtifacts
 */
export const useDigitalArtifacts = ({ catalog, options }: IUseDigitalArtifacts = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.digitalArtifactsQueryKey.catalog(catalog),
    queryFn: () => getDigitalArtifacts({ catalog }),
  });
};

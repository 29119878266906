// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyledError from "components/forms/FormStyledError";
import { useSetRecoilState } from "recoil";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import * as S from "./CveScan.styles";

interface IOptionsProps {
  nodes: {
    selection: string[];
    values: string[];
  };
}

interface IStep2Props {
  handleSubmission: () => void;
  options: IOptionsProps;
  setUploadModalIsOpen: (arg: boolean) => {};
  nodes: string[];
  setNodes: (arg: string[]) => void;
  postError: string;
  onCancel: () => void;
}

export const Step2 = ({
  options,
  handleSubmission,
  setUploadModalIsOpen,
  nodes,
  setNodes,
  postError,
  onCancel,
}: IStep2Props) => {
  const setIsSubmissionPending = useSetRecoilState(isSubmissionPendingState);

  return (
    <div id="SBOM_step2">
      <label>Select a node:</label>
      <br />
      <br />
      <S.ScanNodeOptions>
        {options?.nodes?.values &&
          options.nodes.values.map((val1) => (
            <div key={val1} className="options">
              <input
                type="radio"
                name={val1}
                id={val1}
                checked={val1 === nodes[0]}
                value={val1}
                onChange={(e) => {
                  setNodes([e.target.value]);
                }}
              />
              <label style={{ paddingLeft: "5px" }}>{val1}</label>
            </div>
          ))}
      </S.ScanNodeOptions>

      <FormStyledError id="SBOM_Error">{postError}</FormStyledError>
      <DialogButtonDivStyled id="SBOM_buttons">
        <DialogButtonStyled
          onClick={() => {
            setIsSubmissionPending(false);
            setUploadModalIsOpen(false);
            onCancel();
          }}
          id="SBOM_cancelButton"
        >
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled onClick={() => handleSubmission()} id="SBOM_submitButton">
          Submit
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </div>
  );
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./nist-controls-query-key-factory";

export const getNistControls = ({ catalog }: { catalog?: string }) => {
  return Brm.nistControlApi.findNistControl({ catalog });
};

interface IUseNistControls {
  catalog?: string;
  options?: QueryOption<typeof getNistControls>;
}

/**
 * React-query hook for getting all NIST Controls
 *
 * @param
 * @returns react-query for getNistControls
 */
export const useNistControls = ({ catalog, options }: IUseNistControls = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nistControlsQueryKey.catalog(catalog),
    queryFn: () => getNistControls({ catalog }),
  });
};

import PropTypes from "prop-types";
import { useState } from "react";
import { sortByLabel } from "utils/sorting";
import SelectAll from "components/forms/SelectAllReactSelect";
import { FORM_LABEL } from "constants/brm";
// import { genericImportApi } from "services/brm/importer-service";
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { isControlsCoordinator } from "services/user";
import { useRecoilValue } from "recoil";
import { userRoleState } from "atoms/atoms-admin";
import { ImportApi, ImportContext } from "features/brm";
import { useRoles } from "hooks";

export const DownloadForm = ({ setDownloadModalIsOpen, filesToDownload, projectId, context }) => {
  // console.log({ setDownloadModalIsOpen, filesToDownload, projectId, context });
  const { isRiskAnalyst } = useRoles();

  const [selectedFileOptions, setSelectedFileOptions] = useState([]);
  const [postError, setPostError] = useState("");
  const userRole = useRecoilValue(userRoleState);
  const getReactSelectArray = (array) => {
    const objects = [];
    if (array && array.length > 0) {
      return array.map(({ assetId, name }) => ({ value: assetId, label: name })).sort(sortByLabel);
    }
    return objects;
  };

  const [fileOptions] = useState(getReactSelectArray(filesToDownload));

  const handleFileSelection = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedFileOptions(items);
    setPostError("");
  };

  const downloadFile = (options, contextDownload) => {
    // console.log("contextDownload:", contextDownload);
    options.map(async (each) => {
      try {
        // console.log("here", { projectId, contextDownload, assetId: each.value });
        // const { data } = await genericImportApi.importAssetDownloadWithHttpInfo(contextDownload, projectId, each.value);
        const data = await ImportApi.downloadAsset({ projectId, context: contextDownload, assetId: each.value });
        const fileName = each.label;
        const fileType = fileName.split(".").pop();
        const blob = new Blob([data], { type: `application/${fileType}` });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error("err in get download files :", err);
        setPostError("error in files download");
      }
    });
  };

  const handleDownload = () => {
    if (selectedFileOptions.length > 0) {
      setDownloadModalIsOpen(false);
      if (isControlsCoordinator(userRole)) {
        downloadFile(selectedFileOptions, ImportContext.sctm);
      } else if (isRiskAnalyst) {
        // console.log("context handleDownload:", context);
        downloadFile(selectedFileOptions, context);
      } else {
        downloadFile(selectedFileOptions, ImportContext.system);
      }
    } else setPostError("No files selected. Please Select a file To download");
  };
  return (
    <div style={{ height: "250px" }} id="DownloadForm">
      <label>{FORM_LABEL.download}</label>
      <SelectAll
        isMulti
        options={fileOptions || []}
        defaultValue={selectedFileOptions}
        value={selectedFileOptions}
        onChange={handleFileSelection}
        allowSelectAll
        allOption={{
          label: "Select all",
          value: "*",
        }}
        elementId="DownLoadForm"
      />
      <DialogButtonDivStyled>
        <DialogButtonStyled id="DownloadForm_button" onClick={handleDownload}>
          Download
        </DialogButtonStyled>
      </DialogButtonDivStyled>
      {postError ? <FormStyledError id="DownloadForm_Error">*{postError}</FormStyledError> : null}
    </div>
  );
};
DownloadForm.propTypes = {
  filesToDownload: PropTypes.any,
  projectId: PropTypes.any,
  setDownloadModalIsOpen: PropTypes.func,
  context: PropTypes.string,
};

import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

import { LoadingSpinner as Loading } from "components/elements";
import SelectAll from "components/forms/SelectAllReactSelect";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

import { FORM_LABEL } from "constants/brm";

import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import getReactSelectArray from "utils/react-select-array";
import { SystemApi } from "features/brm";
import { useAddAllocatedDigitalArtifactForm } from "./useAddAllocatedDigitalArtifactForm";
import { menuListAndContainerStyle, AddAllocatedControlPanel } from "./AddAllocatedControlForm.styles";

interface IAddAllocatedDigitalArtifactsDialog {
  elementName: string;
  elementId: string;
  toggle: () => void;
}

interface IOption {
  label: string;
  value: number;
}

const allOption = {
  label: "Select all",
  value: "*",
};

export const AddAllocatedDigitalArtifactForm = ({
  elementName,
  elementId,
  toggle,
}: IAddAllocatedDigitalArtifactsDialog) => {
  const { selectedDas, postError, daList, isErrorDaList, setSelectedDas, setPostError } =
    useAddAllocatedDigitalArtifactForm();

  const queryClient = useQueryClient();

  const { mutate: addSystemAssetTypeDigitalArtifact } = SystemApi.useAddSystemAssetTypeDigitalArtifact({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(SystemApi.digitalArtifactsKeys.element(elementId, elementName));
      },
      onError: () => {
        setPostError("Error adding Digital Artifact");
      },
    },
  });

  if (isErrorDaList) {
    toast.error("Error occured while fetching digital artifacts list");
  }
  async function handleSubmit(e: any) {
    e.preventDefault();

    selectedDas.forEach((da) => {
      addSystemAssetTypeDigitalArtifact({ saTypeId: elementId, daId: da.value });
    });
    toggle();
  }

  const handleDaSelectChange = (selectedItems: IOption[] | IOption | null) => {
    const options = Array.isArray(selectedItems) ? selectedItems : [];
    setSelectedDas(options);
  };

  if (daList) {
    return (
      <AddAllocatedControlPanel>
        <FormStyled>
          <form onSubmit={handleSubmit} action="">
            <div className="form-style">
              <strong>
                <label id="AllocatedDigitalArtifactAddForm_daLabel">{FORM_LABEL.digitalArtifacts}</label>
              </strong>
              <SelectAll
                isMulti
                options={getReactSelectArray(daList)}
                defaultValue={selectedDas}
                value={selectedDas}
                onChange={handleDaSelectChange}
                styles={menuListAndContainerStyle}
                allowSelectAll
                allOption={allOption}
                elementId="AllocatedDigitalArtifactAddForm_das"
              />
              <br />
            </div>
            <FormStyledError>{postError}</FormStyledError>

            <DialogButtonDivStyled>
              <>
                <DialogButtonStyled onClick={() => toggle()} id="AllocatedDigitalArtifactAddForm_cancelButton">
                  Cancel
                </DialogButtonStyled>
                <DialogButtonStyled
                  type="submit"
                  id="AllocatedDigitalArtifactAddForm_addButton"
                  disabled={!selectedDas.length}
                >
                  Add Digital Artifacts
                </DialogButtonStyled>
              </>
            </DialogButtonDivStyled>
          </form>
        </FormStyled>
      </AddAllocatedControlPanel>
    );
  }

  return <Loading />;
};

import * as React from "react";
// import { useState } from "react";
// import { useQueryClient } from "@tanstack/react-query";
// Components
// Styles
import Title from "components/elements/StyledTitle";
import { ThreatApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { ThreatCampaignTabs } from "features/global-threat/components/tabs/ThreatCampaignTabs";

export const ThreatCampaign = () => {
  const { id } = useParams();

  const {
    data: threatCampaign,
    isError: isThreatCampaignError,
    error: threatCampaignError,
  } = ThreatApi.useThreatCampaign({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  const offensiveTechniqueResults = ThreatApi.useOffensiveTechniquesByIds({
    techniques: threatCampaign?.technique || [],
    options: {
      enabled: threatCampaign !== undefined,
    },
  });

  const offensiveTechniques = React.useMemo(() => {
    if (offensiveTechniqueResults.every((r) => r.isSuccess)) {
      return offensiveTechniqueResults.map((r) => r.data);
    }
    return undefined;
  }, [offensiveTechniqueResults]);

  if (isThreatCampaignError) {
    return <ErrorBanner msg={(threatCampaignError as any)?.statusText} />;
  }

  if (threatCampaign) {
    return (
      <>
        <Title>Threat Campaign: {threatCampaign.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{threatCampaign.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{threatCampaign.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{threatCampaign.sourceId}</div>
          <S.PropertyLabel>Source URL</S.PropertyLabel>
          <div>{threatCampaign.sourceURL}</div>
          <S.PropertyLabel>Software</S.PropertyLabel>
          <div>{threatCampaign.software}</div>
          <S.PropertyLabel>Technique</S.PropertyLabel>
          <div>{offensiveTechniques ? offensiveTechniques.map((r) => r.name).join(",") : <Loading />}</div>
          <S.PropertyLabel>Group</S.PropertyLabel>
          <div>{threatCampaign.group}</div>
          <S.PropertyLabel>Alias</S.PropertyLabel>
          <div>{threatCampaign.alias}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <ThreatCampaignTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};

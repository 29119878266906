import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
// Components
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, ThreatApi } from "features/brm";
import { ImportThreatCatalogFileModal } from "features/importer";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
// Services
import { threatCatalogApi } from "services/brm/global-threat-service";
// State
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import { DefensiveTacticsTable } from "features/mitre-d3fend/components/tables/DefensiveTacticsTable";
import { AddDefendCatalogForm } from "features/mitre-d3fend/components/forms/AddDefendCatalogForm";

export const DefensiveTactics = () => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => threatCatalogApi.deleteThreatCatalogWithHttpInfo(each.id)))
        .then(() => {
          queryClient.invalidateQueries(ThreatApi.threatCatalogKeys.all);
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, queryClient, selectedRows, setSelectedInstance]);

  const addThreatCatalog = () => {
    setModalIsOpen(true);
  };

  const header = (
    <Title id="DefensiveTactics_title">
      Defensive Tactics
      <Buttons id="DefensiveTactics_buttons">
        {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
        <AddButton onClick={addThreatCatalog} />
      </Buttons>
    </Title>
  );

  const main = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "DefensiveTactics_deleteModal",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.defensiveTactics}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.defensiveTactics
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "DefensiveTactics_addModal",
          modalIsOpen,
          setModalIsOpen,
          <AddDefendCatalogForm setModalIsOpen={setModalIsOpen} />,
          TYPE.defensiveTactics
        )}
      <ImportThreatCatalogFileModal
        show={uploadModalIsOpen}
        threatCatalogId={selectedInstance.id}
        onHide={() => {
          setUploadModalIsOpen(false);
        }}
      />
      <DefensiveTacticsTable setSelectedRows={setSelectedRows} setUploadModalIsOpen={setUploadModalIsOpen} />
    </>
  );

  return <ContentLayout pageId="DefensiveTactics_page" header={header} main={main} />;
};

import * as brm from "services/brm";

import { TYPE } from "constants/brm";

const getAttackMechanismNote = async (id) => {
  const finalData = {};
  // ToDo: Replace direct API access with react-query hook ThreatApi.useGtMeta
  const data = await brm.gtMetaApi.findGtMeta({ name: id });
  finalData.result = data[0].data.note;
  return finalData;
};

const getNotePromiseMap = {
  [TYPE.knowledgebase]: (id) => brm.kbApi.getKBNote(id),
  [TYPE.ruleset]: (id) => brm.rulesetApi.getRulesetNote(id),
  [TYPE.metadata]: () => {}, // Missing in api
  [TYPE.installation]: (id) => brm.installationApi.getInstallationNote(id),
  [TYPE.organization]: (id) => brm.organizationApi.getOrganizationNote(id),
  [TYPE.project]: (id) => brm.projectApi.getProjectNote(id),
  [TYPE.user]: (id) => brm.userApi.getUserNote(id),
  [TYPE.module]: (id) => brm.moduleApi.getModuleNote(id),
  [TYPE.system]: (id) => brm.systemApi.getSystemNote(id),
  [TYPE.variant]: (id) => brm.variantApi.getVariantNote(id),
  [TYPE.controlCatalog]: (id) => brm.controlCatalogApi.getControlCatalogNote(id),
  [TYPE.controlFamily]: (id) => brm.controlFamilyApi.getControlFamilyNote(id),
  [TYPE.controlType]: (id) => brm.controlTypeApi.getControlTypeNote(id),
  [TYPE.standardBaseline]: (id) => brm.baselineApi.getStandardBaselineNote(id),
  [TYPE.allocatedControl]: (id) => brm.allocatedControlApi.getAllocatedControlNote(id),
  [TYPE.sctm]: (id) => brm.sctmApi.getSCTMNote(id),
  [TYPE.attacker]: (id) => brm.attackerApi.getAttackerNote(id),
  [TYPE.attackerCat]: (id) => brm.attackerCategoryApi.getAttackerCategoryNote(id),
  [TYPE.threatCatalog]: (id) => brm.threatCatalogApi.getThreatCatalogNote(id),
  [TYPE.threatGroup]: (id) => brm.threatGroupApi.getThreatGroupNote(id),
  [TYPE.threatTier]: (id) => brm.threatTierApi.getThreatTierNote(id),
  [TYPE.node]: (id) => brm.nodeApi.getNodeNote(id),
  [TYPE.exchange]: (id) => brm.exchangeApi.getExchangeNote(id),
  [TYPE.mission]: (id) => brm.missionApi.getMissionNote(id),
  [TYPE.link]: (id) => brm.linkApi.getLinkNote(id),
  [TYPE.bus]: (id) => brm.busApi.getBusNote(id),
  [TYPE.activity]: (id) => brm.activityApi.getActivityNote(id),
  [TYPE.capability]: (id) => brm.capabilityApi.getCapabilityNote(id),
  [TYPE.person]: (id) => brm.personApi.getPersonNote(id),
  [TYPE.resource]: (id) => brm.resourceApi.getResourceNote(id),
  [TYPE.dataflow]: (id) => brm.dataflowApi.getDataFlowNote(id),
  [TYPE.datatype]: (id) => brm.dataTypeApi.getDataTypeNote(id),
  [TYPE.elementCat]: (id) => brm.elementCategoryApi.getElementCategoryNote(id),
  [TYPE.structuralCat]: (id) => brm.structCatApi.getStructuralCategoryNote(id),
  [TYPE.systemAsset]: (id) => brm.systemAssetApi.getSystemAssetNote(id),
  [TYPE.systemAssetCat]: (id) => brm.systemAssetCategoryApi.getSystemAssetCategoryNote(id),
  [TYPE.systemAssetType]: (id) => brm.systemAssetTypeApi.getSystemAssetTypeNote(id),
  [TYPE.categorizedEntity]: (id) => brm.categorizedEntityApi.getCategorizedEntityNote(id),
  [TYPE.classificationCat]: (id) => brm.classificationCategoryApi.getClassificationCategoryNote(id),
  [TYPE.securityObjective]: (id) => brm.securityObjectiveApi.getSecurityObjectiveNote(id),
  [TYPE.accessPoint]: (id) => brm.accessPointApi.getAccessPointNote(id),
  [TYPE.accessPointCat]: (id) => brm.accessPointCatApi.getAccessPointCategoryNote(id),
  [TYPE.attackSurface]: (id) => brm.attackSurfaceApi.getAttackSurfaceNote(id),
  [TYPE.attackSurfaceCat]: (id) => brm.attackSurfaceCatApi.getAttackSurfaceCategory(id),
  [TYPE.attackVector]: (id) => brm.attackVectorApi.getAttackVectorNote(id),
  [TYPE.attackVectorCat]: (id) => brm.attackVectorCatApi.getAttackVectorCategoryNote(id),
  [TYPE.entryPoint]: (id) => brm.entryPointApi.getEntryPointNote(id),
  [TYPE.entryPointCat]: (id) => brm.entryPointCatApi.getEntryPointCategoryNote(id),
  [TYPE.asset]: (id) => brm.assetApi.getAssetNote(id),
  [TYPE.assetCat]: (id) => brm.assetCategoryApi.getAssetCategoryNote(id),
  [TYPE.attack]: (id) => brm.attackApi.getAttackNote(id),
  [TYPE.attackKind]: (id) => brm.attackKindApi.getAttackKindNote(id),
  [TYPE.attackMechanism]: (id) => getAttackMechanismNote(id),
  [TYPE.attackTactic]: (id) => brm.attackTacticApi.getAttackTacticNote(id),
  [TYPE.attackPath]: (id) => brm.attackPathApi.getAttackPathNote(id),
  [TYPE.ue]: (id) => brm.undesiredEventApi.getUndesiredEventNote(id),
  [TYPE.harmCat]: (id) => brm.harmCategoryApi.getHarmCategoryNote(id),
  [TYPE.risk]: (id) => brm.riskApi.getRiskNote(id),
  [TYPE.riskCat]: (id) => brm.riskCategoryApi.getRiskCategoryNote(id),
  [TYPE.trev]: (id) => brm.threatEventApi.getThreatEventNote(id),
  [TYPE.trevPat]: async (id) => {
    const trevPat = await brm.threatEventPatternApi.getThreatEventPatternByID(id);
    return { result: trevPat.note };
  },
  [TYPE.trevCat]: (id) => brm.threatEventCategoryApi.getThreatEventCategoryNote(id),
  [TYPE.vulnerability]: (id) => brm.vulnerabilityApi.getVulnerabilityNote(id),
  [TYPE.vulnerabilityCat]: (id) => brm.vulnerabilityCategoryApi.getVulnerabilityCategoryNote(id),
};

const setNotePromiseMap = {
  [TYPE.knowledgebase]: (id, opts) => brm.kbApi.setKBNote(id, opts),
  [TYPE.metadata]: (id, opts) => brm.metadataApi.setMetadataNote(id, opts),
  [TYPE.ruleset]: (id, opts) => brm.rulesetApi.setRulesetNote(id, opts),
  [TYPE.installation]: (id, opts) => brm.installationApi.setInstallationNote(id, opts),
  [TYPE.organization]: (id, opts) => brm.organizationApi.setOrganizationNote(id, opts),
  [TYPE.project]: (id, opts) => brm.projectApi.setProjectNote(id, opts),
  [TYPE.user]: (id, opts) => brm.userApi.setUserNote(id, opts),
  [TYPE.module]: (id, opts) => brm.moduleApi.setModuleNote(id, opts),
  [TYPE.system]: (id, opts) => brm.systemApi.setSystemNote(id, opts),
  [TYPE.variant]: (id, opts) => brm.variantApi.setVariantNote(id, opts),
  [TYPE.controlCatalog]: (id, opts) => brm.controlCatalogApi.setControlCatalogNote(id, opts),
  [TYPE.controlFamily]: (id, opts) => brm.controlFamilyApi.setControlFamilyNote(id, opts),
  [TYPE.controlType]: (id, opts) => brm.controlTypeApi.setControlTypeNote(id, opts),
  [TYPE.standardBaseline]: (id, opts) => brm.baselineApi.setStandardBaselineNote(id, opts),
  [TYPE.allocatedControl]: (id, opts) => brm.allocatedControlApi.setAllocatedControlNote(id, opts),
  [TYPE.sctm]: (id, opts) => brm.sctmApi.setSCTMNote(id, opts),
  [TYPE.attacker]: (id, opts) => brm.attackerApi.setAttackerNote(id, opts),
  [TYPE.attackerCat]: (id, opts) => brm.attackerCategoryApi.setAttackerCategoryNote(id, opts),
  [TYPE.threatCatalog]: (id, opts) => brm.threatCatalogApi.setThreatCatalogNote(id, opts),
  [TYPE.threatGroup]: (id, opts) => brm.threatGroupApi.setThreatGroupNote(id, opts),
  [TYPE.threatTier]: (id, opts) => brm.threatTierApi.setThreatTierNote(id, opts),
  [TYPE.node]: (id, opts) => brm.nodeApi.setNodeNote(id, opts),
  [TYPE.exchange]: (id, opts) => brm.exchangeApi.setExchangeNote(id, opts),
  [TYPE.mission]: (id, opts) => brm.missionApi.setMissionNote(id, opts),
  [TYPE.link]: (id, opts) => brm.linkApi.setLinkNote(id, opts),
  [TYPE.bus]: (id, opts) => brm.busApi.setBusNote(id, opts),
  [TYPE.activity]: (id, opts) => brm.activityApi.setActivityNote(id, opts),
  [TYPE.capability]: (id, opts) => brm.capabilityApi.setCapabilityNote(id, opts),
  [TYPE.person]: (id, opts) => brm.personApi.setPersonNote(id, opts),
  [TYPE.resource]: (id, opts) => brm.resourceApi.setResourceNote(id, opts),
  [TYPE.dataflow]: (id, opts) => brm.dataflowApi.setDataFlowNote(id, opts),
  [TYPE.datatype]: (id, opts) => brm.dataTypeApi.setDataTypeNote(id, opts),
  [TYPE.elementCat]: (id, opts) => brm.elementCategoryApi.setElementCategoryNote(id, opts),
  [TYPE.structuralCat]: (id, opts) => brm.structCatApi.setStructuralCategoryNote(id, opts),
  [TYPE.systemAsset]: (id, opts) => brm.systemAssetApi.setSystemAssetNote(id, opts),
  [TYPE.systemAssetCat]: (id, opts) => brm.systemAssetCategoryApi.setSystemAssetCategoryNote(id, opts),
  [TYPE.systemAssetType]: (id, opts) => brm.systemAssetTypeApi.setSystemAssetTypeNote(id, opts),
  [TYPE.categorizedEntity]: (id, opts) => brm.categorizedEntityApi.setCategorizedEntityNote(id, opts),
  [TYPE.classificationCat]: (id, opts) => brm.classificationCategoryApi.setClassificationCategoryNote(id, opts),
  [TYPE.securityObjective]: (id, opts) => brm.securityObjectiveApi.setSecurityObjectiveNote(id, opts),
  [TYPE.accessPoint]: (id, opts) => brm.accessPointApi.setAccessPointNote(id, opts),
  [TYPE.accessPointCat]: (id, opts) => brm.accessPointCatApi.setAccessPointCategoryNote(id, opts),
  [TYPE.attackSurface]: (id, opts) => brm.attackSurfaceApi.setAttackSurfaceNote(id, opts),
  [TYPE.attackSurfaceCat]: (id, opts) => brm.attackSurfaceCatApi.setAttackSurfaceCategory(id, opts),
  [TYPE.attackVector]: (id, opts) => brm.attackVectorApi.setAttackVectorNote(id, opts),
  [TYPE.attackVectorCat]: (id, opts) => brm.attackVectorCatApi.setAttackVectorCategoryNote(id, opts),
  [TYPE.entryPoint]: (id, opts) => brm.entryPointApi.setEntryPointNote(id, opts),
  [TYPE.entryPointCat]: (id, opts) => brm.entryPointCatApi.setEntryPointCategoryNote(id, opts),
  [TYPE.asset]: (id, opts) => brm.assetApi.setAssetNote(id, opts),
  [TYPE.assetCat]: (id, opts) => brm.assetCategoryApi.setAssetCategoryNote(id, opts),
  [TYPE.attack]: (id, opts) => brm.attackApi.setAttackNote(id, opts),
  [TYPE.attackKind]: (id, opts) => brm.attackKindApi.setAttackKindNote(id, opts),
  [TYPE.attackMechanism]: (id, opts) => brm.attackMechanismApi.setAttackMechanismNote(id, opts),
  [TYPE.attackTactic]: (id, opts) => brm.attackTacticApi.setAttackTacticNote(id, opts),
  [TYPE.attackPath]: (id, opts) => brm.attackPathApi.setAttackPathNote(id, opts),
  [TYPE.ue]: (id, opts) => brm.undesiredEventApi.setUndesiredEventNote(id, opts),
  [TYPE.harmCat]: (id, opts) => brm.harmCategoryApi.setHarmCategoryNote(id, opts),
  [TYPE.risk]: (id, opts) => brm.riskApi.setRiskNote(id, opts),
  [TYPE.riskCat]: (id, opts) => brm.riskCategoryApi.setRiskCategoryNote(id, opts),
  [TYPE.trev]: (id, opts) => brm.threatEventApi.setThreatEventNote(id, opts),
  [TYPE.trevPat]: (id, opts) => brm.threatEventPatternApi.setThreatEventPatternNote(id, opts),
  [TYPE.trevCat]: (id, opts) => brm.threatEventCategoryApi.setThreatEventCategoryNote(id, opts),
  [TYPE.vulnerability]: (id, opts) => brm.vulnerabilityApi.setVulnerabilityNote(id, opts),
  [TYPE.vulnerabilityCat]: (id, opts) => brm.vulnerabilityCategoryApi.setVulnerabilityCategoryNote(id, opts),
};

export const getNote = async (type, id) => {
  if (typeof getNotePromiseMap[type] === "function") {
    // HACK to hide toast when switching roles and no longer having access to note
    try {
      const value = (await getNotePromiseMap[type](id)) || "";
      return value;
    } catch (err) {
      return Promise.resolve("");
    }
  }
  return "";
};

export const setNote = async (type, id, text) => {
  const opts = { body: text };
  const noteFunc = setNotePromiseMap[type];
  if (noteFunc) {
    return setNotePromiseMap[type](id, opts);
  }
  return Promise.resolve("");
};

import * as React from "react";

import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";

import { COMMON, TYPE /* , COMMON */ } from "constants/brm";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import GenericModal from "components/modals/GenericModal";
import { ThreatApi } from "features/brm";

import {
  createColumnName,
  // createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

export const ThreatSoftwareTable = ({ setSelectedRows }: any) => {
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [editMode] = React.useState(false);
  const updatedName = React.useRef("");
  const { data, error } = ThreatApi.useThreatSoftware({ catalog: COMMON.defaultUuid });

  // const createButton = React.useCallback(() => {
  //   return COMMON.na;
  // }, []);

  const columns = React.useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "threatSoftwareId"),
      createColumnMappedNoEdit("descriptionThreat"),
      // createColumnMappedNoEdit("catalog"),
      // createColumnMappedNoEdit("typeThreat"),
      // createColumnMappedNoEdit("technique"),
      // createColumnMappedNoEdit("platform"),
      // createColumnMappedNoEdit("alias"),
      // createColumnMappedNoEdit("sourceId"),
      // createColumnMappedNoEdit("sourceUrl"),
      // createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, editMode]
  );

  const hideCheckBoxCondition = React.useCallback(() => {
    // if (selfData) {
    //   // hide if we are the builtin catalog && we aren't in administration org...
    //   return row.original.organization.id === COMMON.defaultUuid && selfData.organization !== COMMON.defaultUuid;
    // }
    return true;
  }, []);

  if (error) {
    return <ErrorBanner msg="Error while loading threat software" />;
  }

  if (data) {
    return (
      <>
        {alertOpen && (
          <GenericModal
            elementId="ThreatSoftwareTable_alertModal"
            modalIsOpen={alertOpen}
            setModalIsOpen={setAlertOpen}
            headerText="Message"
            bodyText="Please select a Offensive Tactic."
          />
        )}

        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ThreatSoftwareTable_table" }}
            elementName={TYPE.threatSoftware}
            hideCheckBoxCondition={hideCheckBoxCondition}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

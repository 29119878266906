import * as React from "react";
import * as Recoil from "recoil";
import {
  projectIdState,
  projectNameState,
  projectStatusState,
  isReadOnlyState,
  // userIdState,
  riskUpdatedState,
  variantIdState,
  sctmIdState,
} from "atoms";
import useSessionStorage from "hooks/useSessionStorage";
import { isFunction } from "lodash";
import * as log from "loglevel";
import { useIsProjectWizardOpen } from "features/brm/hooks/useIsProjectWizardOpen";
import { useRun } from "features/brm/hooks/useRun";
import { currentProjectState, currentVariantState, isCurrentProjectActiveState } from "../../stores";
import { ProjectState } from "../../types";
import { ProjectAccessLevel } from "../../constants";
import * as AdminApi from "../../api/admin";

export const useProject = () => {
  const [, { resetRunId }] = useRun();
  const { isProjectWizardOpen } = useIsProjectWizardOpen();

  /**
   * Current project state object
   */
  const [currentProject, setCurrentProject] = Recoil.useRecoilState(currentProjectState);
  const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);
  const [, setIsReadOnly] = Recoil.useRecoilState(isReadOnlyState);
  const resetSctmId = Recoil.useResetRecoilState(sctmIdState);
  const resetVariantId = Recoil.useResetRecoilState(variantIdState);
  const resetCurrentVariantState = Recoil.useResetRecoilState(currentVariantState);
  const setRiskUpdated = Recoil.useSetRecoilState(riskUpdatedState);

  /**
   * Deprecated project state management, keeping around to ensure compatibility
   */
  const [, setProjectId] = Recoil.useRecoilState(projectIdState);
  const [, setProjectStatus] = Recoil.useRecoilState(projectStatusState);
  const [, setProjectName] = Recoil.useRecoilState(projectNameState);

  /**
   * Session Persistence
   */
  const [, setPersistedCurrentProjectState] = useSessionStorage<any>("currentProjectState", undefined);
  const [, setPersistedProjectId] = useSessionStorage<any>("projectId", undefined);
  const [, setPersistedProjectNameState] = useSessionStorage<any>("projectNameState", undefined);
  const [, setPersistedIsReadOnlyState] = useSessionStorage<any>("isReadOnlyState", undefined);

  /**
   * Derived State
   */
  const projectId = currentProject?.id || "";
  const { data: projectAccessLevel } = AdminApi.useProjectAccess({ projectId, options: { enabled: !!currentProject } });

  React.useEffect(() => {
    if (projectAccessLevel === ProjectAccessLevel.ReadOnly) {
      setIsReadOnly(true);
      setPersistedIsReadOnlyState({ value: true });
    } else {
      setIsReadOnly(false);
      setPersistedIsReadOnlyState({ value: false });
    }
  }, [projectAccessLevel, setIsReadOnly, setPersistedIsReadOnlyState]);

  // React.useEffect(() => {
  //   setIsReadOnly(isProjectReadOnly);
  //   // Persist value in session
  //   setPersistedIsReadOnlyState({ value: isReadOnly });
  // }, [isProjectReadOnly, isReadOnly, setIsReadOnly, setPersistedIsReadOnlyState]);

  const handlers = React.useMemo(() => {
    const resetProject = () => {
      setCurrentProject(null);
      setProjectId("");
      setProjectName("");
      setProjectStatus(null);
      setPersistedProjectId({ value: "" });
      // Clear values from session
      setPersistedProjectNameState({ value: null });
      setPersistedIsReadOnlyState({ value: "" });
      setPersistedCurrentProjectState({ value: null });
      resetVariantId();
      resetSctmId();
      resetCurrentVariantState();
    };

    return {
      setCurrentProject: (
        valueOrUpdater: ProjectState | null | ((currVal: ProjectState | null) => ProjectState | null)
      ) => {
        const newProject = isFunction(valueOrUpdater) ? valueOrUpdater(currentProject) : valueOrUpdater;
        // console.log("calling currentProject", currentProject);
        // console.log("newProject", newProject);

        if (newProject === null || (projectAccessLevel === ProjectAccessLevel.None && !isProjectWizardOpen)) {
          resetProject();
        } else {
          if (currentProject && currentProject.id !== newProject.id) {
            resetVariantId();
            resetCurrentVariantState();
            resetSctmId();
          }
          log.debug("Project Status:", newProject.status);
          setCurrentProject(newProject);
          setProjectId(newProject.id);
          setProjectName(newProject.name);
          setProjectStatus(newProject.status as string);
          // Persist values in session
          setPersistedProjectId({ value: newProject.id });
          setPersistedProjectNameState({ value: newProject.name });
          setPersistedCurrentProjectState({ value: newProject });
        }

        // Common Actions for any project change.
        resetRunId();
        setRiskUpdated(true);
      },
      resetCurrentProject: () => {
        resetProject();
      },
      isActive: isCurrentProjectActive,
      projectId: currentProject?.id || "",
      getProjectAccessLevel: () => projectAccessLevel,
    };
  }, [
    currentProject,
    isCurrentProjectActive,
    isProjectWizardOpen,
    projectAccessLevel,
    resetCurrentVariantState,
    resetRunId,
    resetSctmId,
    resetVariantId,
    setCurrentProject,
    setPersistedCurrentProjectState,
    setPersistedIsReadOnlyState,
    setPersistedProjectId,
    setPersistedProjectNameState,
    setProjectId,
    setProjectName,
    setProjectStatus,
    setRiskUpdated,
  ]);

  return [currentProject, handlers] as const;
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IAffectedPlatformsProps {
  id: string;
}

export const getAffectedPlatform = ({ id }: IAffectedPlatformsProps) => {
  return Brm.affectedPlatformApi.getAffectedPlatformByID(id);
};

interface IUseAffectedPlatform {
  id: string;
  options?: QueryOption<typeof getAffectedPlatform>;
}

/**
 * React-query hook for getting affected platform
 *
 * @param {string} id -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useAffectedPlatform = ({ id, options }: IUseAffectedPlatform) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.offensiveTechniques.technique(id),
    queryFn: () => getAffectedPlatform({ id }),
  });
};

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/react-in-jsx-scope */
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import MainPage from "brm/layout/MainPage";
import { NotFound } from "components/error";
// import { AppContainer } from "components/layout/AppContainer";
// import { MainLayout } from "components/layout/MainLayout";
import { Logout } from "features/brm";
import { Profile } from "features/account";
import { Help, Demo } from "features/help";
import { RootLayout, BrmLayout, MainLayout as MainLayout2, AccountLayout, HelpLayout } from "features/layout";
import { DiagramRoutes } from "./DiagramRoutes";
import { RoutePath } from "./route-paths";

export const AppRoutes = () => {
  const location = useLocation();
  const state = location.state as any;

  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Navigate replace to="/brm" />} />
        <Route path="brm" element={<BrmLayout />}>
          <Route index element={<Navigate replace to="/brm/main" />} />
          <Route path="main" element={<MainLayout2 />}>
            <Route path={RoutePath.Diagrams} element={<DiagramRoutes />} />
            <Route path={RoutePath.Reports} element={<MainPage contentKey="reports" />} />
            <Route path={RoutePath.ThreatCatalogs} element={<MainPage contentKey="threatCatalogs" />} />
            <Route path={RoutePath.ThreatCatalog} element={<MainPage contentKey="threatCatalogsDetails" />} />
            <Route path={RoutePath.ThreatTiers} element={<MainPage contentKey="threatTiers" />} />
            <Route path={RoutePath.ThreatTier} element={<MainPage contentKey="threatTiersDetails" />} />
            <Route path={RoutePath.ThreatGroups} element={<MainPage contentKey="threatGroups" />} />
            <Route path={RoutePath.ThreatGroup} element={<MainPage contentKey="threatGroupDetails" />} />
            <Route path={RoutePath.ThreatCampaigns} element={<MainPage contentKey="threatCampaigns" />} />
            <Route path={RoutePath.ThreatCampaign} element={<MainPage contentKey="threatCampaign" />} />
            <Route path={RoutePath.ThreatSoftware} element={<MainPage contentKey="threatSoftware" />} />
            <Route path={RoutePath.ThreatSoftwareId} element={<MainPage contentKey="threatSoftwareId" />} />
            <Route path={RoutePath.ThreatDataSources} element={<MainPage contentKey="threatDataSources" />} />
            <Route path={RoutePath.ThreatDataSource} element={<MainPage contentKey="threatDataSource" />} />
            <Route path={RoutePath.OffensiveTactics} element={<MainPage contentKey="offensiveTactics" />} />
            <Route path={RoutePath.OffensiveTactic} element={<MainPage contentKey="offensiveTactic" />} />
            <Route path={RoutePath.OffensiveTechniques} element={<MainPage contentKey="offensiveTechniques" />} />
            <Route path={RoutePath.OffensiveTechnique} element={<MainPage contentKey="offensiveTechnique" />} />
            <Route path={RoutePath.AffectedPlatforms} element={<MainPage contentKey="affectedPlatforms" />} />
            <Route path={RoutePath.AffectedPlatform} element={<MainPage contentKey="affectedPlatform" />} />
            <Route path={RoutePath.TargetAssets} element={<MainPage contentKey="targetAssets" />} />
            <Route path={RoutePath.TargetAsset} element={<MainPage contentKey="targetAsset" />} />
            <Route path={RoutePath.DefendCatalogs} element={<MainPage contentKey="defendCatalogs" />} />
            <Route path={RoutePath.DefendCatalog} element={<MainPage contentKey="defendCatalog" />} />
            <Route path={RoutePath.DefensiveTactics} element={<MainPage contentKey="defensiveTactics" />} />
            <Route path={RoutePath.DefensiveTactic} element={<MainPage contentKey="defensiveTactic" />} />
            <Route path={RoutePath.DefensiveTechniques} element={<MainPage contentKey="defensiveTechniques" />} />
            <Route path={RoutePath.DefensiveTechnique} element={<MainPage contentKey="defensiveTechnique" />} />
            <Route path={RoutePath.DigitalArtifacts} element={<MainPage contentKey="digitalArtifacts" />} />
            <Route path={RoutePath.DigitalArtifact} element={<MainPage contentKey="digitalArtifact" />} />
            <Route path={RoutePath.CciControls} element={<MainPage contentKey="cciControls" />} />
            <Route path={RoutePath.CciControl} element={<MainPage contentKey="cciControl" />} />
            <Route path={RoutePath.NistControls} element={<MainPage contentKey="nistControls" />} />
            <Route path={RoutePath.NistControl} element={<MainPage contentKey="nistControl" />} />
            <Route path={RoutePath.AttackMechanisms} element={<MainPage contentKey="attackMechanisms" />} />
            <Route path={RoutePath.AttackMechanism} element={<MainPage contentKey="attackMechanismFullDetails" />} />
            <Route path={RoutePath.AttackTactics} element={<MainPage contentKey="attackTactics" />} />
            <Route path={RoutePath.AttackTactic} element={<MainPage contentKey="attackTacticFullDetails" />} />
            <Route path={RoutePath.AttackKinds} element={<MainPage contentKey="attackKinds" />} />
            <Route path={RoutePath.AttackKind} element={<MainPage contentKey="attackKindFullDetails" />} />
            <Route path={RoutePath.Attacks} element={<MainPage contentKey="attacks" />} />
            <Route path={RoutePath.Attack} element={<MainPage contentKey="attackFullDetails" />} />
            <Route path={RoutePath.AttackerCategories} element={<MainPage contentKey="attackerCategories" />} />
            <Route path={RoutePath.AttackerCategory} element={<MainPage contentKey="attackerCategoriesDetails" />} />
            <Route path={RoutePath.Attackers} element={<MainPage contentKey="attackers" />} />
            <Route path={RoutePath.Attacker} element={<MainPage contentKey="attackersDetails" />} />
            <Route path={RoutePath.StructuralCategories} element={<MainPage contentKey="structuralCategories" />} />
            <Route
              path={RoutePath.StructuralCategory}
              element={<MainPage contentKey="structuralCategoryFullDetails" />}
            />
            <Route path={RoutePath.ElementCategories} element={<MainPage contentKey="elementCategories" />} />
            <Route path={RoutePath.StructuralCategory} element={<MainPage contentKey="elementCategoryFullDetails" />} />
            <Route path={RoutePath.Nodes} element={<MainPage contentKey="nodes" />} />
            <Route path={RoutePath.Node} element={<MainPage contentKey="nodeFullDetails" />} />
            <Route path={RoutePath.Links} element={<MainPage contentKey="links" />} />
            <Route path={RoutePath.Link} element={<MainPage contentKey="linkFullDetails" />} />
            <Route path={RoutePath.Groups} element={<MainPage contentKey="groups" />} />
            <Route path={RoutePath.Channels} element={<MainPage contentKey="channels" />} />
            <Route path={RoutePath.Exchanges} element={<MainPage contentKey="exchanges" />} />
            <Route path={RoutePath.Exchange} element={<MainPage contentKey="exchangeFullDetails" />} />
            <Route path={RoutePath.Datatypes} element={<MainPage contentKey="datatypes" />} />
            <Route path={RoutePath.Datatype} element={<MainPage contentKey="datatypeFullDetails" />} />
            <Route path={RoutePath.Persons} element={<MainPage contentKey="persons" />} />
            <Route path={RoutePath.Person} element={<MainPage contentKey="personFullDetails" />} />
            <Route path={RoutePath.Dataflows} element={<MainPage contentKey="dataflows" />} />
            <Route path={RoutePath.Dataflow} element={<MainPage contentKey="dataflowFullDetails" />} />
            <Route path={RoutePath.Capabilities} element={<MainPage contentKey="capabilities" />} />
            <Route path={RoutePath.Capability} element={<MainPage contentKey="capabilityFullDetails" />} />
            <Route path={RoutePath.Activities} element={<MainPage contentKey="activities" />} />
            <Route path={RoutePath.Activity} element={<MainPage contentKey="activityFullDetails" />} />
            <Route path={RoutePath.Missions} element={<MainPage contentKey="missions" />} />
            <Route path={RoutePath.Mission} element={<MainPage contentKey="missionFullDetails" />} />
            <Route path={RoutePath.Resources} element={<MainPage contentKey="resources" />} />
            <Route path={RoutePath.Resource} element={<MainPage contentKey="resourceFullDetails" />} />
            <Route path={RoutePath.EntryPoints} element={<MainPage contentKey="entryPoints" />} />
            <Route path={RoutePath.EntryPoint} element={<MainPage contentKey="entryPointsDetails" />} />
            <Route path={RoutePath.EntryPointCategories} element={<MainPage contentKey="entryPointCategories" />} />
            <Route
              path={RoutePath.EntryPointCategory}
              element={<MainPage contentKey="entryPointCategoriesDetails" />}
            />
            <Route path={RoutePath.AccessPointCategories} element={<MainPage contentKey="accessPointCategories" />} />
            <Route
              path={RoutePath.AccessPointCategory}
              element={<MainPage contentKey="accessPointCategoriesDetails" />}
            />
            <Route path={RoutePath.AccessPoints} element={<MainPage contentKey="accessPoints" />} />
            <Route path={RoutePath.AccessPoint} element={<MainPage contentKey="accessPointsDetails" />} />
            <Route path={RoutePath.AttackSurfaces} element={<MainPage contentKey="attackSurfaces" />} />
            <Route path={RoutePath.AttackSurface} element={<MainPage contentKey="attackSurfacesDetails" />} />
            <Route
              path={RoutePath.AttackSurfaceCategories}
              element={<MainPage contentKey="attackSurfaceCategories" />}
            />
            <Route
              path={RoutePath.AttackSurfaceCategory}
              element={<MainPage contentKey="attackSurfaceCategoriesDetails" />}
            />
            <Route path={RoutePath.AttackVectorCategories} element={<MainPage contentKey="attackVectorCategories" />} />
            <Route
              path={RoutePath.AttackVectorCategory}
              element={<MainPage contentKey="attackVectorCategoriesDetails" />}
            />
            <Route path={RoutePath.AttackVectors} element={<MainPage contentKey="attackVectors" />} />
            <Route path={RoutePath.AttackVector} element={<MainPage contentKey="attackVectorsDetails" />} />
            <Route path={RoutePath.Boundaries} element={<MainPage contentKey="boundaries" />} />
            <Route path={RoutePath.BoundariesActivities} element={<MainPage contentKey="boundariesActivities" />} />

            <Route path={RoutePath.ClassificationCategories} element={<MainPage contentKey="classifiedCategories" />} />
            <Route
              path={RoutePath.ClassificationCategory}
              element={<MainPage contentKey="classifiedCategoriesOverview" />}
            />
            <Route path={RoutePath.SecurityObjectives} element={<MainPage contentKey="securityObjectives" />} />
            <Route path={RoutePath.SecurityObjective} element={<MainPage contentKey="securityObjectivesOverview" />} />
            <Route path={RoutePath.CategorizedEntities} element={<MainPage contentKey="categorizedEntities" />} />
            <Route path={RoutePath.CategorizedEntity} element={<MainPage contentKey="categorizedEntitiesOverview" />} />

            <Route path={RoutePath.SystemAssets} element={<MainPage contentKey="systemAssets" />} />
            <Route path={RoutePath.SystemAsset} element={<MainPage contentKey="systemAssetFullDetails" />} />
            <Route path={RoutePath.SystemAssetCategories} element={<MainPage contentKey="systemAssetCategories" />} />
            <Route
              path={RoutePath.SystemAssetCategory}
              element={<MainPage contentKey="systemAssetCategoryFullDetails" />}
            />
            <Route path={RoutePath.SystemAssetTypes} element={<MainPage contentKey="systemAssetTypes" />} />
            <Route path={RoutePath.SystemAssetType} element={<MainPage contentKey="systemAssetTypeFullDetails" />} />

            <Route path={RoutePath.ThreatEventPatterns} element={<MainPage contentKey="threatEventPatterns" />} />
            <Route
              path={RoutePath.ThreatEventPattern}
              element={<MainPage contentKey="threatEventPatternFullDetails" />}
            />
            <Route path={RoutePath.ThreatEventCategories} element={<MainPage contentKey="threatEventCategories" />} />
            <Route
              path={RoutePath.ThreatEventCategory}
              element={<MainPage contentKey="threatEventCategoryFullDetails" />}
            />
            <Route path={RoutePath.ThreatEvents} element={<MainPage contentKey="threatEvents" />} />
            <Route path={RoutePath.ThreatEvent} element={<MainPage contentKey="threatEventFullDetails" />} />
            <Route path={RoutePath.RiskCategories} element={<MainPage contentKey="riskCategories" />} />
            <Route path={RoutePath.RiskCategory} element={<MainPage contentKey="riskCategoryFullDetails" />} />
            <Route path={RoutePath.Risks} element={<MainPage contentKey="risks" />} />
            <Route path={RoutePath.Risk} element={<MainPage contentKey="riskFullDetails" />} />
            <Route path={RoutePath.Harms} element={<MainPage contentKey="harms" />} />
            <Route path={RoutePath.Harm} element={<MainPage contentKey="harmCategoryFullDetails" />} />
            <Route path={RoutePath.UndesiredEvents} element={<MainPage contentKey="undesiredEvents" />} />
            <Route path={RoutePath.UndesiredEvent} element={<MainPage contentKey="undesiredEventFullDetails" />} />
            <Route path={RoutePath.AssetCategories} element={<MainPage contentKey="assetCategories" />} />
            <Route path={RoutePath.AssetCategory} element={<MainPage contentKey="assetCategoryFullDetails" />} />
            <Route path={RoutePath.Assets} element={<MainPage contentKey="assets" />} />
            <Route path={RoutePath.Asset} element={<MainPage contentKey="assetFullDetails" />} />

            <Route path={RoutePath.Vulnerabilities} element={<MainPage contentKey="vulnerabilities" />} />
            <Route path={RoutePath.Vulnerability} element={<MainPage contentKey="vulnerabilityFullDetails" />} />
            <Route
              path={RoutePath.VulnerabilityCategories}
              element={<MainPage contentKey="vulnerabilityCategories" />}
            />
            <Route
              path={RoutePath.VulnerabilityCategory}
              element={<MainPage contentKey="vulnerabilityCategoryFullDetails" />}
            />
            <Route
              path={RoutePath.SoftwareVulnerabilities}
              element={<MainPage contentKey="softwareVulnerabilities" />}
            />
            <Route path={RoutePath.CveFindings} element={<MainPage contentKey="cveFindings" />} />

            <Route path={RoutePath.Sctm} element={<MainPage contentKey="sctmsDetails" />} />
            <Route path={RoutePath.AllocatedControls} element={<MainPage contentKey="allocatedControls" />} />
            <Route path={RoutePath.AllocatedControl} element={<MainPage contentKey="allocatedControlsDetails" />} />
            <Route path={RoutePath.ControlCatalogs} element={<MainPage contentKey="controlCatalogs" />} />
            <Route path={RoutePath.ControlCatalog} element={<MainPage contentKey="controlCatalogsDetails" />} />
            <Route path={RoutePath.ControlFamilies} element={<MainPage contentKey="controlFamily" />} />
            <Route path={RoutePath.ControlFamily} element={<MainPage contentKey="controlFamilyFullDetails" />} />
            <Route path={RoutePath.ControlTypes} element={<MainPage contentKey="controlType" />} />
            <Route path={RoutePath.ControlType} element={<MainPage contentKey="controlTypeFullDetails" />} />
            <Route path={RoutePath.StandardBaselines} element={<MainPage contentKey="standardBaselines" />} />
            <Route path={RoutePath.StandardBaseline} element={<MainPage contentKey="standardBaselineFullDetails" />} />
            <Route path={RoutePath.ProjectCatalogConfig} element={<MainPage contentKey="projectCatalogConfig" />} />

            <Route path={RoutePath.Knowledgebases} element={<MainPage contentKey="knowledgebases" />} />
            <Route path={RoutePath.KbOrganizations} element={<MainPage contentKey="kbOrganizations" />} />
            <Route path={RoutePath.Knowledgebase} element={<MainPage contentKey="knowledgebasesDetails" />} />
            <Route path={RoutePath.KbProjects} element={<MainPage contentKey="kbProjects" />} />

            <Route path={RoutePath.Metadata} element={<MainPage contentKey="metadata" />} />
            <Route path={RoutePath.Modules} element={<MainPage contentKey="modules" />} />
            <Route path={RoutePath.Module} element={<MainPage contentKey="modulesDetails" />} />
            <Route path={RoutePath.Systems} element={<MainPage contentKey="systems" />} />
            <Route path={RoutePath.System} element={<MainPage contentKey="systemsDetails" />} />
            <Route path={RoutePath.Variants} element={<MainPage contentKey="variants" />} />
            <Route path={RoutePath.Variant} element={<MainPage contentKey="variantsDetails" />} />

            <Route path={RoutePath.Projects} element={<MainPage contentKey="projects" />} />
            <Route path={RoutePath.Project} element={<MainPage contentKey="projectOverview" />} />

            <Route path={RoutePath.ImpactCalibration} element={<MainPage contentKey="impactCalibration" />} />
            <Route path={RoutePath.LikelihoodCalibration} element={<MainPage contentKey="likelihoodCalibration" />} />

            <Route path={RoutePath.Organizations} element={<MainPage contentKey="organizations" />} />
            <Route path={RoutePath.Organization} element={<MainPage contentKey="organizationOverview" />} />
            <Route path={RoutePath.Users} element={<MainPage contentKey="users" />} />
            <Route path={RoutePath.User} element={<MainPage contentKey="userOverview" />} />
            <Route path={RoutePath.Installations} element={<MainPage contentKey="installation" />} />
            <Route path={RoutePath.Installation} element={<MainPage contentKey="installationOverview" />} />
            <Route path={RoutePath.AddUser} element={<MainPage contentKey="addUsers" />} />
            <Route path={RoutePath.ProjectImport} element={<MainPage contentKey="projectOverviewImporter" />} />
            <Route path={RoutePath.Home} element={<MainPage contentKey="home" />} />
          </Route>
          <Route path="helpcenter" element={<HelpLayout isHelpCenter />}>
            <Route path={RoutePath.Help} element={<Help />} />
          </Route>
          <Route path="helpcenter" element={<HelpLayout isHelpCenter={false} />}>
            <Route path={RoutePath.Demo} element={<Demo />} />
          </Route>
          <Route path="account" element={<AccountLayout />}>
            <Route path="profile">
              <Route path={RoutePath.UserProfile} element={<Profile />} />
            </Route>
          </Route>
        </Route>
        <Route path={RoutePath.LicenseError} element={<MainPage contentKey="licenseError" />} />
        <Route path={RoutePath.Logout} element={<Logout previousLoc={state?.previousPath || undefined} />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface ITargetAssetsProps {
  id: string;
}

export const getTargetAsset = ({ id }: ITargetAssetsProps) => {
  return Brm.targetAssetApi.getTargetAssetByID(id);
};

interface IUseTargetAsset {
  id: string;
  options?: QueryOption<typeof getTargetAsset>;
}

/**
 * React-query hook for getting target asset
 *
 * @param {string} id -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useTargetAsset = ({ id, options }: IUseTargetAsset) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.targetAssets.target(id),
    queryFn: () => getTargetAsset({ id }),
  });
};

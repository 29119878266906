import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IThreatCampaignProps {
  catalog?: string;
}
export const getThreatCampaigns = ({ catalog }: IThreatCampaignProps) => {
  return Brm.threatCampaignApi.findThreatCampaign({ catalog });
};

interface IUseThreatCampaign {
  catalog?: string;
  options?: QueryOption<typeof getThreatCampaigns>;
}

/**
 * React-query hook for getting threat software
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatCampaigns = ({ catalog, options }: IUseThreatCampaign = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatCampaign.catalog(catalog),
    queryFn: () => getThreatCampaigns({ catalog }),
  });
};

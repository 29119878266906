import Title from "components/elements/StyledTitle";
import { DefenseApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { DefensiveTechniqueTabs } from "features/mitre-d3fend/components/tabs/DefensiveTechniqueTabs";

export const DefensiveTechnique = () => {
  const { id } = useParams();

  const {
    data: defensiveTechnique,
    isError: isDefensiveTechniqueError,
    error: defensiveTechniqueError,
  } = DefenseApi.useDefensiveTechnique({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isDefensiveTechniqueError) {
    return <ErrorBanner msg={(defensiveTechniqueError as any)?.statusText} />;
  }

  if (defensiveTechnique) {
    return (
      <>
        <Title>Defensive Technique: {defensiveTechnique.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{defensiveTechnique.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{defensiveTechnique.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{defensiveTechnique.sourceId}</div>
          <S.PropertyLabel>Parent</S.PropertyLabel>
          <div>{defensiveTechnique.parentname}</div>
          {/* <S.PropertyLabel>Platform</S.PropertyLabel>
          <div>{defensiveTechnique.platform}</div> */}
          <S.PropertyLabel>Is Subtechnique</S.PropertyLabel>
          <div>{defensiveTechnique.isSubtechnique ? "True" : "False"}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <DefensiveTechniqueTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};

import {
  ThreatCatalogApi,
  ThreatGroupApi,
  ThreatTierApi,
  GtMetaApi,
  AffectedPlatformApi,
  OffensiveTacticApi,
  OffensiveTechniqueApi,
  TargetAssetApi,
  ThreatCampaignApi,
  ThreatSoftwareApi,
  ThreatDataSourceApi,
} from "@kdmanalytics/brm-knowledgebase";
import BrmApiClient from "services/brm/BrmApiClient";

export const threatCatalogApi = new ThreatCatalogApi(BrmApiClient.instance);
export const threatGroupApi = new ThreatGroupApi(BrmApiClient.instance);
export const threatTierApi = new ThreatTierApi(BrmApiClient.instance);
export const gtMetaApi = new GtMetaApi(BrmApiClient.instance);
export const affectedPlatformApi = new AffectedPlatformApi(BrmApiClient.instance);
export const offensiveTacticApi = new OffensiveTacticApi(BrmApiClient.instance);
export const offensiveTechniqueApi = new OffensiveTechniqueApi(BrmApiClient.instance);
export const targetAssetApi = new TargetAssetApi(BrmApiClient.instance);
export const threatCampaignApi = new ThreatCampaignApi(BrmApiClient.instance);
export const threatSoftwareApi = new ThreatSoftwareApi(BrmApiClient.instance);
export const threatDataSourceApi = new ThreatDataSourceApi(BrmApiClient.instance);

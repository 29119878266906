import * as React from "react";
import * as Recoil from "recoil";
import { runIdState, runIdBorState, runIdSbomState } from "atoms/atoms-importer";
import { ImportContext } from "features/brm/constants";

export const useRun = () => {
  const [runIdDefault, setRunIdDefault] = Recoil.useRecoilState(runIdState);
  const [runIdBor, setRunIdBor] = Recoil.useRecoilState(runIdBorState);
  const [runIdSbom, setRunIdSbom] = Recoil.useRecoilState(runIdSbomState);
  const runId = { runIdDefault, runIdBor, runIdSbom };

  const resetRunIdDefault = Recoil.useResetRecoilState(runIdState);
  const resetRunIdBor = Recoil.useResetRecoilState(runIdBorState);
  const resetRunIdSbom = Recoil.useResetRecoilState(runIdSbomState);

  const handlers = React.useMemo(() => {
    const resetRunId = () => {
      resetRunIdDefault();
      resetRunIdBor();
      resetRunIdSbom();
    };
    const getRunIdByContext = (context: string) => {
      let runIdCurrent;
      switch (context) {
        case ImportContext.bor:
          runIdCurrent = runIdBor;
          break;
        case ImportContext.sbom:
          runIdCurrent = runIdSbom;
          break;
        default:
          runIdCurrent = runIdDefault;
      }
      return runIdCurrent;
    };
    return {
      resetRunId,
      getRunIdByContext,
      setRunIdDefault,
      setRunIdBor,
      setRunIdSbom,
    };
  }, [
    resetRunIdBor,
    resetRunIdDefault,
    resetRunIdSbom,
    runIdBor,
    runIdDefault,
    runIdSbom,
    setRunIdBor,
    setRunIdDefault,
    setRunIdSbom,
  ]);

  return [runId, handlers] as const;
};

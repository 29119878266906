import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IOffensiveTechniquesProps {
  catalog?: string;
}
export const getOffensiveTechniques = ({ catalog }: IOffensiveTechniquesProps) => {
  return Brm.offensiveTechniqueApi.findOffensiveTechnique({ catalog });
};

interface IUseOffensiveTechniques {
  catalog?: string;
  options?: QueryOption<typeof getOffensiveTechniques>;
}

/**
 * React-query hook for getting offensive techniques
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useOffensiveTechniques = ({ catalog, options }: IUseOffensiveTechniques = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.offensiveTechniques.catalog(catalog),
    queryFn: () => getOffensiveTechniques({ catalog }),
  });
};

const baseKey = "element";

export const elementKeys = {
  all: [baseKey] as const,
  allActivities: [baseKey, "activities"] as const,
  activities: (elementId: string, elementType: string, dataTypeData: any, missionData: any) =>
    [...elementKeys.allActivities, elementId, elementType, dataTypeData, missionData] as const,
  allVulnerabilities: [baseKey, "vulnerabilities"],
  vulnerabilities: (
    elementId: string,
    elementType: string,
    projectId: string,
    variantId: string,
    riskUpdated: boolean,
    riskData: any,
    allocatedControlData: any,
    attackData: any,
    attackerData: any,
    assetData: any,
    ueData: any,
    trevData: any,
    sysAssetData: any,
    sysAssetTypeData: any,
    targetData: any,
    exchangeData: any,
    nodeData: any
  ) =>
    [
      ...elementKeys.allVulnerabilities,
      elementId,
      elementType,
      projectId,
      variantId,
      riskUpdated,
      riskData,
      allocatedControlData,
      attackData,
      attackerData,
      assetData,
      ueData,
      trevData,
      sysAssetData,
      sysAssetTypeData,
      targetData,
      exchangeData,
      nodeData,
    ] as const,
  allCapabilities: [baseKey, "capabilities"] as const,
  capabilities: (
    elementId: string,
    elementType: string,
    exchangeData: any,
    dataTypeData: any,
    personData: any,
    resourceData: any
  ) => [...elementKeys.allCapabilities, elementId, elementType, exchangeData, dataTypeData, personData, resourceData],
  allCharacteristic: [baseKey, "characteristic"] as const,
  characteristic: (elementId: string, elementType: string) => [
    ...elementKeys.allCharacteristic,
    elementId,
    elementType,
  ],
  allDataflows: [baseKey, "dataflows"],
  dataflows: (
    elementId: string,
    elementType: string,
    nodesData: any,
    exchangeData: any,
    dataTypeData: any,
    activityData: any
  ) => [
    ...elementKeys.allDataflows,
    elementId,
    elementType,
    nodesData,
    exchangeData,
    dataTypeData,
    nodesData,
    activityData,
  ],
  allDataTypes: [baseKey, "datatypes"],
  dataTypes: (elementId: string, elementType: string, dataflowData: any, missionData: any) => [
    ...elementKeys.allDataTypes,
    elementId,
    elementType,
    dataflowData,
    missionData,
  ],
  allExchanges: [baseKey, "exchanges"],
  exchanges: (elementId: string, elementType: string, datatypeData: any, missionData: any) => [
    ...elementKeys.allDataTypes,
    elementId,
    elementType,
    datatypeData,
    missionData,
  ],
  allPersons: [baseKey, "persons"],
  persons: (elementId: string, elementType: string, resourceData: any, missionData: any, dataTypeData: any) => [
    ...elementKeys.allDataTypes,
    elementId,
    elementType,
    resourceData,
    missionData,
    dataTypeData,
  ],
  allResources: [baseKey, "resources"],
  resources: (
    elementId: string,
    elementType: string,
    nodesData: any,
    personData: any,
    capabiltityData: any,
    missionData: any
  ) => [...elementKeys.allDataTypes, elementId, elementType, nodesData, personData, capabiltityData, missionData],
  allFindings: [baseKey, "findings"],
  findings: (elementId: string, elementType: string, projectId: string, variantId: string) => [
    ...elementKeys.allFindings,
    elementId,
    elementType,
    projectId,
    variantId,
  ],
  allCveFindings: [baseKey, "cveFindings"],
  cveFindings: (elementId: string, elementType: string, projectId: string) => [
    ...elementKeys.allFindings,
    elementId,
    elementType,
    projectId,
  ],
  allInData: [baseKey, "inData"],
  inData: (elementId: string, elementType: string, nodeInData: any) => [
    ...elementKeys.allInData,
    elementId,
    elementType,
    nodeInData,
  ],
  allOutData: [baseKey, "outData"],
  outData: (elementId: string, elementType: string, nodeOutData: any) => [
    ...elementKeys.allInData,
    elementId,
    elementType,
    nodeOutData,
  ],
};

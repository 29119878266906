export const mitigationKeys = {
  all: ["mitigation"] as const,
};

export const sctmKeys = {
  all: ["sctm"] as const,
  baseline: (sctmId: string) => [...sctmKeys.all, sctmId, "baseline"] as const,
  allocatedControls: (sctmId: string) => [...sctmKeys.all, sctmId, "allocatedControls"] as const,
  name: (sctmId: string) => [...sctmKeys.all, sctmId, "name"] as const,
  sctmFullDetails: (sctmId: string | null) => [...sctmKeys.all, sctmId, "sctmFullDetails"] as const,
};

export const baselineKeys = {
  all: ["baseline"] as const,
  controlsTypes: (baselineId: string) => [...baselineKeys.all, baselineId, "controlTypes"] as const,
  name: (baselineId: string) => [...baselineKeys.all, baselineId, "name"] as const,
};

export const variantKeys = {
  all: ["variants"] as const,
  project: (projectId: string) => [...variantKeys.all, projectId] as const,
};

export const allocatedControls = {
  all: ["allocatedControls"] as const,
  sctm: (sctmId: string) => [...allocatedControls.all, sctmId] as const,
  controls_deprecated: (sctmId: string) => ["getAllocatedControls", sctmId] as const, // deprecated do no use.
};

export const allocatedControlsGqlKey = {
  all: ["getSCTMAllocatedControls"] as const,
  sctm: (sctmId: string) => [...allocatedControlsGqlKey.all, sctmId] as const,
};

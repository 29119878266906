import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IOffensiveTacticsProps {
  catalog?: string;
}
export const getOffensiveTactics = ({ catalog }: IOffensiveTacticsProps) => {
  return Brm.offensiveTacticApi.findOffensiveTactic({ catalog });
};

interface IUseOffensiveTactics {
  catalog?: string;
  options?: QueryOption<typeof getOffensiveTactics>;
}

/**
 * React-query hook for getting offensive tactics
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useOffensiveTactics = ({ catalog, options }: IUseOffensiveTactics = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.offensiveTacticKeys.catalog(catalog),
    queryFn: () => getOffensiveTactics({ catalog }),
  });
};

import * as ReactQuery from "@tanstack/react-query";
// import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { getAffectedPlatform } from "features/brm/api/threat/globalThreat/getAffectedPlatform";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IUseAffectedPlatformsByIds {
  platforms: string[];
  options?: QueryOption<typeof getAffectedPlatform>;
}

/**
 * React-query hook for getting offensive techniques
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useAffectedPlatformsByIds = ({ platforms, options }: IUseAffectedPlatformsByIds) => {
  return ReactQuery.useQueries({
    queries: platforms.map((id: string) => ({
      queryKey: KeyFactory.affectedPlatformKeys.platform(id),
      queryFn: () => getAffectedPlatform({ id }),
      staleTime: Infinity,
      ...options,
    })),
  });
};

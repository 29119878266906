import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Constants
import { COMMON, STATUS } from "constants/brm";

// State
import { useRecoilValue } from "recoil";
import { projectNameState, userRoleState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";

// Assets
import { ReactComponent as DocumentReadWriteIcon } from "assets/icons/studio91/sidebars-toolbars/document-read-write_grey.svg";
// Components
import { RoutePath } from "routes/route-paths";
import { currentProjectState, useStatusChevron } from "features/brm";
import { isViewableByRiskAnalyst } from "utils/filter-utils";
import MenuItem from "./MenuItem";

// Styles
import S from "./ProjectExplorer.styles";

// Hooks

import { HighlightElementProvider } from "./HighlightElementContext";

import {
  CategorizationMenu,
  ConfigurationMenu,
  LocalThreatMenu,
  GlobalThreatMenu,
  MitreD3fendMenu,
  SystemAssetsMenu,
  SystemFactsMenu,
  AccessMenu,
  AssetMenu,
  ImpactMenu,
  RiskMenu,
  ThreatMenu,
  AttackMenu,
  VulnerabilityMenu,
  MitigationMenu,
  GlobalControlMenu,
  KnowledgebaseMenu,
} from "./NavMenu";
import AccordionLevelTwo from "./AccordionLevelTwo";
import Accordion from "./Accordion";
import "./Accordion.css";

const Menu = () => {
  const userRole = useRecoilValue(userRoleState);
  const projectName = useRecoilValue(projectNameState);
  const variantId = useRecoilValue(variantIdState);
  const currentProject = useRecoilValue(currentProjectState);

  const navigate = useNavigate();

  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const [activeThreat, setActiveThreat] = useState("");
  const [heightThreat, setHeightThreat] = useState("0px");
  const [activeRisk, setActiveRisk] = useState("");
  const [heightRisk, setHeightRisk] = useState("0px");
  const [activeGlobal, setActiveGlobal] = useState("");
  const [heightGlobal, setHeightGlobal] = useState("0px");

  const systemModelFolderIcon = useStatusChevron(active);
  const threatModelFolderIcon = useStatusChevron(activeThreat);
  const riskModelFolderIcon = useStatusChevron(activeRisk);
  const globalFolderIcon = useStatusChevron(activeGlobal);

  const browserHeight = navigator.userAgent.indexOf("Firefox") !== -1 ? "-moz-fit-content" : "fit-content";

  const toggleAccordion = () => {
    setActive(active === "" ? STATUS.active : "");
    setHeight(active === STATUS.active ? "0px" : browserHeight);
  };

  const toggleAccordionThreat = () => {
    setActiveThreat(activeThreat === "" ? STATUS.active : "");
    setHeightThreat(activeThreat === STATUS.active ? "0px" : browserHeight);
  };

  const toggleAccordionGlobal = () => {
    setActiveGlobal(activeGlobal === "" ? STATUS.active : "");
    setHeightGlobal(activeGlobal === STATUS.active ? "0px" : browserHeight);
  };

  const toggleAccordionRisk = () => {
    setActiveRisk(activeRisk === "" ? STATUS.active : "");
    setHeightRisk(activeRisk === STATUS.active ? "0px" : browserHeight);
  };

  function showThreatAnalyst() {
    if (projectName) {
      return (
        <>
          <AccordionLevelTwo title="Configuration" content2={[ConfigurationMenu.Metadata, ConfigurationMenu.System]} />
          <div className="accordion__section">
            <S.Item className={`accordion ${active}`} onClick={toggleAccordion}>
              {systemModelFolderIcon}
              <S.Element id="threatAnalyst_SystemModel">System Model</S.Element>
            </S.Item>

            <div style={{ maxHeight: `${height}` }} className="accordion__content">
              <div className="accordion__text">
                <Accordion
                  title="Access"
                  content2={[
                    AccessMenu.AccessPoint,
                    AccessMenu.AccessPointCategory,
                    AccessMenu.ActivityBoundary,
                    AccessMenu.AttackSurface,
                    AccessMenu.AttackSurfaceCategory,
                    AccessMenu.AttackVectors,
                    AccessMenu.AttackVectorCategory,
                    AccessMenu.EntryPoints,
                    AccessMenu.EntryPointCategory,
                    AccessMenu.NodeBoundary,
                  ]}
                />
                <Accordion
                  title="System Asset"
                  content2={[
                    SystemAssetsMenu.SystemAsset,
                    SystemAssetsMenu.SystemAssetCategory,
                    SystemAssetsMenu.SystemAssetType,
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="accordion__section">
            <S.Item className={`accordion ${activeThreat}`} onClick={toggleAccordionThreat}>
              {threatModelFolderIcon}
              <S.Element id="threatAnalyst_ThreatModel">Threat Model</S.Element>
            </S.Item>

            <div style={{ maxHeight: `${heightThreat}` }} className="accordion__content">
              <div className="accordion__text">
                <Accordion title="Threats" content2={[LocalThreatMenu.Attackers, LocalThreatMenu.AttackerCategories]} />
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }

  function showSystemEngineer() {
    if (currentProject) {
      return (
        projectName && (
          <>
            <AccordionLevelTwo
              title="Configuration"
              content2={[ConfigurationMenu.Metadata, ConfigurationMenu.System]}
            />
            <div className="accordion__section">
              <S.Item id="systemsEngineer_systemModel" className={`accordion ${active}`} onClick={toggleAccordion}>
                {systemModelFolderIcon}
                System Model
              </S.Item>

              <div style={{ maxHeight: `${height}` }} className="accordion__content">
                <div className="accordion__text">
                  <Accordion
                    title="Access"
                    content2={[
                      AccessMenu.AccessPoint,
                      AccessMenu.AccessPointCategory,
                      AccessMenu.ActivityBoundary,
                      AccessMenu.AttackSurface,
                      AccessMenu.AttackSurfaceCategory,
                      AccessMenu.AttackVectors,
                      AccessMenu.AttackVectorCategory,
                      AccessMenu.EntryPoints,
                      AccessMenu.EntryPointCategory,
                      AccessMenu.NodeBoundary,
                    ]}
                  />
                  <Accordion
                    title="Categorization"
                    content2={[
                      CategorizationMenu.categorizedEntity,
                      CategorizationMenu.classificationCategory,
                      CategorizationMenu.securityObjective,
                    ]}
                  />
                  <Accordion
                    title="System Asset"
                    content2={[
                      SystemAssetsMenu.SystemAsset,
                      SystemAssetsMenu.SystemAssetCategory,
                      SystemAssetsMenu.SystemAssetType,
                    ]}
                  />
                  <Accordion
                    title="System Facts"
                    content2={[
                      SystemFactsMenu.Nodes,
                      // Commenting out all Links related data for 1st sprint
                      // SystemFactsMenu.Links,
                      // Commenting out all Bus related data for 1st sprint
                      /* SystemFactsMenu.Buses, */
                      SystemFactsMenu.Exchanges,
                      SystemFactsMenu.Datatypes,
                      SystemFactsMenu.Activities,
                      SystemFactsMenu.Dataflows,
                      SystemFactsMenu.Persons,
                      SystemFactsMenu.Resources,
                      SystemFactsMenu.Capabilities,
                      SystemFactsMenu.Missions,
                      SystemFactsMenu.ElementCategory,
                      SystemFactsMenu.StructuralCategory,
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        )
      );
    }
    return <></>;
  }

  function showRiskAnalyst() {
    if (currentProject && isViewableByRiskAnalyst(currentProject)) {
      return (
        <>
          {variantId !== null && variantId !== COMMON.nullUuid && (
            <>
              <S.SingleItem
                onClick={() => {
                  navigate(RoutePath.ImpactCalibration);
                }}
                id="riskAnalyst_ImpactCaliberation"
              >
                Impact Calibration
              </S.SingleItem>
              <S.SingleItem
                onClick={() => {
                  navigate(RoutePath.LikelihoodCalibration);
                }}
                id="riskAnalyst_likelihoodCaliberation"
              >
                Likelihood Calibration
              </S.SingleItem>
            </>
          )}
          <AccordionLevelTwo title="Configuration" content2={[ConfigurationMenu.Metadata, ConfigurationMenu.System]} />

          <div className="accordion__section">
            <S.Item id="riskAnalyst_systemModel" className={`accordion ${active}`} onClick={toggleAccordion}>
              {systemModelFolderIcon}
              System Model
            </S.Item>

            <div style={{ maxHeight: `${height}` }} className="accordion__content">
              <div className="accordion__text">
                <Accordion
                  title="Access"
                  content2={[
                    AccessMenu.AccessPoint,
                    AccessMenu.AccessPointCategory,
                    AccessMenu.ActivityBoundary,
                    AccessMenu.AttackSurface,
                    AccessMenu.AttackSurfaceCategory,
                    AccessMenu.AttackVectors,
                    AccessMenu.AttackVectorCategory,
                    AccessMenu.EntryPoints,
                    AccessMenu.EntryPointCategory,
                    AccessMenu.NodeBoundary,
                  ]}
                />
                <Accordion
                  title="Categorization"
                  content2={[
                    CategorizationMenu.categorizedEntity,
                    CategorizationMenu.classificationCategory,
                    CategorizationMenu.securityObjective,
                  ]}
                />
                <Accordion
                  title="System Asset"
                  content2={[
                    SystemAssetsMenu.SystemAsset,
                    SystemAssetsMenu.SystemAssetCategory,
                    SystemAssetsMenu.SystemAssetType,
                  ]}
                />
                <Accordion
                  title="System Facts"
                  content2={[
                    SystemFactsMenu.Nodes,
                    // SystemFactsMenu.Links,
                    // Commenting out all Bus related data for 1st sprint
                    /* SystemFactsMenu.Buses, */
                    SystemFactsMenu.Exchanges,
                    SystemFactsMenu.Datatypes,
                    SystemFactsMenu.Activities,
                    SystemFactsMenu.Dataflows,
                    SystemFactsMenu.Persons,
                    SystemFactsMenu.Resources,
                    SystemFactsMenu.Capabilities,
                    SystemFactsMenu.Missions,
                    SystemFactsMenu.ElementCategory,
                    SystemFactsMenu.StructuralCategory,
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="accordion__section">
            <S.Item id="riskAnalyst_riskModel" className={`accordion ${activeRisk}`} onClick={toggleAccordionRisk}>
              {riskModelFolderIcon}
              Risk Model
            </S.Item>

            <div style={{ maxHeight: `${heightRisk}` }} className="accordion__content">
              <div className="accordion__text">
                <Accordion title="Asset" content2={[AssetMenu.AssetLists, AssetMenu.AssetCategory]} />
                <Accordion title="Impact" content2={[ImpactMenu.UndesiredEvents, ImpactMenu.HarmCategory]} />
                <Accordion title="Risk" content2={[RiskMenu.RiskList, RiskMenu.RiskCategory]} />
                <Accordion
                  title="Threat Event"
                  content2={[ThreatMenu.ThreatEvents, ThreatMenu.ThreatEventCategory, ThreatMenu.ThreatEventPatterns]}
                />
                <Accordion
                  title="Attack"
                  content2={[
                    AttackMenu.Attacks,
                    AttackMenu.AttackKind,
                    AttackMenu.AttackMechanism,
                    AttackMenu.AttackTactic,
                  ]}
                />
                <Accordion
                  title="Vulnerability"
                  content2={[
                    VulnerabilityMenu.Vulnerability,
                    VulnerabilityMenu.VulnerabilityCategory,
                    VulnerabilityMenu.SoftwareVulnerability,
                    VulnerabilityMenu.CveFinding,
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="accordion__section">
            <S.Item className={`accordion ${activeThreat}`} onClick={toggleAccordionThreat}>
              {threatModelFolderIcon}
              <S.Element id="riskAnalyst_threatModel">Threat Model</S.Element>
            </S.Item>

            <div style={{ maxHeight: `${heightThreat}` }} className="accordion__content">
              <div className="accordion__text">
                <Accordion title="Threats" content2={[LocalThreatMenu.Attackers, LocalThreatMenu.AttackerCategories]} />
              </div>
            </div>
          </div>

          <AccordionLevelTwo
            title="Mitigation"
            content2={[MitigationMenu.AllocatedControls, MitigationMenu.sctm, MitigationMenu.Variant]}
          />
        </>
      );
    }
    return <></>;
  }

  function showCyberSecurityExpert() {
    return <></>;
  }

  function showControlCoordinator() {
    return <></>;
  }

  const renderMenu = (menu) => {
    return (
      <>
        {menu.map(({ id, icon, path, text, alt }) => {
          return (
            <MenuItem key={id} id={id} icon={icon} text={text} path={path} onClick={() => navigate(path)} alt={alt} />
          );
        })}
      </>
    );
  };

  const showAdmin = () => {
    /* prettier-ignore */
    const menu = [
      { id: "Admin_Organizations", icon: DocumentReadWriteIcon, path: RoutePath.Organizations, text: "Organizations", alt: "Model" },
      { id: "Admin_Users", icon: DocumentReadWriteIcon, path: RoutePath.Users, text: "Users", alt: "Model"  },
    ];
    return renderMenu(menu);
  };

  const showSuperAdmin = () => {
    /* prettier-ignore */
    const menu = [
      { id: "SuperAdmin_Installation", icon: DocumentReadWriteIcon, path: RoutePath.Installations, text: "Installation", alt: "Model" },
      { id: "SuperAdmin_Organizations", icon: DocumentReadWriteIcon, path: RoutePath.Organizations, text: "Organizations", alt: "Model" },
      { id: "SuperAdmin_Users", icon: DocumentReadWriteIcon, path: RoutePath.Users, text: "Users", alt: "Model"  },
    ];
    return renderMenu(menu);
  };

  function displayMenuBasedOnRole() {
    switch (userRole) {
      case "threatAnalyst":
        return (
          <div id="navigationMenu_threatAnalyst" style={{ padding: "0px" }}>
            <div className="accordion__section">
              <S.Item
                id="threatAnalyst_globals"
                className={`accordion ${activeGlobal}`}
                onClick={toggleAccordionGlobal}
              >
                {globalFolderIcon}
                Globals
              </S.Item>

              <div style={{ maxHeight: `${heightGlobal}` }} className="accordion__content">
                <div className="accordion__text">
                  <Accordion
                    title="Global Threats"
                    content2={[
                      GlobalThreatMenu.ThreatCatalog,
                      GlobalThreatMenu.ThreatGroup,
                      GlobalThreatMenu.ThreatTier,
                      GlobalThreatMenu.ThreatCampaign,
                      GlobalThreatMenu.ThreatSoftware,
                      GlobalThreatMenu.ThreatDataSource,
                      GlobalThreatMenu.OffensiveTactics,
                      GlobalThreatMenu.OffensiveTechniques,
                      GlobalThreatMenu.AffectedPlatforms,
                      GlobalThreatMenu.TargetAssets,
                    ]}
                  />
                  <Accordion
                    title="MITRE D3FEND"
                    content2={[
                      MitreD3fendMenu.MitreD3fend,
                      MitreD3fendMenu.DefensiveTactics,
                      MitreD3fendMenu.DefensiveTechniques,
                      MitreD3fendMenu.DigitalArtifacts,
                      MitreD3fendMenu.CciControls,
                      MitreD3fendMenu.NistControls,
                    ]}
                  />
                </div>
              </div>
            </div>

            {showThreatAnalyst()}
          </div>
        );
      case "systemsEngineer":
        return (
          <div id="navigationMenu_systemsEngineer" style={{ padding: "0px" }}>
            {showSystemEngineer()}
          </div>
        );

      case "riskAnalyst":
        return (
          <div id="navigationMenu_riskAnalyst" style={{ padding: "0px" }}>
            {showRiskAnalyst()}
          </div>
        );

      case "cybersecurityExpert":
        return (
          <div id="navigationMenu_cyberSecurityExpert" style={{ padding: "0px" }}>
            <S.SingleItem
              onClick={() => {
                navigate(RoutePath.Knowledgebases);
              }}
              id="cyberSecurityExpert_Knowledgebases"
            >
              {KnowledgebaseMenu.KBList.name}
            </S.SingleItem>
            {/* 
            TODO :: Need to get clarification from Nick regarding the Kb configuration for organizations
            <S.Item
              onClick={() => {
                navigate(RoutePath.KbOrganizations);
              }}
              id="cyberSecurityExpert_OrganizationKBConfiguration"
            >
              <SidebarIconStyled src={DocumentRw} alt="Item" />
              Organization KB Configuration
            </S.Item> */}
            <S.SingleItem
              onClick={() => {
                navigate(RoutePath.KbProjects);
              }}
              id="cyberSecurityExpert_ProjectKBConfiguration"
            >
              {KnowledgebaseMenu.ProjectKBConfiguration.name}
            </S.SingleItem>
            {showCyberSecurityExpert()}
          </div>
        );

      case "controlCoordinator":
        return (
          <div id="navigationMenu_controlCoordinator" style={{ padding: "0px" }}>
            <S.SingleItem
              onClick={() => {
                navigate(RoutePath.ProjectCatalogConfig);
              }}
              id="controlCoordinator_ProjectCatalogConfiguration"
            >
              Project Catalog Configuration
            </S.SingleItem>
            <div className="accordion__section">
              <S.Item
                id="controlCoordinator_globals"
                className={`accordion ${activeGlobal}`}
                onClick={toggleAccordionGlobal}
              >
                {globalFolderIcon}
                Globals
              </S.Item>

              <div style={{ maxHeight: `${heightGlobal}` }} className="accordion__content">
                <div className="accordion__text">
                  <Accordion
                    title="Global Control"
                    content2={[
                      GlobalControlMenu.ControlCatalog,
                      GlobalControlMenu.ControlFamily,
                      GlobalControlMenu.ControlType,
                      GlobalControlMenu.StandardBaseline,
                    ]}
                    accordionActive={STATUS.active}
                  />
                </div>
              </div>
            </div>

            {showControlCoordinator()}
          </div>
        );

      case "admin":
        return (
          <div id="navgationMenu_Admin" style={{ padding: "0px" }}>
            {showAdmin()}
          </div>
        );

      case "superAdmin":
        return (
          <div id="navigationMenu_SuperAdmin" style={{ padding: "0px" }}>
            {showSuperAdmin()}
          </div>
        );

      case "rmfCoordinator":
        return <div id="navigationMenu_rmfCoordinator" style={{ padding: "0px" }} />;
      case "executive":
        return <div id="navigationMenu_executive" style={{ padding: "0px" }} />;

      default:
        return (
          <div style={{ padding: "0px" }}>
            {/* This Menu will be shown if there is no userrole selected(for testing purpose only). Should be modified later of what to show */}
          </div>
        );
    }
  }

  return (
    <HighlightElementProvider>
      <S.Menu>{displayMenuBasedOnRole()}</S.Menu>
    </HighlightElementProvider>
  );
};

export default Menu;

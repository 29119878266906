import * as React from "react";
// import { useState } from "react";
// import { useQueryClient } from "@tanstack/react-query";
// Components
// Styles
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import Title from "components/elements/StyledTitle";
// Services
// State
// import { selectedInstanceState } from "atoms/atoms-content";
// import { useRecoilState } from "recoil";

// import ContentLayout from "brm/layout/Content/ContentLayout";
import { ThreatApi } from "features/brm";

// import { OffensiveTechniquesDetailsTable } from "features/global-threat/components/details-tables/OffensiveTechniquesDetailsTable";
import { useParams } from "react-router-dom";
import * as S from "features/global-threat/style/property.style";

import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { OffensiveTechniqueTabs } from "features/global-threat/components/tabs/OffensiveTechniqueTabs";

export const OffensiveTechnique = () => {
  const { id } = useParams();

  const {
    data: offensiveTechnique,
    isError: isOffensiveTechniqueError,
    error: offensiveTechniqueError,
  } = ThreatApi.useOffensiveTechnique({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  const {
    data: threatCatalog,
    isError: isThreatCatalogError,
    error: threatCatalogError,
  } = ThreatApi.useThreatCatalog({
    threatCatalogId: offensiveTechnique?.catalog || "",
    options: {
      enabled: offensiveTechnique !== undefined,
    },
  });

  const tacticResults = ThreatApi.useOffensiveTacticsByIds({
    tactics: offensiveTechnique?.tactic || [],
    options: {
      enabled: offensiveTechnique !== undefined,
    },
  });

  const tactics = React.useMemo(() => {
    if (tacticResults.every((r) => r.isSuccess)) {
      return tacticResults.map((r) => r.data);
    }
    return undefined;
  }, [tacticResults]);

  // console.log("offensiveTechnique", offensiveTechnique);

  const dataSourceResults = ThreatApi.useThreatDataSourcesById({
    dataSources: offensiveTechnique?.datasource || [],
    options: {
      enabled: offensiveTechnique !== undefined,
    },
  });

  const dataSources = React.useMemo(() => {
    if (dataSourceResults.every((r) => r.isSuccess)) {
      return dataSourceResults.map((r) => r.data);
    }
    return undefined;
  }, [dataSourceResults]);

  const affectedPlatformResults = ThreatApi.useAffectedPlatformsByIds({
    platforms: offensiveTechnique?.platform || [],
    options: {
      enabled: offensiveTechnique !== undefined,
    },
  });

  const affectedPlatforms = React.useMemo(() => {
    if (affectedPlatformResults.every((r) => r.isSuccess)) {
      return affectedPlatformResults.map((r) => r.data);
    }
    return undefined;
  }, [affectedPlatformResults]);

  const targetAssetResults = ThreatApi.useTargetAssetsByIds({
    targets: offensiveTechnique?.target || [],
    options: {
      enabled: offensiveTechnique !== undefined,
    },
  });

  const targetAssets = React.useMemo(() => {
    if (targetAssetResults.every((r) => r.isSuccess)) {
      return targetAssetResults.map((r) => r.data);
    }
    return undefined;
  }, [targetAssetResults]);

  // console.log("tactics", tactics);
  // console.log("tacticResults", tacticResults);

  // const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  // const queryClient = useQueryClient();
  // const [confirmDelete, setConfirmDelete] = useState(false);
  // const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  // const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  // const header = (
  //   <Title id="OffensiveTechniques_title">
  //     Offensive Technique
  //     {/* <Buttons id="OffensiveTechniques_buttons">
  //       {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
  //       <AddButton onClick={addThreatCatalog} />
  //     </Buttons> */}
  //   </Title>
  // );

  // const main = (
  //   <>
  //     <OffensiveTechniquesDetailsTable
  //     // setSelectedRows={setSelectedRows}
  //     // setUploadModalIsOpen={setUploadModalIsOpen}
  //     // setIsExportModalOpen={setIsExportModalOpen}
  //     />
  //   </>
  // );

  if (isOffensiveTechniqueError) {
    return <ErrorBanner msg={(offensiveTechniqueError as any)?.statusText} />;
  }

  if (isThreatCatalogError) {
    return <ErrorBanner msg={(threatCatalogError as any)?.statusText} />;
  }

  // return <ContentLayout pageId="OffensiveTechnique_page" header={header} main={main} />;
  if (offensiveTechnique) {
    return (
      <>
        <Title>Offensive Technique: {offensiveTechnique.name}</Title>

        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{offensiveTechnique.name}</div>
          <S.PropertyLabel>Catalog</S.PropertyLabel>
          <div>{threatCatalog ? threatCatalog.name : <Loading />}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{offensiveTechnique.description}</div>
          <S.PropertyLabel>Tactic</S.PropertyLabel>
          <div>{tactics ? tactics.map((t: any) => t.name).join(",") : <Loading />}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{offensiveTechnique.sourceId}</div>
          <S.PropertyLabel>Source URL</S.PropertyLabel>
          <div>{offensiveTechnique.sourceURL}</div>
          <S.PropertyLabel>Detection</S.PropertyLabel>
          <div>{offensiveTechnique.detection}</div>
          <S.PropertyLabel>isSubtechnique</S.PropertyLabel>
          <div>{offensiveTechnique.isSubtechnique ? "True" : "False"}</div>
          <S.PropertyLabel>Parent</S.PropertyLabel>
          <div>{offensiveTechnique.parentname}</div>
          {/* <S.PropertyLabel>Parent</S.PropertyLabel>
          <div>{offensiveTechnique.parent}</div> */}
          <S.PropertyLabel>Platform</S.PropertyLabel>
          <div>{affectedPlatforms ? affectedPlatforms.map((t: any) => t.name).join(", ") : <Loading />}</div>
          <S.PropertyLabel>Data Source</S.PropertyLabel>
          <div>{dataSources ? dataSources.map((t: any) => t.name).join(", ") : <Loading />}</div>
          <S.PropertyLabel>Target</S.PropertyLabel>
          <div>{targetAssets ? targetAssets.map((t: any) => t.name).join(", ") : <Loading />}</div>
          <S.PropertyLabel>Impact Type</S.PropertyLabel>
          <div>{offensiveTechnique.impactType ? offensiveTechnique.impactType : "N/A"}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <OffensiveTechniqueTabs /* selectedElement={offensiveTechnique}  */ />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }
  return <Loading />;
};

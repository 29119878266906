import { RoutePath } from "routes/route-paths";

// export const HomeContentKey = "home";

// eslint-disable-next-line import/prefer-default-export
export const routes = {
  noPath: {
    contentKey: "noPath",
    path: "",
    name: "",
    crumbs: [],
    showProject: false,
    showProjects: false,
  },
  reports: {
    contentKey: "reports",
    path: RoutePath.Reports,
    name: "Reports",
    crumbs: [],
  },
  help: {
    contentKey: "help",
    path: RoutePath.Help,
    name: "Help",
    crumbs: [],
  },
  systemModel: {
    contentKey: "systemModel",
    path: "",
    name: "System Model",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  riskModel: {
    contentKey: "riskModel",
    path: "",
    name: "Risk Model",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  threatModel: {
    contentKey: "threatModel",
    path: "",
    name: "Threat Model",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  globals: {
    contentKey: "globals",
    path: "",
    name: "Globals",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  systemFacts: {
    contentKey: "systemFacts",
    path: "",
    name: "System Facts",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  systemAsset: {
    contentKey: "systemAsset",
    path: "",
    name: "System Asset",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  categorization: {
    contentKey: "categorization",
    path: "",
    name: "Categorization",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  access: {
    contentKey: "access",
    path: "",
    name: "Access",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  asset: {
    contentKey: "asset",
    path: "",
    name: "Asset",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  impact: {
    contentKey: "impact",
    path: "",
    name: "Impact",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  risk: {
    contentKey: "risk",
    path: "",
    name: "Risk",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  threat: {
    contentKey: "threat",
    path: "",
    name: "Threats",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  threatEvent: {
    contentKey: "threatEvent",
    path: "",
    name: "Threat Event",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  attack: {
    contentKey: "attack",
    path: "",
    name: "Attack",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  vulnerability: {
    contentKey: "vulnerability",
    path: "",
    name: "Vulnerability",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  globalThreats: {
    contentKey: "globalThreats",
    path: "",
    name: "Global Threats",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  globalControl: {
    contentKey: "globalControl",
    path: "",
    name: "Global Control",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  systemDiagram: {
    contentKey: "systemDiagram",
    path: RoutePath.SystemDiagram,
    name: "System Diagram",
    crumbs: ["systemDiagram"],
  },
  contextDiagram: {
    contentKey: "contextDiagram",
    path: RoutePath.ContextDiagram,
    name: "Context Diagram",
    crumbs: ["contextDiagram"],
  },
  attackDiagram: {
    contentKey: "attackDiagram",
    path: RoutePath.AttackDiagram,
    name: "Attack Diagram",
    crumbs: ["attackDiagram"],
  },
  threatCatalogs: {
    contentKey: "threatCatalogs",
    path: RoutePath.ThreatCatalogs,
    name: "Threat Catalogs",
    crumbs: ["globals", "globalThreats"],
    showProject: false,
    showProjects: false,
  },
  threatCatalogsDetails: {
    contentKey: "threatCatalogsDetails",
    path: RoutePath.ThreatCatalog,
    name: "Details",
    crumbs: ["globals", "globalThreats", "threatCatalogs"],
  },
  threatTiers: {
    contentKey: "threatTiers",
    path: RoutePath.ThreatTiers,
    name: "Threat Tiers",
    crumbs: ["globals", "globalThreats"],
    showProject: false,
    showProjects: false,
  },
  threatTiersDetails: {
    contentKey: "threatTiersDetails",
    path: RoutePath.ThreatTier,
    name: "Details",
    crumbs: ["globals", "globalThreats", "threatTiers"],
  },
  threatGroups: {
    contentKey: "threatGroups",
    path: RoutePath.ThreatGroups,
    name: "Threat Groups",
    crumbs: ["globals", "globalThreats"],
    showProject: false,
    showProjects: false,
  },
  threatGroupDetails: {
    contentKey: "threatGroupDetails",
    path: RoutePath.ThreatGroup,
    name: "Details",
    crumbs: ["globals", "globalThreats", "threatGroups"],
    showProject: false,
    showProjects: false,
  },
  attackMechanisms: {
    contentKey: "attackMechanisms",
    path: RoutePath.AttackMechanisms,
    name: "Attack Mechanisms",
    crumbs: ["riskModel", "attack"],
  },
  attackMechanismFullDetails: {
    contentKey: "attackMechanismFullDetails",
    path: RoutePath.AttackMechanism,
    name: "Details",
    crumbs: ["riskModel", "attack", "attackMechanisms"],
  },
  attackTactics: {
    contentKey: "attackTactics",
    path: RoutePath.AttackTactics,
    name: "Attack Tactics",
    crumbs: ["riskModel", "attack"],
  },
  attackTacticFullDetails: {
    contentKey: "attackTacticFullDetails",
    path: RoutePath.AttackTactic,
    name: "Details",
    crumbs: ["riskModel", "attack", "attackTactics"],
  },
  attackKinds: {
    contentKey: "attackKinds",
    path: RoutePath.AttackKinds,
    name: "Attack Kinds",
    crumbs: ["riskModel", "attack"],
  },
  attackKindFullDetails: {
    contentKey: "attackKindFullDetails",
    path: RoutePath.AttackKind,
    name: "Details",
    crumbs: ["riskModel", "attack", "attackKinds"],
  },
  attacks: {
    contentKey: "attacks",
    path: RoutePath.Attacks,
    name: "Attacks",
    crumbs: ["riskModel", "attack"],
  },
  attackFullDetails: {
    contentKey: "attackFullDetails",
    path: RoutePath.Attack,
    name: "Details",
    crumbs: ["riskModel", "attack", "attacks"],
  },
  attackerCategories: {
    contentKey: "attackerCategories",
    path: RoutePath.AttackerCategories,
    name: "Attacker Categories",
    crumbs: ["threatModel", "threat"],
  },
  attackerCategoriesDetails: {
    contentKey: "attackerCategoriesDetails",
    path: RoutePath.AttackerCategory,
    name: "Details",
    crumbs: ["threatModel", "threat", "attackerCategories"],
  },
  attackers: {
    contentKey: "attackers",
    path: RoutePath.Attackers,
    name: "Attackers",
    crumbs: ["threatModel", "threat"],
  },
  attackersDetails: {
    contentKey: "attackersDetails",
    path: RoutePath.Attacker,
    name: "Details",
    crumbs: ["threatModel", "threat", "attackers"],
  },
  structuralCategories: {
    contentKey: "structuralCategories",
    path: RoutePath.StructuralCategories,
    name: "Structural Categories",
    crumbs: ["systemModel", "systemFacts"],
  },
  structuralCategoryFullDetails: {
    contentKey: "structuralCategoryFullDetails",
    path: RoutePath.StructuralCategory,
    name: "Details",
    crumbs: ["structuralCategories"],
  },
  elementCategories: {
    contentKey: "elementCategories",
    path: RoutePath.ElementCategories,
    name: "Element Categories",
    crumbs: ["systemModel", "systemFacts"],
  },
  elementCategoryFullDetails: {
    contentKey: "elementCategoryFullDetails",
    path: RoutePath.ElementCategory,
    name: "Details",
    crumbs: ["elementCategories"],
  },
  nodes: {
    contentKey: "nodes",
    path: RoutePath.Nodes,
    name: "Nodes",
    crumbs: ["systemModel", "systemFacts"],
  },
  nodeFullDetails: {
    contentKey: "nodeFullDetails",
    path: RoutePath.Node,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "nodes"],
  },
  busFullDetails: {
    contentKey: "busFullDetails",
    path: RoutePath.Bus,
    name: "Details",
    crumbs: ["busFullDetails"],
  },
  links: {
    contentKey: "links",
    path: RoutePath.Links,
    name: "Links",
    crumbs: ["systemModel", "systemFacts"],
  },
  linkFullDetails: {
    contentKey: "linkFullDetails",
    path: RoutePath.Link,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "links"],
  },
  groups: {
    contentKey: "groups",
    path: RoutePath.Groups,
    name: "Groups",
    crumbs: [],
  },
  channels: {
    contentKey: "channels",
    path: RoutePath.Channels,
    name: "Channels",
    crumbs: [],
  },
  exchanges: {
    contentKey: "exchanges",
    path: RoutePath.Exchanges,
    name: "Exchanges",
    crumbs: ["systemModel", "systemFacts"],
  },
  exchangeFullDetails: {
    contentKey: "exchangeFullDetails",
    path: RoutePath.Exchange,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "exchanges"],
  },
  datatypes: {
    contentKey: "datatypes",
    path: RoutePath.Datatypes,
    name: "Datatypes",
    crumbs: ["systemModel", "systemFacts"],
  },
  datatypeFullDetails: {
    contentKey: "datatypeFullDetails",
    path: RoutePath.Datatype,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "datatypes"],
  },
  persons: {
    contentKey: "persons",
    path: RoutePath.Persons,
    name: "Persons",
    crumbs: ["systemModel", "systemFacts"],
  },
  personFullDetails: {
    contentKey: "personFullDetails",
    path: RoutePath.Person,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "persons"],
  },
  resources: {
    contentKey: "resources",
    path: RoutePath.Resources,
    name: "Resources",
    crumbs: ["systemModel", "systemFacts"],
  },
  resourceFullDetails: {
    contentKey: "resourceFullDetails",
    path: RoutePath.Resource,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "resources"],
  },
  dataflows: {
    contentKey: "dataflows",
    path: RoutePath.Dataflows,
    name: "Dataflows",
    crumbs: ["systemModel", "systemFacts"],
  },
  dataflowFullDetails: {
    contentKey: "dataflowFullDetails",
    path: RoutePath.Dataflow,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "dataflows"],
  },
  capabilities: {
    contentKey: "capabilities",
    path: RoutePath.Capabilities,
    name: "Capabilities",
    crumbs: ["systemModel", "systemFacts"],
  },
  capabilityFullDetails: {
    contentKey: "capabilityFullDetails",
    path: RoutePath.Capability,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "capabilities"],
  },
  activities: {
    contentKey: "activities",
    path: RoutePath.Activities,
    name: "Activities",
    crumbs: ["systemModel", "systemFacts"],
  },
  activityFullDetails: {
    contentKey: "activityFullDetails",
    path: RoutePath.Activity,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "activities"],
  },
  missions: {
    contentKey: "missions",
    path: RoutePath.Missions,
    name: "Missions",
    crumbs: ["systemModel", "systemFacts"],
  },
  missionFullDetails: {
    contentKey: "missionFullDetails",
    path: RoutePath.Mission,
    name: "Details",
    crumbs: ["systemModel", "systemFacts", "missions"],
  },
  entryPoints: {
    contentKey: "entryPoints",
    path: RoutePath.EntryPoints,
    name: "Entry Points",
    crumbs: ["systemModel", "access"],
  },
  entryPointsDetails: {
    contentKey: "entryPointsDetails",
    path: RoutePath.EntryPoint,
    name: "Details",
    crumbs: ["systemModel", "access", "entryPoints"],
  },
  entryPointCategories: {
    contentKey: "entryPointCategories",
    path: RoutePath.EntryPointCategories,
    name: "Entry Point Categories",
    crumbs: ["systemModel", "access"],
  },
  entryPointCategoriesDetails: {
    contentKey: "entryPointCategoriesDetails",
    path: RoutePath.EntryPointCategory,
    name: "Details",
    crumbs: ["entryPointCategories"],
  },
  accessPointCategories: {
    contentKey: "accessPointCategories",
    path: RoutePath.AccessPointCategories,
    name: "Access Point Categories",
    crumbs: ["systemModel", "access"],
  },
  accessPointCategoriesDetails: {
    contentKey: "accessPointCategoriesDetails",
    path: RoutePath.AccessPointCategory,
    name: "Details",
    crumbs: ["accessPointCategories"],
  },
  accessPoints: {
    contentKey: "accessPoints",
    path: RoutePath.AccessPoints,
    name: "Access Points",
    crumbs: ["systemModel", "access"],
  },
  accessPointsDetails: {
    contentKey: "accessPointsDetails",
    path: RoutePath.AccessPoint,
    name: "Details",
    crumbs: ["systemModel", "access", "accessPoints"],
  },
  attackSurfaces: {
    contentKey: "attackSurfaces",
    path: RoutePath.AttackSurfaces,
    name: "Attack Surfaces",
    crumbs: ["systemModel", "access"],
  },
  attackSurfacesDetails: {
    contentKey: "attackSurfacesDetails",
    path: RoutePath.AttackSurface,
    name: "Details",
    crumbs: ["systemModel", "access", "attackSurfaces"],
  },
  attackSurfaceCategories: {
    contentKey: "attackSurfaceCategories",
    path: RoutePath.AttackSurfaceCategories,
    name: "Attack Surface Categories",
    crumbs: ["systemModel", "access"],
  },
  attackSurfaceCategoriesDetails: {
    contentKey: "attackSurfaceCategoriesDetails",
    path: RoutePath.AttackSurfaceCategory,
    name: "Details",
    crumbs: ["attackSurfaceCategories"],
  },
  attackVectorCategories: {
    contentKey: "attackVectorCategories",
    path: RoutePath.AttackVectorCategories,
    name: "Attack Vector Categories",
    crumbs: ["systemModel", "access"],
  },
  attackVectorCategoriesDetails: {
    contentKey: "attackVectorCategoriesDetails",
    path: RoutePath.AttackVectorCategory,
    name: "Details",
    crumbs: ["attackVectorCategories"],
  },
  attackVectors: {
    contentKey: "attackVectors",
    path: RoutePath.AttackVectors,
    name: "Attack Vectors",
    crumbs: ["systemModel", "access"],
  },
  attackVectorsDetails: {
    contentKey: "attackVectorsDetails",
    path: RoutePath.AttackVector,
    name: "Details",
    crumbs: ["systemModel", "access", "attackVectors"],
  },
  boundaries: {
    contentKey: "boundaries",
    path: RoutePath.Boundaries,
    name: "Boundaries",
    crumbs: ["systemModel", "access"],
  },
  boundariesActivities: {
    contentKey: "boundariesActivities",
    path: RoutePath.BoundariesActivities,
    name: "Boundary Activities",
    crumbs: ["systemModel", "access"],
  },
  classifiedCategories: {
    contentKey: "classifiedCategories",
    path: RoutePath.ClassificationCategories,
    name: "Classified Categories",
    crumbs: ["systemModel", "categorization"],
  },
  classifiedCategoriesOverview: {
    contentKey: "classifiedCategoriesOverview",
    path: RoutePath.ClassificationCategory,
    name: "Details",
    crumbs: ["classifiedCategories"],
  },
  securityObjectives: {
    contentKey: "securityObjectives",
    path: RoutePath.SecurityObjectives,
    name: "Security Objectives",
    crumbs: ["systemModel", "categorization"],
  },
  securityObjectivesOverview: {
    contentKey: "securityObjectivesOverview",
    path: RoutePath.SecurityObjective,
    name: "Details",
    crumbs: ["systemModel", "categorization", "securityObjectives"],
  },
  categorizedEntities: {
    contentKey: "categorizedEntities",
    path: RoutePath.CategorizedEntities,
    name: "Categorized Entities",
    crumbs: ["systemModel", "categorization"],
  },

  categorizedEntitiesOverview: {
    contentKey: "categorizedEntitiesOverview",
    path: RoutePath.CategorizedEntity,
    name: "Details",
    crumbs: ["systemModel", "categorization", "categorizedEntities"],
  },
  systemAssets: {
    contentKey: "systemAssets",
    path: RoutePath.SystemAssets,
    name: "System Assets",
    crumbs: ["systemModel", "systemAsset"],
  },
  systemAssetFullDetails: {
    contentKey: "systemAssetFullDetails",
    path: RoutePath.SystemAsset,
    name: "Details",
    crumbs: ["systemModel", "systemAsset", "systemAssets"],
  },
  systemAssetCategories: {
    contentKey: "systemAssetCategories",
    path: RoutePath.SystemAssetCategories,
    name: "System Asset Categories",
    crumbs: ["systemModel", "systemAsset"],
  },
  systemAssetCategoryFullDetails: {
    contentKey: "systemAssetCategoryFullDetails",
    path: RoutePath.SystemAssetCategory,
    name: "Details",
    crumbs: ["systemAssetCategories"],
  },
  systemAssetTypes: {
    contentKey: "systemAssetTypes",
    path: RoutePath.SystemAssetTypes,
    name: "System Asset Types",
    crumbs: ["systemModel", "systemAsset"],
  },
  systemAssetTypeFullDetails: {
    contentKey: "systemAssetTypeFullDetails",
    path: RoutePath.SystemAssetType,
    name: "Details",
    crumbs: ["systemModel", "systemAsset", "systemAssetTypes"],
  },
  threatEventPatterns: {
    contentKey: "threatEventPatterns",
    path: RoutePath.ThreatEventPatterns,
    name: "Threat Event Patterns",
    crumbs: ["riskModel", "threatEvent"],
  },
  threatEventPatternFullDetails: {
    contentKey: "threatEventPatternFullDetails",
    path: RoutePath.ThreatEventPattern,
    name: "Details",
    crumbs: ["riskModel", "threatEvent", "threatEventPatterns"],
  },
  threatEventCategories: {
    contentKey: "threatEventCategories",
    path: RoutePath.ThreatEventCategories,
    name: "Threat Event Categories",
    crumbs: ["riskModel", "threatEvent"],
  },
  threatEventCategoryFullDetails: {
    contentKey: "threatEventCategoryFullDetails",
    path: RoutePath.ThreatEventCategory,
    name: "Details",
    crumbs: ["riskModel", "threatEvent", "threatEventCategories"],
  },
  threatEvents: {
    contentKey: "threatEvents",
    path: RoutePath.ThreatEvents,
    name: "Threat Events",
    crumbs: ["riskModel", "threatEvent"],
  },
  threatEventFullDetails: {
    contentKey: "threatEventFullDetails",
    path: RoutePath.ThreatEvent,
    name: "Details",
    crumbs: ["riskModel", "threatEvent", "threatEvents"],
  },
  riskCategories: {
    contentKey: "riskCategories",
    path: RoutePath.RiskCategories,
    name: "Risk Categories",
    crumbs: ["riskModel", "risk"],
  },
  riskCategoryFullDetails: {
    contentKey: "riskCategoryFullDetails",
    path: RoutePath.RiskCategory,
    name: "Details",
    crumbs: ["riskModel", "risk", "riskCategories"],
  },
  risks: {
    contentKey: "risks",
    path: RoutePath.Risks,
    name: "Risks",
    crumbs: ["riskModel", "risk"],
  },
  riskFullDetails: {
    contentKey: "riskFullDetails",
    path: RoutePath.Risk,
    name: "Details",
    crumbs: ["riskModel", "risk", "risks"],
  },
  harms: {
    contentKey: "harms",
    path: RoutePath.Harms,
    name: "Harm Categories",
    crumbs: ["riskModel", "impact"],
  },
  harmCategoryFullDetails: {
    contentKey: "harmCategoryFullDetails",
    path: RoutePath.Harm,
    name: "Details",
    crumbs: ["riskModel", "impact", "harms"],
  },
  undesiredEvents: {
    contentKey: "undesiredEvents",
    path: RoutePath.UndesiredEvents,
    name: "Undesired Events",
    crumbs: ["riskModel", "impact"],
  },
  undesiredEventFullDetails: {
    contentKey: "undesiredEventFullDetails",
    path: RoutePath.UndesiredEvent,
    name: "Details",
    crumbs: ["riskModel", "impact", "undesiredEvents"],
  },
  assetCategories: {
    contentKey: "assetCategories",
    path: RoutePath.AssetCategories,
    name: "Asset Categories",
    crumbs: ["riskModel", "asset"],
  },
  assetCategoryFullDetails: {
    contentKey: "assetCategoryFullDetails",
    path: RoutePath.AssetCategory,
    name: "Details",
    crumbs: ["riskModel", "asset", "assetCategories"],
  },
  assets: {
    contentKey: "assets",
    path: RoutePath.Assets,
    name: "Assets",
    crumbs: ["riskModel", "asset"],
  },
  assetFullDetails: {
    contentKey: "assetFullDetails",
    path: RoutePath.Asset,
    name: "Details",
    crumbs: ["riskModel", "asset", "assets"],
  },
  vulnerabilities: {
    contentKey: "vulnerabilities",
    path: RoutePath.Vulnerabilities,
    name: "Vulnerability Conditions",
    crumbs: ["riskModel", "vulnerability"],
  },
  vulnerabilityFullDetails: {
    contentKey: "vulnerabilityFullDetails",
    path: RoutePath.Vulnerability,
    name: "Details",
    crumbs: ["riskModel", "vulnerability", "vulnerabilities"],
  },
  vulnerabilityCategories: {
    contentKey: "vulnerabilityCategories",
    path: RoutePath.VulnerabilityCategories,
    name: "Vulnerability Categories",
    crumbs: ["riskModel", "vulnerability"],
  },
  vulnerabilityCategoryFullDetails: {
    contentKey: "vulnerabilityCategoryFullDetails",
    path: RoutePath.VulnerabilityCategory,
    name: "Details",
    crumbs: ["riskModel", "vulnerability", "vulnerabilityCategories"],
  },
  softwareVulnerabilities: {
    contentKey: "softwareVulnerabilities",
    path: RoutePath.SoftwareVulnerabilities,
    name: "Vulnerability Findings",
    crumbs: ["riskModel", "vulnerability"],
  },
  sctms: {
    contentKey: "sctmsDetails",
    path: RoutePath.Sctm,
    name: "SCTM",
    crumbs: ["mitigation"],
  },
  sctmsDetails: {
    contentKey: "sctmsDetails",
    path: RoutePath.Sctm,
    name: "Details",
    crumbs: ["mitigation", "sctms"],
  },
  allocatedControls: {
    contentKey: "allocatedControls",
    path: RoutePath.AllocatedControls,
    name: "Allocated Controls",
    crumbs: ["mitigation"],
  },
  allocatedControlsDetails: {
    contentKey: "allocatedControlsDetails",
    path: RoutePath.AllocatedControl,
    name: "Details",
    crumbs: ["mitigation", "allocatedControls"],
  },
  controlCatalogs: {
    contentKey: "controlCatalogs",
    path: RoutePath.ControlCatalogs,
    name: "Control Catalogs",
    crumbs: ["globals", "globalControl"],
    showProject: false,
    showProjects: false,
  },
  controlCatalogsDetails: {
    contentKey: "controlCatalogsDetails",
    path: RoutePath.ControlCatalog,
    name: "Details",
    crumbs: ["globals", "globalControl", "controlCatalogs"],
  },
  controlFamily: {
    contentKey: "controlFamily",
    path: RoutePath.ControlFamilies,
    name: "Control Families",
    crumbs: ["globals", "globalControl"],
    showProject: false,
    showProjects: false,
  },
  controlFamilyFullDetails: {
    contentKey: "controlFamilyFullDetails",
    path: RoutePath.ControlFamily,
    name: "Details",
    crumbs: ["globals", "globalControl", "controlFamily"],
  },
  controlType: {
    contentKey: "controlType",
    path: RoutePath.ControlTypes,
    name: "Control Types",
    crumbs: ["globals", "globalControl"],
    showProject: false,
    showProjects: false,
  },
  controlTypeFullDetails: {
    contentKey: "controlTypeFullDetails",
    path: RoutePath.ControlType,
    name: "Details",
    crumbs: ["globals", "globalControl", "controlType"],
  },
  standardBaselines: {
    contentKey: "standardBaselines",
    path: RoutePath.StandardBaselines,
    name: "Baselines",
    crumbs: ["globals", "globalControl"],
    showProject: false,
    showProjects: false,
  },
  standardBaselineFullDetails: {
    contentKey: "standardBaselineFullDetails",
    path: RoutePath.StandardBaseline,
    name: "Details",
    crumbs: ["globals", "globalControl", "standardBaselines"],
  },
  projectCatalogConfig: {
    contentKey: "projectCatalogConfig",
    path: RoutePath.ProjectCatalogConfig,
    name: "Project Catalog Configuration",
    crumbs: [],
    showProject: false,
    showProjects: false,
  },
  knowledgebases: {
    contentKey: "knowledgebases",
    path: RoutePath.Knowledgebases,
    name: "Knowledgebases",
    crumbs: [],
    showProject: false,
    showProjects: false,
  },
  kbOrganizations: {
    contentKey: "kbOrganizations",
    path: RoutePath.KbOrganizations,
    name: "Organization",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  knowledgebasesDetails: {
    contentKey: "knowledgebasesDetails",
    path: RoutePath.Knowledgebase,
    name: "Details",
    crumbs: ["knowledgebases"],
  },
  kbProjects: {
    contentKey: "kbProjects",
    path: RoutePath.KbProjects,
    name: "Projects KB Configuration",
    crumbs: [],
    showProject: false,
    showProjects: false,
  },
  configuration: {
    contentKey: "configuration",
    path: "",
    name: "Configuration",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  metadata: {
    contentKey: "metadata",
    path: RoutePath.Metadata,
    name: "Metadata",
    crumbs: ["configuration"],
    showProject: false,
    showProjects: false,
  },
  modules: {
    contentKey: "modules",
    path: RoutePath.Modules,
    name: "Modules",
    crumbs: ["configuration"],
  },
  modulesDetails: {
    contentKey: "modulesDetails",
    path: RoutePath.Module,
    name: "Details",
    crumbs: ["configuration", "modules"],
  },
  systems: {
    contentKey: "systems",
    path: RoutePath.Systems,
    name: "Systems",
    crumbs: ["configuration"],
  },
  systemsDetails: {
    contentKey: "systemsDetails",
    path: RoutePath.System,
    name: "Details",
    crumbs: ["configuration", "systems"],
  },
  mitigation: {
    contentKey: "mitigation",
    path: "",
    name: "Mitigation",
    crumbs: ["noPath"],
  },
  variants: {
    contentKey: "variants",
    path: RoutePath.Variants,
    name: "Variants",
    crumbs: ["mitigation"],
  },
  variantsDetails: {
    contentKey: "variantsDetails",
    path: RoutePath.Variant,
    name: "Details",
    crumbs: ["mitigation", "variants"],
  },
  projects: {
    contentKey: "projects",
    path: RoutePath.Projects,
    name: "Projects",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  projectOverview: {
    contentKey: "projectOverview",
    path: RoutePath.Project,
    name: "Details",
    crumbs: [],
    showProject: false,
    showProjects: true,
  },
  impactCalibration: {
    contentKey: "impactCalibration",
    path: RoutePath.ImpactCalibration,
    name: "Impact Calibration",
    crumbs: [],
  },
  likelihoodCalibration: {
    contentKey: "likelihoodCalibration",
    path: RoutePath.LikelihoodCalibration,
    name: "Likelihood Calibration",
    crumbs: [],
  },
  organizations: {
    contentKey: "organizations",
    path: RoutePath.Organizations,
    name: "Organizations",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
    showOrganization: false,
    showOrganizations: false,
  },
  organizationOverview: {
    contentKey: "organizationOverview",
    path: RoutePath.Organization,
    name: "Details",
    crumbs: ["organizations"],
    showProject: false,
    showProjects: false,
    showOrganization: true,
    showOrganizations: true,
  },
  installation: {
    contentKey: "installation",
    path: RoutePath.Installations,
    name: "Installation",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  installationOverview: {
    contentKey: "installationOverview",
    path: RoutePath.Installation,
    name: "Details",
    crumbs: ["installation"],
  },
  users: {
    contentKey: "users",
    path: RoutePath.Users,
    name: "Users",
    crumbs: ["noPath"],
    showProject: false,
    showProjects: false,
  },
  userOverview: {
    contentKey: "userOverview",
    path: RoutePath.User,
    name: "Details",
    crumbs: ["users"],
    showProject: false,
    showProjects: false,
    showOrganization: false,
    showOrganizations: false,
  },

  projectOverviewImporter: {
    contentKey: "projectOverviewImporter",
    path: RoutePath.ProjectImport,
    name: "Details",
    crumbs: ["projects"],
  },
  addUsers: {
    contentKey: "addUsers",
    path: RoutePath.AddUser,
    name: "Add User",
    crumbs: ["users"],
  },
  home: {
    contentKey: "home",
    path: RoutePath.Home,
    name: "Home",
    crumbs: [],
  },
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./defend-catalog-query-key-factory";

interface ICreateDefendCatalog {
  defenseCatalogCreateDto: any;
}

/**
 *
 * @param {DefenseCatalogCreateDto} defenseCatalogCreateDto creation dto
 * @returns id of created catalog
 */
export const createDefendCatalog = ({ defenseCatalogCreateDto }: ICreateDefendCatalog) => {
  return Brm.defenseCatalogApi.createDefenseCatalog({ defenseCatalogCreateDto });
};

export interface IUseCreateDefendCatalog {
  options?: MutationOption<typeof createDefendCatalog>;
}

/**
 * Custom hook to create a new exchange.
 *
 * @param {object} config - react-query mutation configuration
 * @returns react-query for useCreateDefendCatalog
 */
export const useCreateDefendCatalog = ({ options }: IUseCreateDefendCatalog = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.defendCatalogKeys.all);
    },
    ...options,
    mutationFn: createDefendCatalog,
  });
};

import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as globalDefenseKeyFactory from "../defense/defendCatalog";

export interface IImportDefendCatalog {
  defendCatalogId: string;
  file: any;
}

/**
 * Import a DefendCatalog
 *
 * @param {string} defendCatalogId - uuid of the defendCatalogId
 * @param {any} file - import context
 * @returns
 */
export const importDefendCatalog = async ({ defendCatalogId, file }: IImportDefendCatalog) => {
  return Brm.defendCatalogIoApi.d3fendCatalogImport(defendCatalogId, { file });
};

interface IUseDefendCatalog {
  options?: MutationOption<typeof importDefendCatalog>;
}

/**
 * Custom hook to import a defend catalog
 *
 *
 * @param {object} options - react-query options
 */
export const useImportDefendCatalog = ({ options }: IUseDefendCatalog = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: importDefendCatalog,
    onSuccess: () => {
      queryClient.invalidateQueries(globalDefenseKeyFactory.defendCatalogKeys.all);
    },
  });
};

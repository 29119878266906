import Title from "components/elements/StyledTitle";
import { DefenseApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { DefensiveTechniqueTabs } from "features/mitre-d3fend/components/tabs/DefensiveTechniqueTabs";

export const CciControl = () => {
  const { id } = useParams();

  const {
    data: cciControl,
    isError: isCciControlError,
    error: cciControlError,
  } = DefenseApi.useCciControl({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isCciControlError) {
    return <ErrorBanner msg={(cciControlError as any)?.statusText} />;
  }

  if (cciControl) {
    return (
      <>
        <Title>CCI Control: {cciControl.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{cciControl.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{cciControl.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{cciControl.sourceId}</div>
          <S.PropertyLabel>Parent</S.PropertyLabel>
          <div>{cciControl.parentname}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <DefensiveTechniqueTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};

import styled from "styled-components";

export const PropertyContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1rem;
`;

export const PropertyLabel = styled.div`
  font-weight: bold;

  &:after {
    content: ":";
  }
`;

import * as Recoil from "recoil";
import * as React from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
// Messenger
import messenger from "services/messenger";
// State
import { selectedInstanceState } from "atoms/atoms-content";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
// Hooks
import { SystemApi, useMoveNode, UrlSearchParams, systemModelStructureDirtyState } from "features/brm";
import { useCanRename } from "features/system-model";
import { queryClient } from "libs/react-query";

// Constants
import { TYPE } from "constants/brm";
import { SYSTEM_DIAGRAM_EVENTS } from "features/diagram-constants";

import {
  createColumnName,
  createColumnMapped,
  createColumnCategoryMapped,
  createColumnBooleanMapped,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnControlsCount,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import { stringToBoolean } from "utils/boolean-checker";

const CONTROL_TAB_INDEX = 7;
const tabIndex = `?${UrlSearchParams.Tab}=${CONTROL_TAB_INDEX}`;

const NodeTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const { moveNode } = useMoveNode();
  const variantId = Recoil.useRecoilValue(variantIdState);
  const projectId = Recoil.useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);
  const setSystemModelStructureDirty = Recoil.useSetRecoilState(systemModelStructureDirtyState);
  const { isSystemEngineer, isRiskAnalyst } = useRoles();

  const [editMode, setEditMode] = React.useState(false);
  const updatedName = React.useRef("");
  const updatedControlsCount = React.useRef("");
  const updatedIsInternal = React.useRef("");
  const updatedCategory = React.useRef("");
  const updatedStructCat = React.useRef("");
  const updatedParent = React.useRef("");

  const { data: nodesWithRisk, isError: isNodesWithRiskError } = SystemApi.useNodesWithRiskAndControls({
    projectId,
    variantId,
    options: {
      enabled: isRiskAnalyst && !!projectId && !!variantId,
    },
  });

  const { data: nodes, isError: isNodesError } = SystemApi.useNodes({
    projectId,
    options: { enabled: (isSystemEngineer || isRiskAnalyst) && !!projectId },
  });

  const { data: categoryList, isError: isCategoryListError } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst), staleTime: 5000 },
  });

  const { data: structCatList, isError: isStructCatListError } = SystemApi.useGetNodeStructuralCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { data: possibleParents } = SystemApi.useNodeParents({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { mutate: setNodeName } = SystemApi.useSetNodeName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting node name caused an error: ${err}`);
      },
    },
  });
  const { mutate: setNodeCategory } = SystemApi.useSetNodeCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting node category caused an error: ${err}`);
      },
    },
  });
  const { mutate: setNodeStructCategory } = SystemApi.useSetNodeStructuralCategory({
    options: {
      onSettled: () => {
        updatedStructCat.current = "";
      },
      onError: (err) => {
        toast.error(`Setting node structural category caused an error: ${err}`);
      },
    },
  });
  const { mutateAsync: setNodeParent } = SystemApi.useSetNodeParent();

  const createButton = React.useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: nodes })) return;
              setNodeName({ nodeId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              const isInternal = stringToBoolean(updatedIsInternal.current);
              moveNode({ nodeId: selectedInstance.id, parentId: null, isInternal }, { markSystemModelDirty: true });

              messenger.dispatch(SYSTEM_DIAGRAM_EVENTS.nodeParentChangedById, {
                nodeDataId: selectedInstance.id,
                isInternal,
                parentId: null,
              });
              updatedIsInternal.current = "";
            }

            if (updatedStructCat.current !== "") {
              setNodeStructCategory({ nodeId: selectedInstance.id, structCat: updatedStructCat.current });
            }

            if (updatedCategory.current !== "") {
              setNodeCategory({ nodeId: selectedInstance.id, category: updatedCategory.current });
            }

            if (updatedParent.current !== "") {
              try {
                await setNodeParent({
                  nodeId: selectedInstance.id,
                  parentId: updatedParent.current === "-" ? null : updatedParent.current,
                });
                setSystemModelStructureDirty(true);
                updatedParent.current = "";
              } catch (err) {
                toast.error(`Setting node parent caused an error: ${err}`);
              }
            }

            queryClient.invalidateQueries(SystemApi.nodeKeys.node(selectedInstance.id));
            queryClient.invalidateQueries(SystemApi.nodeKeys.project(projectId));
            // TODO should put these query-keys outside of system diagram for now just use the string
            queryClient.invalidateQueries(["SystemDiagramData"]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      selectedInstance.id,
      editMode,
      projectId,
      canRename,
      nodes,
      setNodeName,
      moveNode,
      setNodeStructCategory,
      setNodeCategory,
      setNodeParent,
      setSystemModelStructureDirty,
    ]
  );

  const checkNameUniqueness = React.useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(nodes)
        ? !nodes.map((node) => node.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, nodes]
  );

  const columns = React.useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "node"),
        createColumnMapped("category", selectedInstance.id, categoryList, false, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnMapped("structcat", selectedInstance.id, structCatList, false, updatedStructCat),
        createColumnMappedNoEdit("riskDistributionRank"),
        createColumnMappedNoEdit("riskDistributionPercent"),
        createColumnControlsCount(
          selectedInstance.id,
          setSelectedInstance,
          false,
          updatedControlsCount,
          "node",
          tabIndex
        ),
        createColumnMappedNoEdit("findingCount"),
        createColumnMappedNoEdit("cveFindingCount"),
      ];
    }

    if (isSystemEngineer) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "node", checkNameUniqueness, {
          Filter: RetainStringColumnFilter,
        }),
        createColumnCategoryMapped("category", selectedInstance.id, categoryList, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnCategoryMapped("structcat", selectedInstance.id, structCatList, editMode, updatedStructCat),
        createColumnMapped("parent", selectedInstance.id, possibleParents, editMode, updatedParent),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "node"),
      createColumnMapped("category", selectedInstance.id, categoryList, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnMapped("structcat", selectedInstance.id, structCatList, false, updatedStructCat),
      createColumnMapped("parent", selectedInstance.id, possibleParents, false, null),
    ];
  }, [
    isRiskAnalyst,
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    categoryList,
    structCatList,
    possibleParents,
    editMode,
    checkNameUniqueness,
    createButton,
  ]);

  if (isNodesWithRiskError || isNodesError || isCategoryListError || isStructCatListError) {
    return <ErrorBanner msg="Error while loading Node data." />;
  }

  if (nodesWithRisk && isRiskAnalyst) {
    return (
      <CustomTable>
        <BrmMainTable
          data={nodesWithRisk}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "NodeTable_table" }}
          elementName={TYPE.node}
          showRowSelect={isSystemEngineer}
        />
      </CustomTable>
    );
  }

  if (!isRiskAnalyst && nodes) {
    return (
      <CustomTable>
        <BrmMainTable
          data={nodes}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "NodeTable_table" }}
          elementName={TYPE.node}
          showRowSelect={isSystemEngineer}
        />
      </CustomTable>
    );
  }
  return <Loading />;
};

NodeTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default NodeTable;

import { BrmError, DetailTableType } from "features/brm";
import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

/**
 * returns list of all attackers
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of selected element
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of the variant
 * @returns list of attackers for any given selected element
 */
export const getElementAttackers = ({ elementId, elementName, projectId, variantId }) => {
  const attackersMap = {
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllAttacker(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllAttacker(id),
    [DetailTableType.SYSTEM_ASSET_TYPES.key]: (id) => Brm.systemAssetTypeApi.getSystemAssetTypeAllAttacker(id),
    [DetailTableType.SYSTEM_ASSETS.key]: (id) => Brm.systemAssetApi.getSystemAssetAllAttacker(id),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllAttacker(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventAllAttacker(id),
    [DetailTableType.ATTACK_SURFACES.key]: (id) => Brm.attackSurfaceApi.getAttackSurfaceAllAttacker(id),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllAttacker(id),
    [DetailTableType.VULNERABILITIES.key]: (id) => Brm.vulnerabilityApi.getVulnerabilityAllAttacker(id),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.getEntryPointAllAttacker(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointAllAttacker(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorAllAttacker(id),
    [DetailTableType.ATTACKER_CATEGORIES.key]: (id) => Brm.attackerApi.findAttacker(projectId, { cat: id }),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventAllAttacker(id),
    [DetailTableType.ALLOCATED_CONTROLS.key]: (id) => Brm.allocatedControlApi.getAllocatedControlAllAttacker(id),
    [DetailTableType.CONTROL_TYPES.key]: (id) =>
      Brm.controlTypeApi.getControlTypeAllAttacker(id, { variant: variantId }),
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackAttacker(id),
    [DetailTableType.THREAT_GROUPS.key]: (id) => Brm.threatGroupApi.getThreatGroupAllAttacker(id),
    // [DetailTableType.TTPS.key]: (id) => Brm.ttpApi.getTTPAllAttacker(id),
  };

  if (attackersMap[elementName]) {
    return attackersMap[elementName](elementId);
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
};

/**
 * custom-hook to retreive all attackers of any selected element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of variant
 * @param {object} config - configuration of react-query
 * @returns react-query for getting attackers
 */
export const useElementAttackers = ({ elementId, elementName, projectId, variantId, config = {} }) => {
  return useQuery({
    ...config,
    queryKey: ["elementAttackers", elementId, elementName],
    queryFn: () => getElementAttackers({ elementId, elementName, projectId, variantId }),
  });
};

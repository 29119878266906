import {
  DefenseCatalogApi,
  DefensiveTechniqueApi,
  DefensiveTacticApi,
  DigitalArtifactApi,
  DefensiveTripleApi,
  NistControlApi,
  CciControlApi,
} from "@kdmanalytics/brm-knowledgebase";
import BrmApiClient from "services/brm/BrmApiClient";

export const defenseCatalogApi = new DefenseCatalogApi(BrmApiClient.instance);
export const defensiveTechniqueApi = new DefensiveTechniqueApi(BrmApiClient.instance);
export const defensiveTacticApi = new DefensiveTacticApi(BrmApiClient.instance);
export const digitalArtifactApi = new DigitalArtifactApi(BrmApiClient.instance);
export const defensiveTripleApi = new DefensiveTripleApi(BrmApiClient.instance);
export const nistControlApi = new NistControlApi(BrmApiClient.instance);
export const cciControlApi = new CciControlApi(BrmApiClient.instance);

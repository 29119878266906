import { BrmError, DetailTableType } from "features/brm";
import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
// Services
import * as KeyFactory from "./element-query-key-factory";

interface IGetElementCveFindingsProps {
  elementId: string;
  elementName: string;
  projectId: string;
  elementCategory?: string | null;
  nodeId?: string | null;
  options: {};
}

interface IGetCveFindingsProps {
  elementId: string;
  elementName: string;
  projectId: string;
  elementCategory?: string | null;
  nodeId?: string | null;
}

interface ICveFindingsMap {
  [index: string]: (id?: string) => any;
}

async function getCveFindings({ elementId, elementName, projectId, elementCategory, nodeId }: IGetCveFindingsProps) {
  const CveFindingsMap: ICveFindingsMap = {
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeCvefinding(id),
    [DetailTableType.VULNERABILITIES.key]: () =>
      Brm.cveFindingsApi.findCveFinding(projectId, { category: elementCategory, performer: nodeId }),
    [DetailTableType.PROJECTS.key]: () => Brm.cveFindingsApi.findCveFinding(projectId),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllCvefinding(id),
  };

  if (CveFindingsMap[elementName]) {
    const data = await CveFindingsMap[elementName](elementId);
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
}

export const useGetElementCveFindings = ({
  elementId,
  elementName,
  elementCategory,
  projectId,
  nodeId,
  options = {},
}: IGetElementCveFindingsProps) => {
  return useQuery({
    ...options,
    queryKey: KeyFactory.elementKeys.cveFindings(elementId, elementName, projectId),
    queryFn: () => getCveFindings({ elementId, elementName, projectId, elementCategory, nodeId }),
  });
};

import * as ReactQuery from "@tanstack/react-query";
// import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { getTargetAsset } from "features/brm/api/threat/globalThreat/getTargetAsset";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IUseTargetAssetsByIds {
  targets: string[];
  options?: QueryOption<typeof getTargetAsset>;
}

/**
 * React-query hook for getting target assets
 *
 * @param {string} targets -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useTargetAssetsByIds = ({ targets, options }: IUseTargetAssetsByIds) => {
  return ReactQuery.useQueries({
    queries: targets.map((id: string) => ({
      queryKey: KeyFactory.targetAssets.target(id),
      queryFn: () => getTargetAsset({ id }),
      staleTime: Infinity,
      ...options,
    })),
  });
};

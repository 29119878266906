// import * as ReactQuery from "react-query";
// import * as Brm from "services/brm";
// import { MutationOption } from "libs/react-query";

// export interface IUploadAsset {
//   projectId: string;
//   context: string;
//   files: File[];
// }

// /**
//  * Uploads an asset
//  *
//  * @param {string} projectId - uuid of project
//  * @param {string} context - context of upload
//  * @param {any} file the file to upload
//  */
// export const uploadAsset = ({ projectId, context, files }: IUploadAsset) => {
//   return Brm.genericImportApi.importAssetUpload(context, projectId, { file: files });
// };

// interface IUseUploadAsset {
//   options?: MutationOption<typeof uploadAsset>;
// }

// /**
//  * Custom hook uploading an asset
//  *
//  * @param {object} options - react-query options
//  */
// export const useUploadAsset = ({ options }: IUseUploadAsset = {}) => {
//   return ReactQuery.useMutation({
//     ...options,
//     mutationFn: uploadAsset,
//   });
// };

import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import { ImportContext, ImportContextValues } from "../../constants";

export interface IUploadAsset {
  projectId: string;
  context: ImportContextValues;
  files: File[];
}
interface ImporterFunctionMap {
  [key: string]: any;
}
/**
 * Start processing an already uploaded submission
 *
 * @param {string} projectId - uuid of the project
 * @param {ContextValues} context - import context
 * @returns
 */
export const uploadAsset = async ({ projectId, context, files }: IUploadAsset) => {
  // console.log("uploading asset!!");
  const importer: ImporterFunctionMap = {
    [ImportContext.bor]: () => Brm.borImportApi.importAssetUploadBOR(projectId, { file: files }),
    [ImportContext.sbom]: () => Brm.scanBorImportApi.scanUpload(projectId, { file: files }),
    [ImportContext.baseline]: () =>
      Brm.baselineImportApi.importAssetUploadBaseline(projectId, {
        file: files,
      }),
    [ImportContext.savepoint]: () =>
      Brm.savePointImportApi.importAssetUploadSavepoint(projectId, {
        file: files,
      }),
    [ImportContext.sctm]: () => Brm.sctmImportApi.importAssetUploadSctm(projectId, { file: files }),
    [ImportContext.tra]: () => Brm.traImportApi.importAssetUploadTra(projectId, { file: files }),
    [ImportContext.system]: () => Brm.systemImportApi.importAssetUploadSystem(projectId, { file: files }),
  };

  if (!Object.keys(importer).some((k) => k === context)) {
    throw new Error(`Unsupported import context: ${context}`);
  }

  return importer[context]();
};

type MutationFunction = typeof uploadAsset;
interface IUseSubmission {
  options?: MutationOption<MutationFunction>;
}

/**
 * Custom hook uploading an asset
 *
 *
 * @param {object} options - react-query options
 */
export const useUploadAsset = ({ options }: IUseSubmission = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: uploadAsset,
  });
};

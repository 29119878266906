import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface ITargetAssetsProps {
  catalog?: string;
}
export const getTargetAssets = ({ catalog }: ITargetAssetsProps) => {
  return Brm.targetAssetApi.findTargetAsset({ catalog });
};

interface IUseTargetAssets {
  catalog?: string;
  options?: QueryOption<typeof getTargetAssets>;
}

/**
 * React-query hook for getting target assets
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useTargetAssets = ({ catalog, options }: IUseTargetAssets = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.offensiveTacticKeys.catalog(catalog),
    queryFn: () => getTargetAssets({ catalog }),
  });
};

import styled from "styled-components/macro";

export const ScanWizard = styled.div`
  .wizardTitle {
    text-align: center;
  }
`;

export const ScanNodeOptions = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 15px;
  justify-content: center;

  .row: {
    display: flex;

    flex-wrap: no-wrap;
    gap: 12px;
    justify-content: center;
    width: absolute;
  }

  .column: {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
  }
`;

// import * as React from "react";
// import { useState } from "react";
// import { useQueryClient } from "@tanstack/react-query";
// Components
// Styles
import Title from "components/elements/StyledTitle";
import { ThreatApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { ThreatSoftwareIdTabs } from "features/global-threat/components/tabs/ThreatSoftwareIdTabs";

export const ThreatSoftwareId = () => {
  const { id } = useParams();

  const {
    data: threatDataSource,
    isError: isThreatSoftwareIdError,
    error: threatDataSourceError,
  } = ThreatApi.useThreatSoftwareId({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isThreatSoftwareIdError) {
    return <ErrorBanner msg={(threatDataSourceError as any)?.statusText} />;
  }

  if (threatDataSource) {
    return (
      <>
        <Title>{threatDataSource.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{threatDataSource.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{threatDataSource.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{threatDataSource.sourceId}</div>
          <S.PropertyLabel>Parent</S.PropertyLabel>
          <div>{threatDataSource.parentName}</div>
          <S.PropertyLabel>Platform</S.PropertyLabel>
          <div>{threatDataSource.platform}</div>
          <S.PropertyLabel>Is Component</S.PropertyLabel>
          <div>{threatDataSource.isComponent ? "True" : "False"}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <ThreatSoftwareIdTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};
//  // createColumnMappedNoEdit("isComponent"),
//  createColumnMappedNoEdit("parentName"),
//  // createColumnMappedNoEdit("parent"),
//  // createColumnMappedNoEdit("platform"),
//  // createColumnMappedNoEdit("sourceId"),
//  // createColumnMappedNoEdit("sourceUrl"),

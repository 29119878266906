// Libs
import { useState, useMemo } from "react";
// import { useRecoilValue } from "recoil";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// import { LoadingSpinner as Loading } from "components/elements";
// State
// import { variantIdState } from "atoms/atoms-component";
// Constants
import { TYPE } from "constants/brm";
// Hooks
// import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
// Styles
import * as S from "brm/styles/details-table.styles";
// Queries
// import * as BrmGql from "generated/graphql";
// Local
// import { DetailTableType } from "features/brm";
// import { ExportTableModal, useExportTable } from "features/exporter";
import { RoutePath } from "routes/route-paths";

export const OffensiveTechniquesDetailsTable = ({ /* elementNameInUrl, selectedElement, */ tableTitle }: any) => {
  const { isThreatAnalyst } = useRoles();
  // const variantId = useRecoilValue(variantIdState);
  const [, setSelectedOffensiveTechnique] = useState([]);

  // const { isVisible, toggle } = useModal();
  // const { data: attackersData, isError: isGetOffensiveTechniqueDataError } = useElementOffensiveTechniques({
  //   elementId: selectedElement.id,
  //   elementName: elementNameInUrl,
  //   variantId,
  //   config: {
  //     enabled:
  //       !!selectedElement.id &&
  //       !!elementNameInUrl &&
  //       elementNameInUrl !== DetailTableType.RESOURCES.key &&
  //       elementNameInUrl !== DetailTableType.CAPABILITIES.key &&
  //       elementNameInUrl !== DetailTableType.PERSON.key &&
  //       elementNameInUrl !== DetailTableType.MISSIONS.key &&
  //       elementNameInUrl !== DetailTableType.DATATYPES.key &&
  //       elementNameInUrl !== DetailTableType.DATAFLOWS.key &&
  //       elementNameInUrl !== DetailTableType.ACTIVITIES.key,
  //   },
  // });
  // const {
  //   data: targetOffensiveTechniquesData,
  //   isError: isTargetOffensiveTechniquesDataError,
  //   error: targetOffensiveTechniquesDataError,
  // } = BrmGql.useGetTargetOffensiveTechniquesQuery(
  //   { target: selectedElement.id, variant: variantId },
  //   {
  //     enabled:
  //       !!selectedElement.id &&
  //       (elementNameInUrl === DetailTableType.CAPABILITIES.key ||
  //         elementNameInUrl === DetailTableType.PERSON.key ||
  //         elementNameInUrl === DetailTableType.MISSIONS.key ||
  //         elementNameInUrl === DetailTableType.DATATYPES.key ||
  //         elementNameInUrl === DetailTableType.RESOURCES.key ||
  //         elementNameInUrl === DetailTableType.DATAFLOWS.key ||
  //         elementNameInUrl === DetailTableType.ACTIVITIES.key),
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const columns = useMemo(
    () =>
      isThreatAnalyst
        ? [
            createColumnNameDetails(RoutePath.OffensiveTechnique.replace(":id", "")),
            createColumnMappedNoEdit("category"),
          ]
        : [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("category")],
    [isThreatAnalyst]
  );

  const data = useMemo(() => {
    const result: any[] = [];
    // if (attackersData) {
    //   result = Array.isArray(attackersData) ? attackersData : [attackersData];
    // } else if (targetOffensiveTechniquesData) {
    //   const res = targetOffensiveTechniquesData?.target?.all_attackers;
    //   result = Array.isArray(res) ? res : [res];
    // }
    return result;
  }, []);

  // if (isGetOffensiveTechniqueDataError) {
  //   return <ErrorBanner msg="Error while loading OffensiveTechniques" />;
  // }

  // if (isTargetOffensiveTechniquesDataError) {
  //   return <ErrorBanner msg={targetOffensiveTechniquesDataError?.message} />;
  // }

  // if (data) {
  return (
    <S.DetailsContainer id="OffensiveTechniqueTable_detailsPanel">
      {/* <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} /> */}
      <S.ActionContainer>
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={data}
            columns={columns}
            setSelectedElement={setSelectedOffensiveTechnique}
            customProps={{ id: "OffensiveTechniqueTable_detailsTable" }}
            showRowSelect={false}
            elementName={TYPE.attacker}
            tableTitle={tableTitle}
            setExportTableData={() => {}}
          />
        </S.DetailsTableContainer>
        {/* <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer> */}
      </S.ActionContainer>
    </S.DetailsContainer>
  );
  // }
  // return <Loading />;
};

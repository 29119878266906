import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./defend-catalog-query-key-factory";

export const getDefendCatalogs = () => {
  return Brm.defenseCatalogApi.findDefenseCatalog();
};

interface IUseDefendCatalogs {
  options?: QueryOption<typeof getDefendCatalogs>;
}

/**
 * React-query hook for getting all Threat Catalogs
 *
 * @param
 * @returns react-query for getDefendCatalogs
 */
export const useDefendCatalogs = ({ options }: IUseDefendCatalogs = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.defendCatalogKeys.all,
    queryFn: () => getDefendCatalogs(),
  });
};

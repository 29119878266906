import PropTypes from "prop-types";
import { useEffect, useState, useMemo } from "react";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { TYPE } from "constants/brm";
import { ThreatApi, useRoles } from "features/brm";

import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const ThreatTierTable = ({ setSelectedRows }) => {
  const { isThreatAnalyst } = useRoles();
  // console.log("userRole", userRole);

  const [threatTierData, setThreatTierData] = useState([]);
  const { data, error } = ThreatApi.useThreatTiers({ options: { enabled: isThreatAnalyst } });

  const columns = useMemo(() => [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("description")], []);

  useEffect(() => {
    if (data) {
      setThreatTierData(data.map((e) => ({ name: e })));
    }
  }, [data]);

  if (error) {
    return <ErrorBanner msg="Error while loading threat tiers" />;
  }

  if (data) {
    return (
      <CustomTable>
        <BrmMainTable
          data={threatTierData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "ThreatTierTable_table" }}
          elementName={TYPE.threatTier}
          showRowSelect={false}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

ThreatTierTable.propTypes = {
  setSelectedRows: PropTypes.any,
};

export default ThreatTierTable;

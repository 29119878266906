// Components
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
// Local
import AttackerTable from "brm/tables/details/AttackerTable";
import { DetailTableType } from "features/brm";
import { OffensiveTechniquesDetailsTable } from "features/global-threat/components/details-tables/OffensiveTechniquesDetailsTable";

export const ThreatGroupTabs = ({ selectedElement }: any) => {
  const elementNameInUrl = DetailTableType.THREAT_GROUPS.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Techniques</Tab>
        <Tab>Software</Tab>
        <Tab>Campaigns</Tab>
      </TabList>

      <TabPanel>
        <OffensiveTechniquesDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      <TabPanel>
        <AttackerTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      <TabPanel>
        <AttackerTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

import * as React from "react";
import * as Recoil from "recoil";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import PropTypes from "prop-types";
import CategorizedEntityTable from "brm/tables/details/CategorizedEntityTable";
// Services
// Local
import { useRoles } from "features/brm/hooks/useRoles";
import { nodeDetailTabIndexState } from "atoms/atoms-content";
import SystemAssetTable from "brm/tables/details/SystemAssetTable";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { DetailTableType } from "features/brm";

const systemMenu = [
  DetailTableType.ALL_DATA,
  DetailTableType.IN_DATA,
  DetailTableType.OUT_DATA,
  DetailTableType.IN_EXCHANGE,
  DetailTableType.OUT_EXCHANGE,
  DetailTableType.PARENT,
  DetailTableType.CHILDREN,
  // DetailTableType.MEMBER,
  // DetailTableType.GROUP,
  DetailTableType.RESOURCES,
  DetailTableType.ACTIVITIES,
  DetailTableType.DATAFLOWS,
  DetailTableType.PERSON,
  DetailTableType.CAPABILITIES,
  DetailTableType.MISSIONS,
  DetailTableType.STORED_DATA,
  DetailTableType.CHARACTERISTICS,
];

const attacksMenu = [
  DetailTableType.THREAT_EVENTS,
  DetailTableType.ATTACKS,
  DetailTableType.ATTACKERS,
  DetailTableType.MITRE_ATTACK,
];

const vulnerabilityMenu = [
  DetailTableType.VULNERABILITY_CONDITIONS,
  DetailTableType.VULNERABILITY_FINDINGS,
  DetailTableType.CVE_FINDINGS,
];

const accessMenu = [
  DetailTableType.ACCESS_POINTS,
  DetailTableType.ATTACK_SURFACES,
  DetailTableType.ATTACK_VECTORS,
  DetailTableType.ENTRY_POINTS,
];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS, DetailTableType.MITRE_D3FEND];

const riskMenu = [DetailTableType.RISKS, DetailTableType.DEP_ASSETS, DetailTableType.DEP_UNDESIRED_EVENTS];

const NodeDetails = ({ selectedElement, defaultTabIndex = 0, defaultSubTabIndex = 0 }) => {
  const setNodeTabIndex = Recoil.useSetRecoilState(nodeDetailTabIndexState);
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const [tabIndex, setTabIndex] = React.useState(defaultTabIndex);
  const elementNameInUrl = DetailTableType.NODES.key;
  const [selectedContext, setSelectedContext] = React.useState(DetailTableType.ALL_DATA.key);
  const [selectedAccessContext, setSelectedAccessContext] = React.useState(DetailTableType.ACCESS_POINTS.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(vulnerabilityMenu[defaultSubTabIndex].key);
  const [curr, setCurr] = React.useState("");

  React.useEffect(() => {
    setTabIndex(defaultTabIndex);
    setSelectedVulContext(vulnerabilityMenu[defaultSubTabIndex].key);
  }, [defaultTabIndex, defaultSubTabIndex]);

  const onSelect = (index) => {
    setTabIndex(index);
    setNodeTabIndex(index);
  };

  return (
    <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.CATEGORIZATION.name)}>{DetailTableType.CATEGORIZATION.name}</Tab>
        {(isRiskAnalyst || isSystemEngineer) && (
          <>
            <Tab onClick={() => setCurr(DetailTableType.ACCESS.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.ACCESS.name}
                menu={accessMenu}
                setSelectedContext={setSelectedAccessContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.SYSTEM_ASSETS.name)}>{DetailTableType.SYSTEM_ASSETS.name}</Tab>
          </>
        )}

        {isRiskAnalyst && (
          <>
            <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.RISK.name}
                menu={riskMenu}
                setSelectedContext={setSelectedRiskContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.ATTACK.name}
                menu={attacksMenu}
                setSelectedContext={setSelectedAttackContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.VULNERABILITIES.name}
                menu={vulnerabilityMenu}
                setSelectedContext={setSelectedVulContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.CONTROLS.name}
                menu={controlsMenu}
                setSelectedContext={setSelectedControlsContext}
                curr={curr}
              />
            </Tab>
          </>
        )}
      </TabList>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedContext}
        />
      </TabPanel>

      <TabPanel>
        <CategorizedEntityTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          tableTitle="Categorizations"
        />
      </TabPanel>

      {(isRiskAnalyst || isSystemEngineer) && (
        <>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedAccessContext}
            />
          </TabPanel>

          <TabPanel>
            <SystemAssetTable
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              tableTitle="System Assets"
            />
          </TabPanel>
        </>
      )}

      {isRiskAnalyst && (
        <>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              defaultTabIndex={defaultSubTabIndex}
              selectedContext={selectedRiskContext}
            />
          </TabPanel>

          {/* <RiskElementsTabPanel
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
            defaultTabIndex={defaultSubTabIndex}
            selectedContext={selectedRiskContext}
          /> */}
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedAttackContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedVulContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedControlsContext}
            />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
};

NodeDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  defaultTabIndex: PropTypes.number,
  defaultSubTabIndex: PropTypes.number,
};

export default NodeDetails;

// Libs
import { useState, useMemo } from "react";
// import { useRecoilValue } from "recoil";
// Utils
import {
  createColumnMappedNoEdit,
  createColumnNameDetails,
  createColumnBooleanMapped,
} from "brm/tables/services/column/columnFactory";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
// State
// import { variantIdState } from "atoms/atoms-component";
// Constants
import { TYPE } from "constants/brm";
// Hooks
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
// Styles
import * as S from "brm/styles/details-table.styles";
// Local
import { /* DetailTableType, */ ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { RoutePath } from "routes/route-paths";
import { useElementMitreAttack } from "features/mitre-attack/api/getElementMitreAttack";

interface IMitreAttackDetailsTableProps {
  elementNameInUrl: string;
  selectedElement: any;
  tableTitle: string;
}

export const MitreAttackDetailsTable = ({
  elementNameInUrl,
  selectedElement,
  tableTitle,
}: IMitreAttackDetailsTableProps) => {
  const { isThreatAnalyst } = useRoles();
  // const variantId = useRecoilValue(variantIdState);
  const [selectedAttacker, setSelectedAttacker] = useState([]);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();
  const { data: mitreAttackData, isError: isGetAttackerDataError } = useElementMitreAttack({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    // variantId,
    config: { enabled: !!selectedElement.id && !!elementNameInUrl },
  });

  const columns = useMemo(
    () =>
      isThreatAnalyst
        ? [createColumnNameDetails(RoutePath.Attacker.replace(":id", "")), createColumnMappedNoEdit("attackercat")]
        : [
            createColumnMappedNoEdit("name"),
            createColumnMappedNoEdit("descriptionThreat"),
            createColumnMappedNoEdit("detection"),
            createColumnBooleanMapped("isSubtechnique", selectedAttacker, false, null),
          ],
    [isThreatAnalyst, selectedAttacker]
  );

  const data = useMemo(() => {
    let result = null;
    if (mitreAttackData) {
      result = Array.isArray(mitreAttackData) ? mitreAttackData : [mitreAttackData];
    }
    return result;
  }, [mitreAttackData]);

  // console.log(data);

  if (isGetAttackerDataError) {
    return <ErrorBanner msg="Error while loading Attackers" />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="MitreAttackTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedAttacker}
              customProps={{ id: "MitreAttackTable_detailsTable" }}
              showRowSelect={false}
              elementName={TYPE.attacker}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

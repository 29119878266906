import AddIcon from "assets/icons/plus_tmp.svg";
import PrimaryButton from "components/elements/PrimaryStyledButton";
import ImgSized from "components/elements/ImgSized";
// Services
import { MouseEvent } from "react";

interface IAddButtonProps {
  onClick: (event: MouseEvent) => void;
  tooltip?: string;
  disabled?: boolean;
}

export const GenericAddButton = ({ onClick, tooltip = "Add", disabled = false }: IAddButtonProps) => {
  return (
    <PrimaryButton id="AddButton_button" onClick={onClick} disabled={disabled} title={tooltip}>
      <ImgSized id="AddButton_addIcon" src={AddIcon} alt="add" width="16" height="16" />
    </PrimaryButton>
  );
};

import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import PropTypes from "prop-types";
import SystemAssetTable from "brm/tables/details/SystemAssetTable";
import CategorizedEntityTable from "brm/tables/details/CategorizedEntityTable";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { CenterText } from "components/elements";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { DetailTableType } from "features/brm";

const systemMenu = [DetailTableType.TARGETS, DetailTableType.TOPIC];

const riskMenu = [
  DetailTableType.RISKS,
  { ...DetailTableType.DEP_ASSETS, isDisabled: true },
  DetailTableType.UNDESIRED_EVENTS,
];

const attacksMenu = [
  DetailTableType.THREAT_EVENTS,
  DetailTableType.ATTACKS,
  DetailTableType.ATTACKERS,
  DetailTableType.MITRE_ATTACK,
];

const vulnerabilityMenu = [
  DetailTableType.VULNERABILITY_CONDITIONS,
  { ...DetailTableType.VULNERABILITY_FINDINGS, isDisabled: true },
];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS, DetailTableType.MITRE_D3FEND];

const AssetDetails = ({ selectedElement, setRefresh }) => {
  const elementNameInUrl = DetailTableType.ASSETS.key;
  const [selectedSystemContext, setSelectedSystemContext] = React.useState(DetailTableType.TARGETS.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(DetailTableType.VULNERABILITY_CONDITIONS.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [curr, setCurr] = React.useState("");

  return (
    <Tabs>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedSystemContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.CATEGORIZATION.name)}>{DetailTableType.CATEGORIZATION.name}</Tab>
        <Tab onClick={() => setCurr(DetailTableType.ACCESS.name)}>{DetailTableType.ACCESS.name}</Tab>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM_ASSETS.name)}>{DetailTableType.SYSTEM_ASSETS.name}</Tab>

        <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.RISK.name}
            menu={riskMenu}
            setSelectedContext={setSelectedRiskContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.ATTACK.name}
            menu={attacksMenu}
            setSelectedContext={setSelectedAttackContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.VULNERABILITIES.name}
            menu={vulnerabilityMenu}
            setSelectedContext={setSelectedVulContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.CONTROLS.name}
            menu={controlsMenu}
            setSelectedContext={setSelectedControlsContext}
            curr={curr}
          />
        </Tab>
      </TabList>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedSystemContext}
        />
      </TabPanel>

      <TabPanel>
        <CategorizedEntityTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>

      <TabPanel>
        <CenterText msg="No Access Data Found." />;
      </TabPanel>

      <TabPanel>
        <SystemAssetTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          showAdd={false}
          showDelete={false}
          setRefresh={setRefresh}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedRiskContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedAttackContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedVulContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedControlsContext}
        />
      </TabPanel>
    </Tabs>
  );
};

AssetDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AssetDetails;

import { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
// State
import { useRecoilState, useRecoilValue } from "recoil";
import { factAnalysisReportState } from "atoms/atoms-report";
import { userIdState } from "atoms/atoms-admin";
// Styles
import styled from "styled-components";
// Constatns
import { SYSTEM_MODEL_ANALYTICS, SYSTEM_ELEMENT, ERROR, REPORT_LEVEL } from "constants/brm";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { LoadingSpinner } from "components/elements";
import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";

// Utils
import { handleNoteInTooltip, formattedTimeStamp } from "utils/report-utils";
import { createColumnMappedNoEditRpt, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Hooks
import { useModal } from "hooks";
import useSioService from "hooks/useSioService";
import * as S from "brm/styles/details-table.styles";

const FactAnalysisRptTable = ({ selectedElement, tableTitle }) => {
  // Global state
  const [factAnalysisReport, setFactAnalysisReport] = useRecoilState(factAnalysisReportState);

  const userId = useRecoilValue(userIdState);

  // Local State
  const socket = useRef(null);
  const [sio, isConnected, sioError] = useSioService(selectedElement.project);
  socket.current = sio;

  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  // Leave in row selector so user can still highlight rows in the report while viewing
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [errMsg, setErrMsg] = useState(``);
  const [done1, setDone1] = useState(true);
  const [done2, setDone2] = useState(true);
  const [done3, setDone3] = useState(true);
  const [done4, setDone4] = useState(true);
  const [done5, setDone5] = useState(true);
  const [done6, setDone6] = useState(true);
  const [done7, setDone7] = useState(true);
  const [done8, setDone8] = useState(true);
  const [done9, setDone9] = useState(true);
  const [done10, setDone10] = useState(true);

  const analysisResults = useRef([]);
  const progMsg1 = useRef("");
  const progMsg2 = useRef("");
  const progMsg3 = useRef("");
  const progMsg4 = useRef("");
  const progMsg5 = useRef("");
  const progMsg6 = useRef("");
  const progMsg7 = useRef("");
  const progMsg8 = useRef("");
  const progMsg9 = useRef("");
  const progMsg10 = useRef("");

  const columns = useMemo(
    () => [
      createColumnMappedNoEditRpt("elementName", handleNoteInTooltip),
      createColumnMappedNoEdit("elementType"),
      createColumnMappedNoEdit("levelNoVal"),
      createColumnMappedNoEditRpt("description", handleNoteInTooltip),
    ],
    []
  );

  useEffect(() => {
    if (factAnalysisReport.summary) {
      const progMsgs = factAnalysisReport.summary.split(", ");
      [
        progMsg1.current,
        progMsg2.current,
        progMsg3.current,
        progMsg4.current,
        progMsg5.current,
        progMsg6.current,
        progMsg7.current,
        progMsg8.current,
        progMsg9.current,
        progMsg10.current,
      ] = progMsgs;
    }
  }, [factAnalysisReport.summary]);

  /**
   * Analysis Results Handlers
   *
   * Each element type receives its own _prog msg and the _done msg is global and sent only
   * once all of the _progs have been sent.
   */
  useEffect(() => {
    function doWork() {
      setInProgress(true);
      progMsg1.current = "data types";
      progMsg2.current = "data flows";
      progMsg3.current = "capabilities";
      progMsg4.current = "activities";
      progMsg5.current = "links";
      progMsg6.current = "buses";
      progMsg7.current = "persons";
      progMsg8.current = "exchanges";
      progMsg9.current = "nodes";
      progMsg10.current = "missions";
      setDone1(false);
      setDone2(false);
      setDone3(false);
      setDone4(false);
      setDone5(false);
      setDone6(false);
      setDone7(false);
      setDone8(false);
      setDone9(false);
      setDone10(false);

      socket.current.sendReq(SYSTEM_MODEL_ANALYTICS, {
        levels: [REPORT_LEVEL.error, REPORT_LEVEL.warn, REPORT_LEVEL.structural, REPORT_LEVEL.unused],
        elements: {
          [`${SYSTEM_ELEMENT.activity}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.datatype}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.dataFlow}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.capability}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.link}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.bus}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.person}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.exchange}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.node}`]: { list: [], notIncluded: true },
          [`${SYSTEM_ELEMENT.mission}`]: { list: [], notIncluded: true },
        },
        userId,
        projectId: selectedElement.project,
      });

      socket.current.waitProg(SYSTEM_MODEL_ANALYTICS, (data) => {
        // console.log(`waitProg - info from backend- ${SYSTEM_MODEL_ANALYTICS} - data: `, data);

        // Adjust progress indicator for element type
        switch (data.elementType) {
          case SYSTEM_ELEMENT.datatype:
            setDone1(true);
            progMsg1.current = `${data.numInsts} ${data.elementType}`;
            break;
          case SYSTEM_ELEMENT.dataFlow:
            setDone2(true);
            progMsg2.current = `${data.numInsts} ${data.elementType}`;
            break;
          case SYSTEM_ELEMENT.capability:
            setDone3(true);
            progMsg3.current = `${data.numInsts} ${data.elementType}`;
            break;
          case SYSTEM_ELEMENT.activity:
            setDone4(true);
            progMsg4.current = `${data.numInsts} ${data.elementType}`;
            break;
          /* Enable when links and buses are used */
          // case SYSTEM_ELEMENT.link:
          //   setDone5(true);
          //   progMsg5.current = `${data.numInsts} ${data.elementType}`;
          //   break;
          // case SYSTEM_ELEMENT.bus:
          //   setDone6(true);
          //   progMsg6.current = `${data.numInsts} ${data.elementType}`;
          //   break;
          case SYSTEM_ELEMENT.person:
            setDone7(true);
            progMsg7.current = `${data.numInsts} ${data.elementType}`;
            break;
          case SYSTEM_ELEMENT.exchange:
            setDone8(true);
            progMsg8.current = `${data.numInsts} ${data.elementType}`;
            break;
          case SYSTEM_ELEMENT.node:
            setDone9(true);
            progMsg9.current = `${data.numInsts} ${data.elementType}`;
            break;
          case SYSTEM_ELEMENT.mission:
            setDone10(true);
            progMsg10.current = `${data.numInsts} ${data.elementType}`;
            break;
          default:
            console.error(`waitProg - switch - ${SYSTEM_MODEL_ANALYTICS} - unknown element type: ${data.elementType}`);
            return;
        }

        // Update the table with the latest results received from backend analysis
        analysisResults.current = [...analysisResults.current, ...data.msg.analysisResults];
      });
      socket.current.waitResp(SYSTEM_MODEL_ANALYTICS, () => {
        // console.log(`waitResp ${SYSTEM_MODEL_ANALYTICS} - data: `, data);

        // Capture the final report
        setFactAnalysisReport({
          data: analysisResults.current,
          summary:
            `${progMsg1.current}, ${progMsg2.current}, ${progMsg3.current}, ${progMsg4.current}, ${progMsg5.current}, ` +
            `${progMsg6.current}, ${progMsg7.current}, ${progMsg8.current}, ${progMsg9.current}, ${progMsg10.current}`,
          timestamp: formattedTimeStamp(),
          count: analysisResults.current.length.toLocaleString(),
        });

        setInProgress(false);
      });
      socket.current.waitErr(SYSTEM_MODEL_ANALYTICS, (data) => {
        // console.log(`waitErr ${SYSTEM_MODEL_ANALYTICS} - data.msg: `, data.msg);

        // For now, indicate error message in first item message area
        progMsg1.current = data.msg;
        setGeneralError(true);
      });
    }
    if (isConnected) {
      setGeneralError(false);
      analysisResults.current = [];
      doWork();
    }
    if (isConnected && sioError) {
      setErrMsg(`${ERROR.userAuth}  (${sioError.message})`);
      setGeneralError(true);
    }
  }, [isConnected, sioError, setGeneralError, setErrMsg, setFactAnalysisReport, userId, selectedElement.project]);

  return (
    <S.DetailsContainer id="FactAnalysisRptTable_detailsPanel">
      <LeftMsgArea>
        <>
          {inProgress ? (
            <ProgressArea>
              {generalError ? (
                <Prog>
                  <ProgMsg>{errMsg}</ProgMsg>
                  <DoneMsg />
                </Prog>
              ) : (
                <>
                  <Prog>
                    <ProgMsg>{progMsg1.current}</ProgMsg>
                    <DoneMsg>{done1 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg2.current}</ProgMsg>
                    <DoneMsg>{done2 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg3.current}</ProgMsg>
                    <DoneMsg>{done3 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg4.current}</ProgMsg>
                    <DoneMsg>{done4 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg5.current}</ProgMsg>
                    <DoneMsg>{done5 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg6.current}</ProgMsg>
                    <DoneMsg>{done6 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg7.current}</ProgMsg>
                    <DoneMsg>{done7 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg8.current}</ProgMsg>
                    <DoneMsg>{done8 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg9.current}</ProgMsg>
                    <DoneMsg>{done9 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                  <Prog>
                    <ProgMsg>{progMsg10.current}</ProgMsg>
                    <DoneMsg>{done10 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                  </Prog>
                </>
              )}
            </ProgressArea>
          ) : (
            <>
              {generalError ? (
                <Prog>
                  <ProgMsg>{errMsg}</ProgMsg>
                  <DoneMsg />
                </Prog>
              ) : (
                <Amt />
              )}
            </>
          )}
        </>
        <Updated>Last updated: {factAnalysisReport.timestamp}</Updated>
      </LeftMsgArea>
      <RightMsgArea />
      <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
      <S.ActionContainer>
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={factAnalysisReport.data}
            columns={columns}
            setSelectedRowIndex={setSelectedRowIndex}
            selectedRowIndex={selectedRowIndex}
            showRowSelect={false}
            tableTitle={tableTitle}
            setExportTableData={setExportTableData}
          />
        </S.DetailsTableContainer>
        <S.DetailButtonTopCorner>
          <ExportButton onClick={() => toggle()} disableExport={disableExport} />
        </S.DetailButtonTopCorner>
      </S.ActionContainer>
    </S.DetailsContainer>
  );
};

FactAnalysisRptTable.propTypes = {
  selectedElement: PropTypes.object,
  tableTitle: PropTypes.string,
};

const LeftMsgArea = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;
const Amt = styled.div`
  justify-self: start;
`;

const ProgressArea = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  grid-column-gap: 1em;
`;

const Prog = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.1em;
`;

const ProgMsg = styled.div`
  justify-self: end;
`;

const DoneMsg = styled.div`
  justify-self: start;
`;

const Updated = styled.div`
  justify-self: end;
`;

const RightMsgArea = styled.div``;

export default FactAnalysisRptTable;

// Components
import systemModals from "components/modals/Modals";

interface IAddAllocatedDigitalArtifactsDialog {
  isVisible: boolean;
  toggle: () => void;
}

export const AddAllocatedDigitalArtifactsDialog = ({ isVisible, toggle }: IAddAllocatedDigitalArtifactsDialog) => {
  return (
    <>
      {isVisible
        ? systemModals.customModal(
            "AddAllocatedDigitalArtifactDialog",
            isVisible,
            toggle,
            <div>Something needs to go here</div>,
            "Add Allocated Digital Artifact"
          )
        : null}
    </>
  );
};

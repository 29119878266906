import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { controlFamilyApi } from "services/brm/global-control-service";
import { sctmIdState, totalAddedControlsState } from "atoms/atoms-mitigation";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilState, useSetRecoilState } from "recoil";
import { sortByLabel } from "utils/sorting";
import Select from "react-select";
import SelectAll from "components/forms/SelectAllReactSelect";
import { DEFAULT_CATALOG_NAME, DEFAULT_CATALOG_STANDARD, FORM_LABEL } from "constants/brm";
import { SystemApi, GlobalControlApi, useRoles } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import {
  useCreateAllocatedControl,
  useBaselineControlTypes,
  useSctmAllocatedControls,
  useSctmBaseline,
} from "../../api";

export const AddAllocatedControlForm = ({ setModalIsOpen, sctmName }) => {
  const { isRiskAnalyst, isControlCoordinator } = useRoles();
  const setTotalAddedControls = useSetRecoilState(totalAddedControlsState);
  const [postError, setPostError] = useState("");
  const [sctmId] = useRecoilState(sctmIdState);
  const [projectId] = useRecoilState(projectIdState);
  const [baseline, setBaseline] = useState({});
  const [controlTypeOptions, setControlTypeOptions] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedExchanges, setSelectedExchanges] = useState([]);
  const [selectedControlTypes, setSelectedControlTypes] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState({});
  const [previousControls, setPreviousControls] = useState([]);
  const NOUNS = {
    allocatedcontrol: "allocatedcontrol",
    sctm: "SCTM",
  };
  const [defaultCntrlCatalog, setDefaultCntrlCatalog] = useState("");

  // Query
  const { mutate: createAC } = useCreateAllocatedControl(setModalIsOpen, sctmId);
  const { data: cntrlCatalogs } = GlobalControlApi.useControlCatalogs({
    options: { enabled: isRiskAnalyst, isControlCoordinator },
  });
  const { data: cntrlCatalogFamilies } = GlobalControlApi.useControlFamilies({
    controlCatalogId: defaultCntrlCatalog?.id,
    options: { enabled: !!defaultCntrlCatalog?.id },
  });
  const { data: nodeslist } = SystemApi.useNodes({ projectId, options: { enabled: !!projectId } });
  const { data: exchangeList } = SystemApi.useExchanges({ projectId, options: { enabled: !!projectId } });
  const { data: allocatedControls } = useSctmAllocatedControls({ sctmId, config: { enabled: !!sctmId } });
  const { data: sctmBaseline } = useSctmBaseline({
    sctmId,
    config: { enabled: !!sctmId },
  });
  const { data: cntrlTypes } = useBaselineControlTypes({
    baselineId: sctmBaseline?.id,
    config: { enabled: !!sctmBaseline?.id },
  });

  function getReactSelectArray(array) {
    const objects = [];
    array.forEach((obj) => {
      objects.push({
        value: obj.id,
        label: obj.name,
      });
    });
    return objects.sort(sortByLabel);
  }

  // set default NIST 800-53 control catalog
  useEffect(() => {
    if (cntrlCatalogs) {
      const nistCatalog = cntrlCatalogs.filter(
        (e) => e.name === DEFAULT_CATALOG_NAME.name_5 && e.standard === DEFAULT_CATALOG_STANDARD.standard_5
      );
      setDefaultCntrlCatalog(nistCatalog[0]);
    }
  }, [cntrlCatalogs]);

  useEffect(() => {
    if (allocatedControls) {
      setPreviousControls(allocatedControls);
    }
  }, [allocatedControls]);

  useEffect(() => {
    if (sctmBaseline) {
      setBaseline(sctmBaseline);
    }
  }, [sctmBaseline]);

  useEffect(() => {
    if (cntrlTypes) {
      setControlTypeOptions(getReactSelectArray(cntrlTypes));
    }
  }, [cntrlTypes]);

  async function handleSubmit(e) {
    e.preventDefault();
    let prevValues = [];
    let allocatedcontrol = [];
    if (selectedControlTypes.length > 0) {
      if (selectedExchanges.length > 0 || selectedNodes.length > 0) {
        allocatedcontrol = await Promise.all(
          selectedControlTypes.map((selectedCT) => {
            let nodeObj = [];
            let exchangeObj = [];
            if (selectedNodes.length > 0) {
              nodeObj = selectedNodes.map((node) => {
                return {
                  noun: NOUNS.allocatedcontrol,
                  name: `${selectedCT.label}@${node.label}`,
                  id: `${selectedCT.label}`,
                  object: `${node.label}`,
                  controltype: `${selectedCT.label}`,
                  isCompliant: false,
                  isImported: false,
                };
              });
            }

            if (selectedExchanges.length > 0) {
              exchangeObj = selectedExchanges.map((exchange) => {
                return {
                  noun: NOUNS.allocatedcontrol,
                  name: `${selectedCT.label}@${exchange.label}`,
                  id: `${selectedCT.label}`,
                  object: `${exchange.label}`,
                  controltype: `${selectedCT.label}`,
                  isCompliant: false,
                  isImported: false,
                };
              });
            }

            return [...nodeObj, ...exchangeObj];
          })
        );

        prevValues = previousControls.map((each) => {
          return {
            noun: NOUNS.allocatedcontrol,
            name: each.name,
            id: each.id,
            object: each.anobject.name,
            controltype: each.controltype.name,
            isCompliant: each.isCompliant,
          };
        });

        const finalControls = [...prevValues, ...allocatedcontrol.flat()];

        setTotalAddedControls(finalControls.length);
        const schema = {
          noun: NOUNS.sctm,
          name: sctmName,
          allocatedcontrol: finalControls,
          baseline: baseline.name,
          note: "",
          isImported: false,
        };

        try {
          const opts = JSON.stringify(schema);
          createAC({ opts });
        } catch (err) {
          console.error("error in creating sctm import", err);
        }
      } else {
        setPostError("*Minimum one Node/Exchange is required to add controls");
      }
    } else {
      setPostError("No Control Types have been selected.");
    }
  }

  const handleNodeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedNodes(items);
  };

  const handleExchangeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedExchanges(items);
  };

  const handleControlTypeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedControlTypes(items);
  };

  const handleControlFamilySelectChange = async (selectedItems) => {
    const items = selectedItems || {};
    setSelectedFamily(items);

    // get control types by family
    try {
      const resControls = await controlFamilyApi.getControlFamilyControlWithHttpInfo(items.value);
      setSelectedControlTypes([]);
      setControlTypeOptions(getReactSelectArray(resControls.data));
    } catch (err) {
      console.error(err);
    }
  };

  if (nodeslist && exchangeList && cntrlCatalogFamilies && sctmBaseline) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <p>
                Currently selected baseline: <strong>{sctmBaseline.name}</strong>
              </p>
              <label id="AllocatedControlAddForm_controlFamilyLabel">{FORM_LABEL.controlFamily}</label>
              <Select
                isMulti={false}
                options={getReactSelectArray(cntrlCatalogFamilies)}
                defaultValue={selectedFamily}
                onChange={handleControlFamilySelectChange}
                id="AllocatedControlAddForm_controlFamilyDropdown"
                classNamePrefix="controlFamilyDropdown"
              />
              <br />
              <label id="AllocatedControlAddForm_controlTypeLabel">{FORM_LABEL.controlTypes}</label>
              <SelectAll
                isMulti
                options={controlTypeOptions}
                defaultValue={selectedControlTypes}
                value={selectedControlTypes}
                onChange={handleControlTypeSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AllocatedControlAddForm_controlType"
              />
              <br />
              <label id="AllocatedControlAddForm_nodeLabel">{FORM_LABEL.nodes}</label>
              <SelectAll
                isMulti
                options={getReactSelectArray(nodeslist)}
                defaultValue={selectedNodes}
                value={selectedNodes}
                onChange={handleNodeSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AllocatedControlAddForm_nodes"
              />
              <br />
              <label id="AllocatedControlAddForm_linkLabel">{FORM_LABEL.exchanges}</label>
              <SelectAll
                isMulti
                options={getReactSelectArray(exchangeList)}
                defaultValue={selectedExchanges}
                value={selectedExchanges}
                onChange={handleExchangeSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AllocatedControlAddForm_Exchanges"
              />
              <br />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AllocatedControlAddForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            {sctmBaseline.name ? (
              <>
                <DialogButtonStyled type="submit" id="AllocatedControlAddForm_addButton">
                  Add
                </DialogButtonStyled>
              </>
            ) : (
              <></>
            )}
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <Loading />;
};

AddAllocatedControlForm.propTypes = {
  setModalIsOpen: PropTypes.func,
  sctmName: PropTypes.string.isRequired,
};

import * as React from "react";
import { DefenseApi } from "features/brm";

export const useAddAllocatedDigitalArtifactForm = () => {
  const [selectedDas, setSelectedDas] = React.useState<any[]>([]);
  const [postError, setPostError] = React.useState("");
  const { data: daList, isError: isErrorDaList } = DefenseApi.useDigitalArtifacts();

  return {
    selectedDas,
    postError,
    daList,
    isErrorDaList,
    setSelectedDas,
    setPostError,
  };
};

import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IAddSystemAssetTypeDigitalArtifactProps {
  saTypeId: string | null;
  daId: string | null;
}

/**
 * Add a digital artifact to a saType
 *
 * @returns
 */
export const addSystemAssetTypeDigitalArtifact = ({ saTypeId, daId }: IAddSystemAssetTypeDigitalArtifactProps) => {
  return Brm.systemAssetTypeApi.addSystemAssetTypeDigitalartifact(saTypeId, { body: daId });
};

interface IAddSystemAssetTypeDigitalArtifactQueryProps {
  options?: MutationOption<typeof addSystemAssetTypeDigitalArtifact>;
}

/**
 * Custom hook to add a digital artifact to a saType
 *
 * @returns react-query Mutation.
 */
export const useAddSystemAssetTypeDigitalArtifact = ({
  options,
}: IAddSystemAssetTypeDigitalArtifactQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetTypeKeys.all);
    },
    ...options,
    mutationFn: addSystemAssetTypeDigitalArtifact,
  });
};

// import * as React from "react";
// import { useState } from "react";
// import { useQueryClient } from "@tanstack/react-query";
// Components
// Styles
import Title from "components/elements/StyledTitle";
import { ThreatApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { AffectedPlatformTabs } from "features/global-threat/components/tabs/AffectedPlatformTabs";

export const AffectedPlatform = () => {
  const { id } = useParams();

  const {
    data: affectedPlatform,
    isError: isAffectedPlatformError,
    error: affectedPlatformError,
  } = ThreatApi.useAffectedPlatform({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isAffectedPlatformError) {
    return <ErrorBanner msg={(affectedPlatformError as any)?.statusText} />;
  }

  if (affectedPlatform) {
    return (
      <>
        <Title>Affected Platform: {affectedPlatform.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{affectedPlatform.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{affectedPlatform.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{affectedPlatform.sourceId}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <AffectedPlatformTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};

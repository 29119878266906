import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IOffensiveTechniquesProps {
  id: string;
}

export const getOffensiveTechnique = ({ id }: IOffensiveTechniquesProps) => {
  return Brm.offensiveTechniqueApi.getOffensiveTechniqueByID(id);
};

interface IUseOffensiveTechnique {
  id: string;
  options?: QueryOption<typeof getOffensiveTechnique>;
}

/**
 * React-query hook for getting offensive techniques
 *
 * @param {string} id -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useOffensiveTechnique = ({ id, options }: IUseOffensiveTechnique) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.offensiveTechniques.technique(id),
    queryFn: () => getOffensiveTechnique({ id }),
  });
};

import Title from "components/elements/StyledTitle";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import { ThreatApi } from "features/brm";
import { useParams } from "react-router-dom";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { OffensiveTacticTabs } from "features/global-threat/components/tabs/OffensiveTacticTabs";

export const OffensiveTactic = () => {
  const { id } = useParams();

  const {
    data: offensiveTactic,
    isError: isOffensiveTacticError,
    error: offensiveTacticError,
  } = ThreatApi.useOffensiveTactic({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isOffensiveTacticError) {
    return <ErrorBanner msg={(offensiveTacticError as any)?.statusText} />;
  }

  if (offensiveTactic) {
    return (
      <>
        <Title>Offensive Tactic: {offensiveTactic.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{offensiveTactic.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{offensiveTactic.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{offensiveTactic.sourceId}</div>
          <S.PropertyLabel>Source URL</S.PropertyLabel>
          <div>{offensiveTactic.sourceURL}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <OffensiveTacticTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }
  return <Loading />;
};

import * as React from "react";

import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import {
  UploadButton,
  LoadingSpinner as Loading,
  ErrorBanner,
  DownloadButton,
  ButtonSpacer,
  ButtonWrapper,
} from "components/elements";

import PropTypes from "prop-types";
import { TYPE, COMMON } from "constants/brm";
import { userRoles } from "services/brm/admin-service";
import { userRoleState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import GenericModal from "components/modals/GenericModal";
import ButtonCell from "components/EditComponents/ButtonCell";
import { AdminApi, ThreatApi } from "features/brm";

import {
  createColumnName,
  createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

const threatAnalystRole = userRoles.properties[userRoles.THREAT_ANALYST].key;

const ThreatCatalogTable = ({ setSelectedRows, setUploadModalIsOpen, setIsExportModalOpen }) => {
  const userRole = useRecoilValue(userRoleState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const updatedName = React.useRef("");

  const { data: selfData } = AdminApi.useSelf({ options: {} });
  const { data, error } = ThreatApi.useThreatCatalogs();
  const { mutate: setThreatCatalogName } = ThreatApi.useSetThreatCatalogName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
    },
  });

  const createButton = React.useCallback(
    (cellProps) => {
      const currentRow = cellProps.cell.row.original;
      const exportButton = (
        <DownloadButton
          onClick={() => {
            setSelectedInstance(currentRow);
            setIsExportModalOpen(true);
          }}
        />
      );

      if (userRole === threatAnalystRole && selfData) {
        const isReadOnly =
          !(selfData.organization === COMMON.defaultUuid && currentRow.organization.id === COMMON.defaultUuid) ||
          selfData.organization === currentRow.organization.id;

        if (currentRow.id === COMMON.defaultUuid) {
          return (
            <ButtonWrapper>
              <ButtonSpacer />
              {exportButton}
              <ButtonSpacer />
            </ButtonWrapper>
          );
        }
        return (
          <ButtonWrapper>
            {isReadOnly ? (
              <ButtonSpacer />
            ) : (
              <ButtonCell
                selectedRowId={selectedInstance.id}
                elementId={cellProps.cell.row.original.id}
                handleConfirmEditClick={async () => {
                  setEditMode(false);
                  // TODO: Throw an error if updatedName.current is empty, i.e. if the new string doesn't meet the regex condition inputString.match(/^[\w\-\s]+$/) in src\components\EditComponents\EditField.jsx
                  if (updatedName.current !== "") {
                    setThreatCatalogName({ threatCatalogId: selectedInstance.id, name: updatedName.current });
                  }
                }}
                setEditMode={setEditMode}
                editMode={editMode}
              />
            )}
            {exportButton}
            {(selfData.organization === COMMON.defaultUuid && currentRow.organization.id === COMMON.defaultUuid) ||
            selfData.organization === currentRow.organization.id ? (
              <UploadButton onClick={() => setUploadModalIsOpen(true)} />
            ) : null}
            {/* 
            <UploadButton
              onClick={() => {
                setUploadModalIsOpen(true);
              }}
            /> */}
          </ButtonWrapper>
        );
      }
      return COMMON.na;
    },
    [
      userRole,
      selfData,
      selectedInstance.id,
      editMode,
      setThreatCatalogName,
      setSelectedInstance,
      setIsExportModalOpen,
      setUploadModalIsOpen,
    ]
  );

  const columns = React.useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "threatCatalog"),
      createColumnMappedNoEdit("org"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, editMode, createButton]
  );

  const hideCheckBoxCondition = React.useCallback(
    (row) => {
      if (selfData) {
        // hide if we are the builtin catalog && we aren't in administration org...
        return row.original.organization.id === COMMON.defaultUuid && selfData.organization !== COMMON.defaultUuid;
      }
      return true;
    },
    [selfData]
  );

  if (error) {
    return <ErrorBanner msg="Error while loading threat catalogs" />;
  }

  if (data) {
    return (
      <>
        {alertOpen && (
          <GenericModal
            elementId="ThreatCatalogTable_alertModal"
            modalIsOpen={alertOpen}
            setModalIsOpen={setAlertOpen}
            headerText="Message"
            bodyText="Please select a Threat Catalog."
          />
        )}

        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ThreatCatalogTable_table" }}
            elementName={TYPE.threatCatalog}
            hideCheckBoxCondition={hideCheckBoxCondition}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

ThreatCatalogTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setUploadModalIsOpen: PropTypes.func.isRequired,
  setIsExportModalOpen: PropTypes.func.isRequired,
};

export default ThreatCatalogTable;

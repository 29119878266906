// import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { CveFidingTable } from "./tables/CveFidingTable";

export const CveFinding = () => {
  const cveHeader = <Title id="CveFiding_title">CVE Findings</Title>;

  const cveMain = <CveFidingTable />;

  return <ContentLayout pageId="CveFiding_page" header={cveHeader} main={cveMain} />;
};

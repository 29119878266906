export class DetailTableType {
  static values: DetailTableType[] = [];

  static readonly ACTIVITIES = new DetailTableType("Activities", "activities");

  static readonly CHARACTERISTICS = new DetailTableType("Characteristics", "characteristics");

  static readonly CAPABILITIES = new DetailTableType("Capabilities", "capabilities");

  static readonly CHILDREN = new DetailTableType("Children", "children");

  static readonly DATA = new DetailTableType("Data", "data");

  static readonly DATATYPES = new DetailTableType("Data Types", "datatypes");

  static readonly DATAFLOWS = new DetailTableType("Dataflows", "dataFlows");

  static readonly ALL_DATA = new DetailTableType("All Data", "allData");

  static readonly IN_DATA = new DetailTableType("In Data", "inData");

  static readonly OUT_DATA = new DetailTableType("Out Data", "outData");

  static readonly STORED_DATA = new DetailTableType("Stored Data", "storedData");

  static readonly EXCHANGES = new DetailTableType("Exchanges", "exchanges");

  static readonly IN_EXCHANGE = new DetailTableType("In Exchanges", "inExchanges");

  static readonly OUT_EXCHANGE = new DetailTableType("Out Exchanges", "outExchanges");

  static readonly GROUP = new DetailTableType("Groups", "groups");

  static readonly MEMBER = new DetailTableType("Members", "members");

  static readonly MISSIONS = new DetailTableType("Missions", "missions");

  static readonly NEXT = new DetailTableType("Next", "next");

  static readonly NODES = new DetailTableType("Nodes", "nodes");

  static readonly ACCESSIBLE_NODES = new DetailTableType("Nodes", "accessibleNodes");

  static readonly PERSON = new DetailTableType("Persons", "persons");

  static readonly PERSONS = new DetailTableType("Persons", "persons");

  static readonly PREVIOUS = new DetailTableType("Previous", "previous");

  static readonly PIVOT_PATHS = new DetailTableType("Pivot Paths", "pivotPaths");

  static readonly RESOURCES = new DetailTableType("Resources", "resources");

  static readonly ACCESS_POINTS = new DetailTableType("Access Points", "accessPoints");

  static readonly ACCESS_POINT_CATEGORIES = new DetailTableType("Access Point Categories", "accessPointCategories");

  static readonly ATTACK_VECTORS = new DetailTableType("Attack Vectors", "attackVectors");

  static readonly ATTACK_PATHS = new DetailTableType("Attack Paths", "attackPaths");

  static readonly ATTACK_VECTOR_CATEGORIES = new DetailTableType("Attack Vector Categories", "attackVectorCategories");

  static readonly ATTACK_SURFACES = new DetailTableType("Attack Surfaces", "attackSurfaces");

  static readonly ATTACK_SURFACE_CATEGORIES = new DetailTableType(
    "Attack Surface Categories",
    "attackSurfaceCategories"
  );

  static readonly ENTRY_POINTS = new DetailTableType("Entry Points", "entryPoints");

  static readonly ENTRY_POINT_CATEGORIES = new DetailTableType("Entry Point Categories", "entryPointCategories");

  static readonly RISKS = new DetailTableType("Risks", "risks");

  static readonly RISK = new DetailTableType("Risk", "risks");

  static readonly DEP_RISKS = new DetailTableType("Risks", "depRisks");

  static readonly DEP_ASSETS = new DetailTableType("Assets", "depAssets");

  static readonly ASSETS = new DetailTableType("Assets", "assets");

  static readonly ATTACKS = new DetailTableType("Attacks", "attacks");

  static readonly ATTACK = new DetailTableType("Attack", "attacks");

  static readonly MITRE_ATTACK = new DetailTableType("MITRE ATT&CK", "mitreAttack");

  static readonly THREAT_CATALOGS = new DetailTableType("Threat Catalogs", "threatCatalogs");

  static readonly THREAT_EVENTS = new DetailTableType("Threat Events", "threatEvents");

  static readonly THREAT_EVENT_PATTERNS = new DetailTableType("Threat Event Patterns", "threatEventPatterns");

  static readonly THREAT_EVENT_CATEGORIES = new DetailTableType("Threat Event Categories", "threatEventCategories");

  static readonly THREAT_TIERS = new DetailTableType("Threat Tiers", "threatTiers");

  static readonly THREAT_GROUPS = new DetailTableType("Threat Groups", "threatGroups");

  static readonly DEP_UNDESIRED_EVENTS = new DetailTableType("Undesired Events", "depUndesiredEvents");

  static readonly UNDESIRED_EVENTS = new DetailTableType("Undesired Events", "undesiredEvents");

  static readonly VULNERABILITIES = new DetailTableType("Vulnerability", "vulnerabilities");

  static readonly VULNERABILITY_CONDITIONS = new DetailTableType("Vulnerability Conditions", "vulConditions");

  static readonly VULNERABILITY_FINDINGS = new DetailTableType("Vulnerability Findings", "vulFindings");

  static readonly CVE_FINDINGS = new DetailTableType("CVE Findings", "cveFindings");

  static readonly VULNERABILITY_CATEGORIES = new DetailTableType("Vulnerability Categories", "vulnerabilityCategories");

  static readonly ALLOCATED_CONTROLS = new DetailTableType("Allocated Controls", "allocControls");

  static readonly MITRE_D3FEND = new DetailTableType("MITRE D3FEND", "mitreD3fend");

  static readonly MITRE_DIGITAL_ARTIFACTS = new DetailTableType("MITRE Digital Artifacts", "mitreDigitalArtifacts");

  static readonly ATTACKERS = new DetailTableType("Attackers", "attackers");

  static readonly ATTACKER_CATEGORIES = new DetailTableType("Attacker Categories", "attackerCategories");

  static readonly SYSTEM = new DetailTableType("System", "system");

  static readonly SYSTEM_ASSETS = new DetailTableType("System Assets", "systemAssets");

  static readonly SYSTEM_ASSET_TYPES = new DetailTableType("System Asset Types", "systemAssetTypes");

  static readonly CATEGORIZATION = new DetailTableType("Categorization", "categorization");

  static readonly CONTROLS = new DetailTableType("Controls", "controls");

  static readonly ACCESS = new DetailTableType("Access", "access");

  static readonly CONTROL_TYPES = new DetailTableType("Control Types", "controltypes");

  static readonly CONTROL_FAMILIES = new DetailTableType("Control Families", "controlFamilies");

  static readonly ATTACK_MECHANISM = new DetailTableType("Attack Mechanism", "attackmechanisms");

  static readonly ATTACK_TACTIC = new DetailTableType("Attack Tactic", "attacktactics");

  static readonly ATTACK_KINDS = new DetailTableType("Attack Kinds", "attackkinds");

  static readonly TARGETS = new DetailTableType("Targets", "targets");

  static readonly PARENT = new DetailTableType("Parent", "parent");

  static readonly PRODUCER = new DetailTableType("Producer", "producer");

  static readonly CONSUMER = new DetailTableType("Consumer", "consumer");

  static readonly PRODUCING_ACTIVITY = new DetailTableType("Producing Activity", "producingActivity");

  static readonly CONSUMING_ACTIVITY = new DetailTableType("Consuming Activity", "consumingActivity");

  static readonly KNOWLEDGEBASE = new DetailTableType("Knowledgebase", "kb");

  static readonly STANDARD_BASELINE = new DetailTableType("Standard Baseline", "standardBaseline");

  static readonly ORGANIZATIONS = new DetailTableType("Organizations", "organizations");

  static readonly USER = new DetailTableType("Users", "users");

  static readonly TOPIC = new DetailTableType("Topic", "topics");

  static readonly VARIANTS = new DetailTableType("Variants", "variants");

  static readonly CONTROL_CATALOGS = new DetailTableType("Control Catalogs", "controlcatalogs");

  static readonly SCTM = new DetailTableType("SCTM", "sctm");

  static readonly ASSET_CATEGORIES = new DetailTableType("Asset Categories", "assetcats");

  static readonly RISK_CATEGORIES = new DetailTableType("Risk Categories", "riskcats");

  static readonly PROJECTS = new DetailTableType("Project", "projects");

  static readonly SECURITY_OBJECTIVES = new DetailTableType("Security Objectives", "securityObjectives");

  static readonly CATEGORIZED_ENTITIES = new DetailTableType("Categorized Entities", "categorizedEntities");

  static readonly ELEMENT_CATEGORIES = new DetailTableType("Element Categories", "elementCategories");

  static readonly BUSES = new DetailTableType("Buses", "buses");

  static readonly LINKS = new DetailTableType("Links", "links");

  static readonly TTPS = new DetailTableType("TTPS", "ttps");

  static readonly HARM_CATEGORIES = new DetailTableType("Harm Categories", "harmCategories");

  static readonly FACT_ANALYSIS_RPT = new DetailTableType("Fact Analysis Report", "factAnalysisReport");

  static readonly FACT_ANALYSIS_INFORMATION_LEVEL_RPT = new DetailTableType(
    "Fact Analysis Information Level Report",
    "factAnalysisInformationLevelReport"
  );

  static readonly FACT_ANALYSIS_INVENTORY_RPT = new DetailTableType(
    "Fact Analysis Inventory Report",
    "factAnalysisInventoryReport"
  );

  static readonly CNTRL_GUINDANCE_RPT = new DetailTableType("Control Guidance Report", "controlGuidanceReport");

  static readonly CNTRL_RPT = new DetailTableType("Control Report", "controlReport");

  static readonly CSF_RPT = new DetailTableType("Cyber Security Framework (CSF) Report", "csfReport");

  static readonly CSA_KPP_RPT = new DetailTableType("CSA KPP Report", "csaKppReport");

  static readonly VARIANT_DIFF_RPT = new DetailTableType("Variant Difference Report", "variantDifferenceReport");

  static readonly SCTM_COMPL_RPT = new DetailTableType("SCTM Compliance Report", "sctmComplianceReport");

  static readonly SYSTEM_CWE_RPT = new DetailTableType("System CWE Guidance Report", "systemCWEGuidanceReport");

  static readonly CWE_RPT = new DetailTableType("CWE Report", "cweReport");

  static readonly RAR_RPT = new DetailTableType("Risk Assessment Report (RAR)", "rarReport");

  static readonly TRA_RPT = new DetailTableType("Threat Risk Analysis (TRA) Report", "traReport");

  static readonly TRA_RPT_TMP = new DetailTableType(
    "Threat Risk Analysis (TRA) Report Templates Import",
    "traReportTemplate"
  );

  static fromKey(key: string) {
    const result = DetailTableType.values.find((v) => v.key === key);
    if (result) return result;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the key ${key}.`);
  }

  static fromName(name: string) {
    const result = DetailTableType.values.find((v) => v.name === name);
    if (result) return result;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the name ${name}.`);
  }

  public readonly name: string;

  public readonly key: string;

  public readonly isDisabled: boolean;

  constructor(name: string, key: string, isDisabled: boolean = false) {
    this.name = name;
    this.key = key;
    this.isDisabled = isDisabled;
    DetailTableType.values.push(this);
  }

  toJSON() {
    return this.key;
  }
}

export default DetailTableType;

import { useQuery } from "@tanstack/react-query";

import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementMap } from "./types/elementInterface";
import { responseAsArray } from "./utils/responseAsArray";

interface IgetElementMitreDigitalArtifacts {
  elementId: string;
  elementName: string;
}

/**
 * returns list of all digital artifacts
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of selected element
 * @returns list of allocated digital artifacts for any given selected element
 */

export const getElementMitreDigitalArtifacts = ({ elementId, elementName }: IgetElementMitreDigitalArtifacts) => {
  const mitreDefendMap: IElementMap = {
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllDefensivetechnique(id),
    [DetailTableType.SYSTEM_ASSET_TYPES.key]: (id) => Brm.systemAssetTypeApi.getSystemAssetTypeDigitalartifact(id),
  };

  return responseAsArray({ map: mitreDefendMap, elementId, elementType: elementName });
};

export interface IElementMitreDigitalArtifactsProps {
  elementId: string;
  elementName: string;
  options?: QueryOption<typeof getElementMitreDigitalArtifacts>;
}

/**
 * custom-hook to retreive all digital artifacts of any selected element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of the selected element
 * @param {object} config - configuration of react-query
 * @returns react-query for getting allocated controls
 */
export const useElementMitreDigitalArtifacts = ({
  elementId,
  elementName,
  options,
}: IElementMitreDigitalArtifactsProps) => {
  const elementAllocatedControlsQuery = useQuery({
    ...options,
    queryKey: KeyFactory.digitalArtifactsKeys.element(elementId, elementName),
    queryFn: () =>
      getElementMitreDigitalArtifacts({
        elementId,
        elementName,
      }),
  });

  return elementAllocatedControlsQuery;
};

import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import SystemAssetTable from "brm/tables/details/SystemAssetTable";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import PropTypes from "prop-types";
import { DetailTableType } from "features/brm";
import { CenterText } from "components/elements";

const riskMenu = [DetailTableType.RISKS, DetailTableType.ASSETS, DetailTableType.UNDESIRED_EVENTS];

const attacksMenu = [
  DetailTableType.THREAT_EVENTS,
  DetailTableType.ATTACKS,
  DetailTableType.ATTACKERS,
  DetailTableType.MITRE_ATTACK,
];

const vulnerabilityMenu = [
  DetailTableType.VULNERABILITY_CONDITIONS,
  { ...DetailTableType.VULNERABILITY_FINDINGS, isDisabled: true },
];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS, DetailTableType.CONTROL_TYPES, DetailTableType.MITRE_D3FEND];

const systemMenu = [DetailTableType.TARGETS, { ...DetailTableType.TOPIC, isDisabled: true }];

const AttackDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.ATTACKS.key;

  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedSystemContext, setSelectedSystemContext] = React.useState(DetailTableType.TARGETS.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(DetailTableType.VULNERABILITY_CONDITIONS.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [curr, setCurr] = React.useState("");
  const onSelect = (index) => {
    setTabIndex(index);
  };

  return (
    <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedSystemContext}
            curr={curr}
          />
        </Tab>
        <Tab>{DetailTableType.ACCESS.name}</Tab>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM_ASSETS.name)}>{DetailTableType.SYSTEM_ASSETS.name}</Tab>

        <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.RISK.name}
            menu={riskMenu}
            setSelectedContext={setSelectedRiskContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.ATTACK.name}
            menu={attacksMenu}
            setSelectedContext={setSelectedAttackContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.VULNERABILITIES.name}
            menu={vulnerabilityMenu}
            setSelectedContext={setSelectedVulContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.CONTROLS.name}
            menu={controlsMenu}
            setSelectedContext={setSelectedControlsContext}
            curr={curr}
          />
        </Tab>
      </TabList>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedSystemContext}
        />
      </TabPanel>
      <TabPanel>
        <CenterText msg="Access is not applicable" />
      </TabPanel>
      <TabPanel>
        <SystemAssetTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} showDelete={false} />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedRiskContext}
        />
      </TabPanel>
      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedAttackContext}
        />
      </TabPanel>
      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedVulContext}
        />
      </TabPanel>
      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedControlsContext}
        />
      </TabPanel>
    </Tabs>
  );
};

AttackDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default AttackDetails;

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./defensive-techniques-query-key-factory";

interface IDefensiveTechniqueProps {
  id: string;
}

export const getDefensiveTechnique = ({ id }: IDefensiveTechniqueProps) => {
  return Brm.defensiveTechniqueApi.getDefensiveTechniqueByID(id);
};

interface IUseDefensiveTechnique {
  id: string;
  options?: QueryOption<typeof getDefensiveTechnique>;
}

/**
 * React-query hook for getting defensive technique
 *
 * @param
 * @returns react-query for getDefensiveTechnique
 */
export const useDefensiveTechnique = ({ id, options }: IUseDefensiveTechnique) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.defensiveTechniquesQueryKey.technique(id),
    queryFn: () => getDefensiveTechnique({ id }),
  });
};

import * as Recoil from "recoil";
import * as React from "react";
import toast from "react-hot-toast";
import { useRoles } from "features/brm/hooks/useRoles";
import { userRoleState, isPublishingState, userIdState } from "atoms/atoms-admin";
import { isRiskAnalyst as isRiskAnalystFunc } from "services/user";
import { useProject } from "../useProject";
import * as ConfigurationApi from "../../api/component/system";
import * as AdminApi from "../../api/admin";
import {
  // isCurrentProjectActiveState,
  isCurrentProjectSuspendedState,
  isCurrentProjectPrepState,
} from "../../stores/atoms-project";

export const useSwitchRole = ({ onPublish }: { onPublish: () => void }) => {
  // Global State
  const [userId] = Recoil.useRecoilState(userIdState);
  // const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);
  const setCurrentUserRole = Recoil.useSetRecoilState(userRoleState);
  const setIsPublishing = Recoil.useSetRecoilState(isPublishingState);
  const isCurrentProjectSuspended = Recoil.useRecoilValue(isCurrentProjectSuspendedState);
  const isCurrentProjectInPrep = Recoil.useRecoilValue(isCurrentProjectPrepState);

  // Hooks
  const [currentProject, { resetCurrentProject }] = useProject();
  const { isSystemEngineer, roleHasProjectContext } = useRoles();
  const { canPublish } = ConfigurationApi.useCanPublish();

  // Derived State
  const projId = currentProject?.id || "";

  const { data: projectAccessLevel } = AdminApi.useProjectAccess({
    projectId: projId,
    options: { enabled: !!currentProject },
  });

  // Queries
  const { data: system } = ConfigurationApi.useSystems({
    projectId: projId,
    options: {
      enabled: !!projId && currentProject !== null && roleHasProjectContext() && !!projectAccessLevel,
    },
  });

  const { mutate: publishSystemProject } = ConfigurationApi.usePublishSystem({
    projectId: projId,
  });

  const { mutateAsync: setNewUserRole } = AdminApi.useSetUserRole();

  // const roleHasProject = (userRole: string) => {
  //   return isRiskAnalystFunc(userRole) || isSystemEngineerFunc(userRole) || isThreatAnalystFunc(userRole);
  // };

  const setUserRole = React.useCallback(
    async (selRole: any) => {
      const meetsPublishingConditions =
        isSystemEngineer && system && isRiskAnalystFunc(selRole) && isCurrentProjectInPrep;

      if (meetsPublishingConditions && canPublish()) {
        setIsPublishing(true);
        publishSystemProject(
          { systemId: system.id },
          {
            onSuccess: async () => {
              setIsPublishing(false);
              onPublish();
              await setNewUserRole(
                { userId, role: selRole },
                {
                  onSuccess: () => {
                    setCurrentUserRole(selRole);
                    if (isCurrentProjectSuspended || !roleHasProjectContext(selRole)) {
                      resetCurrentProject();
                    }
                  },
                }
              );
            },
          }
        );
      } else {
        if (meetsPublishingConditions) {
          toast.error(`${currentProject?.name} not published - no Nodes`, {
            duration: 4000,
          });
        }

        await setNewUserRole(
          { userId, role: selRole },
          {
            onSuccess: () => {
              setCurrentUserRole(selRole);
              if (isCurrentProjectSuspended || !roleHasProjectContext(selRole)) {
                resetCurrentProject();
              }
            },
          }
        );
      }
    },
    [
      canPublish,
      currentProject?.name,
      isCurrentProjectInPrep,
      isCurrentProjectSuspended,
      isSystemEngineer,
      onPublish,
      publishSystemProject,
      resetCurrentProject,
      roleHasProjectContext,
      setCurrentUserRole,
      setIsPublishing,
      setNewUserRole,
      system,
      userId,
    ]
  );

  return { setUserRole };
};

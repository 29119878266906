import Title from "components/elements/StyledTitle";
import { DefenseApi } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import * as S from "features/global-threat/style/property.style";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { useParams } from "react-router-dom";
import { DigitalArtifactTabs } from "features/mitre-d3fend/components/tabs/DigitalArtifactTabs";

export const DigitalArtifact = () => {
  const { id } = useParams();

  const {
    data: digitalArtifact,
    isError: isDigitalArtifactError,
    error: digitalArtifactError,
  } = DefenseApi.useDigitalArtifact({
    id: id || "",
    options: {
      enabled: id !== undefined,
    },
  });

  if (isDigitalArtifactError) {
    return <ErrorBanner msg={(digitalArtifactError as any)?.statusText} />;
  }

  if (digitalArtifact) {
    return (
      <>
        <Title>Digital Artifact: {digitalArtifact.name}</Title>
        <S.PropertyContainer>
          <S.PropertyLabel>Name</S.PropertyLabel>
          <div>{digitalArtifact.name}</div>
          <S.PropertyLabel>Description</S.PropertyLabel>
          <div>{digitalArtifact.description}</div>
          <S.PropertyLabel>Source Id</S.PropertyLabel>
          <div>{digitalArtifact.sourceId}</div>
          <S.PropertyLabel>Parent</S.PropertyLabel>
          <div>{digitalArtifact.parentname}</div>
        </S.PropertyContainer>

        <DetailsContainerParent>
          <DetailsContainer>
            <DigitalArtifactTabs />
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }

  return <Loading />;
};

import * as React from "react";
import Form from "react-bootstrap/Form";
import { ActionButton } from "components/elements";
import { KnowledgebaseApi, ExportApi, useRoles } from "features/brm";
import { Select } from "components/elements/Select";
import { type Option, type DiffSource, IKnowledgebaseObj } from "./types";
import * as S from "./KnowledgebaseDiffForm.styles";
import { useKbDiff } from "./useKbDiff";
import { useKbDiffToCsv } from "./useKbDiffToCsv";

interface IKnowledgebaseDiffFormProps {
  onClose: () => void;
}

const toOption = (kb: IKnowledgebaseObj) => {
  return { label: kb.name, value: kb.id };
};

export const KnowledgebaseDiffForm = ({ onClose }: IKnowledgebaseDiffFormProps) => {
  const { isCyberSecurityExpert } = useRoles();
  const { data: kbData } = KnowledgebaseApi.useAllKbs({ options: { enabled: isCyberSecurityExpert } });
  const [doDiff, setDoDiff] = React.useState(false);
  const [showDiffEmptyError, setShowDiffEmptyError] = React.useState(false);
  const [kbAId, setKbAId] = React.useState<string | undefined>();
  const [kbBId, setKbBId] = React.useState<string | undefined>();

  const { calculateDiff } = useKbDiff();
  const { convertKbDiffToCsv } = useKbDiffToCsv();

  const handleChange = (diffSource: DiffSource, option: Option | null) => {
    const setDiffSource = diffSource === "A" ? setKbAId : setKbBId;
    setDiffSource(option?.value);
    setShowDiffEmptyError(false);
  };

  const { data: kbARuleSetData } = ExportApi.useExportKnowledgebaseQuery({
    kbId: kbAId || "",
    format: "JSON",
    options: { enabled: !!kbAId && kbAId !== "" },
  });
  const { data: kbBRuleSetData } = ExportApi.useExportKnowledgebaseQuery({
    kbId: kbBId || "",
    format: "JSON",
    options: { enabled: !!kbBId && kbBId !== "" },
  });

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (kbAId && kbBId) {
      setDoDiff(true);
    }
  };

  React.useEffect(() => {
    async function parseBlobs() {
      const kbAJson = JSON.parse(await kbARuleSetData.text());
      const kbBJson = JSON.parse(await kbBRuleSetData.text());

      const { result, isEmpty } = calculateDiff(kbAJson, kbBJson);
      setDoDiff(false);

      if (!isEmpty) {
        const kbA = kbData.filter((kb: IKnowledgebaseObj) => kbAId === kb.id)[0];
        const kbB = kbData.filter((kb: IKnowledgebaseObj) => kbBId === kb.id)[0];
        const { ruleCsv, ueCsv } = convertKbDiffToCsv(kbA, kbB, result);

        const contentType = "text/csv";
        const a = document.createElement("a");
        const csvFile = new Blob([ruleCsv], { type: contentType });
        a.download = `ATK_${kbA.name}_${kbB.name}_diff.csv`;
        a.href = window.URL.createObjectURL(csvFile);
        a.textContent = "Download CSV";
        a.click();

        const a2 = document.createElement("a");
        const csvFile2 = new Blob([ueCsv], { type: contentType });
        a2.download = `UE_${kbA.name}_${kbB.name}_diff.csv`;
        a2.href = window.URL.createObjectURL(csvFile2);
        a2.textContent = "Download CSV";
        a2.click();

        onClose();
      } else {
        setShowDiffEmptyError(true);
      }
    }

    if (doDiff && kbARuleSetData && kbBRuleSetData) {
      parseBlobs();
    }
  }, [
    kbARuleSetData,
    kbBRuleSetData,
    doDiff,
    setDoDiff,
    onClose,
    calculateDiff,
    convertKbDiffToCsv,
    kbData,
    kbAId,
    kbBId,
  ]);

  const kbAFiltered = kbData.filter((kb: IKnowledgebaseObj) => (kbBId ? kbBId !== kb.id : kb));
  const kbBFiltered = kbData.filter((kb: IKnowledgebaseObj) => (kbAId ? kbAId !== kb.id : kb));
  const kbAOptions = kbAFiltered.map((kb: IKnowledgebaseObj) => toOption(kb));
  const kbBOptions = kbBFiltered.map((kb: IKnowledgebaseObj) => toOption(kb));

  return (
    <S.Container>
      <S.Form>
        <div>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="knowledgebaseA">Knowledgebase A</Form.Label>
            <Select
              isClearable
              onChange={(o: Option | null) => {
                handleChange("A", o);
              }}
              name="knowledgebaseA"
              options={kbAOptions}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="knowledgebaseB">Knowledgebase B</Form.Label>
            <Select
              id="knowledgebaseB"
              name="knowledgebaseB"
              aria-label="Select Knowledgebase"
              isClearable
              onChange={(o: Option | null) => {
                handleChange("B", o);
              }}
              options={kbBOptions}
            />
          </Form.Group>
          {showDiffEmptyError && <span>The resulting diff is empty, choose different Knowledgebases</span>}
        </div>
        <S.ButtonContainer>
          <ActionButton onClick={onClose}>Cancel</ActionButton>
          <ActionButton onClick={handleSubmit} disabled={!(kbAId && kbBId) || showDiffEmptyError}>
            Diff
          </ActionButton>
        </S.ButtonContainer>
      </S.Form>
    </S.Container>
  );
};

import { useState, useCallback } from "react";

// Constants
import { ImportApi, useProject } from "features/brm";

// Styles
import * as S from "./CveScan.styles";

import { Step2 } from "./Step2";

interface IOptionsProps {
  nodes: {
    selection: string[];
    values: string[];
  };
}

interface ICveScanProps {
  handleSubmit: (arg: IOptionsProps) => void;
  options: IOptionsProps;
  setUploadModalIsOpen: (arg: boolean) => {};
  submissionId: string;
}

export const CveScan = ({ options, handleSubmit, setUploadModalIsOpen, submissionId }: ICveScanProps) => {
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);

  // inputs
  const [, { projectId }] = useProject();
  const { mutate: cancelIimport } = ImportApi.useCancelImport();

  const [postError, setPostError] = useState("");

  const handleSubmission = useCallback(() => {
    function validate() {
      if (selectedNodes.length > 0) {
        return true;
      }
      return false;
    }

    const isValid = validate();

    if (isValid) {
      setPostError("");
      const result = options;
      result.nodes.selection = selectedNodes;
      handleSubmit(result);
    } else {
      setPostError("Please select a node to submit!");
    }
  }, [handleSubmit, options, selectedNodes]);

  const handleCancel = useCallback(() => {
    cancelIimport({ projectId, runId: submissionId });
  }, [cancelIimport, projectId, submissionId]);

  return (
    <S.ScanWizard>
      <div style={{ padding: "10px" }} id="CVEScan_title">
        <h4 className="wizardTitle">CVE Scan Wizard</h4>
      </div>
      <Step2
        options={options}
        handleSubmission={handleSubmission}
        setUploadModalIsOpen={setUploadModalIsOpen}
        nodes={selectedNodes}
        setNodes={setSelectedNodes}
        postError={postError}
        onCancel={handleCancel}
      />
    </S.ScanWizard>
  );
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./defensive-tactics-query-key-factory";

interface IGetDefensiveTacticsProp {
  catalog?: string;
}

export const getDefensiveTactics = ({ catalog }: IGetDefensiveTacticsProp) => {
  return Brm.defensiveTacticApi.findDefensiveTactic({ catalog });
};

interface IUseDefensiveTactics {
  catalog?: string;
  options?: QueryOption<typeof getDefensiveTactics>;
}

/**
 * React-query hook for getting all defend Catalogs
 *
 * @param
 * @returns react-query for getDefendCatalogs
 */
export const useDefensiveTactics = ({ catalog, options }: IUseDefensiveTactics = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.defensiveTacticsQueryKey.catalog(catalog),
    queryFn: () => getDefensiveTactics({ catalog }),
  });
};

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-threat-query-key-factory";

interface IThreatSoftwareProps {
  catalog?: string;
}
export const getThreatSoftware = ({ catalog }: IThreatSoftwareProps) => {
  return Brm.threatSoftwareApi.findThreatSoftware({ catalog });
};

interface IUseThreatSoftware {
  catalog?: string;
  options?: QueryOption<typeof getThreatSoftware>;
}

/**
 * React-query hook for getting threat software
 *
 * @param {string} catalog -
 * @param {object} options - react-query configuration
 * @returns react-query result
 */
export const useThreatSoftware = ({ catalog, options }: IUseThreatSoftware = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatSoftware.catalog(catalog),
    queryFn: () => getThreatSoftware({ catalog }),
  });
};

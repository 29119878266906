import * as Recoil from "recoil";
import * as React from "react";

import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { UploadButton, LoadingSpinner, DownloadButton } from "components/elements";
import { UploadHistoryTable } from "features/admin";
import { Wizard } from "features/importer";
import { ExportWizard } from "features/exporter";
import systemModals from "components/modals/Modals";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { COMMON } from "constants/brm";
import ShowHideButton from "components/elements/ShowHideButton";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import {
  createColumnImportedAt,
  createColumnExportedAt,
  createColumnModifiedAt,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { useQueryClient } from "@tanstack/react-query";
import {
  isCurrentProjectActiveState,
  useProject,
  ImportContext,
  ExportContext,
  useRun,
  MitigationApi,
} from "features/brm";
import { useSctm, useSctmById } from "../../api";
import { SctmTab } from "../../components/tabs/SctmTab";

export function SctmOverview() {
  let content = null;
  const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);
  const queryClient = useQueryClient();
  const projectId = Recoil.useRecoilValue(projectIdState);
  const variantId = Recoil.useRecoilValue(variantIdState);
  const [runId, { setRunIdDefault }] = useRun();
  const [currentProject] = useProject();
  const [selectedElement, setSelectedElement] = React.useState({});
  const [sctmId, setSctmId] = React.useState("");
  const [, setSelectedRowId] = React.useState("");
  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = React.useState(false);
  const [showSummaryTable, setShowSummaryTable] = React.useState(true);

  const {
    data: sctmShortData,
    isError: isSctmShortDataError,
    // error: sctmShortDataError,
  } = useSctm(projectId, variantId);

  React.useEffect(() => {
    if (sctmShortData) setSctmId(sctmShortData[0].id);
  }, [sctmShortData]);

  const {
    data: sctmData,
    isError: isSctmDataError,
    // error: sctmDataError,
  } = useSctmById({
    sctmId,
    options: {
      enabled: !!sctmId && isCurrentProjectActive,
    },
  });

  const createButton = React.useCallback(() => {
    return (
      <div>
        <UploadButton onClick={() => setUploadModalIsOpen(true)} uploadTitle="Import SCTM" />
        <DownloadButton onClick={() => setExportModalIsOpen(true)} buttonTitle="Export SCTM" />
      </div>
    );
  }, []);

  const columns = React.useMemo(
    () => [
      createColumnMappedNoEdit("cntrlCatalog"),
      createColumnMappedNoEdit("baseline"),
      createColumnMappedNoEdit("variant"),
      createColumnBooleanNoEditMapped("isImported"),
      createColumnImportedAt(),
      createColumnExportedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton]
  );

  React.useEffect(() => {
    queryClient.invalidateQueries(MitigationApi.sctmKeys.sctmFullDetails(sctmId));
  }, [queryClient, sctmId, uploadModalIsOpen]);

  React.useEffect(() => {
    if (sctmData) {
      setSelectedElement({
        id: sctmData.id,
        name: sctmData.name,
      });
    }
  }, [sctmData]);

  if (sctmData) {
    content = (
      <BrmSingleElementTable
        data={[sctmData]}
        columns={columns}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        customProps={{ id: "SCTMFullDetails_table" }}
      />
    );
  } else {
    content = <LoadingSpinner />;
  }

  if (isSctmShortDataError) {
    // console.error("err in useSctm:", sctmShortDataError);
    content = "Error in setting Data";
    if (isSctmDataError) {
      // console.error("err in useSctmById:", sctmDataError);
      content = "Error in setting/getting Data";
    }
  } else if (isSctmDataError) {
    // console.error("err in useSctmById:", sctmDataError);
    content = "Error in getting Data";
  }

  if (!variantId || variantId === COMMON.nullUuid) {
    return (
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <h3>Select a user defined variant to view Sctm</h3>
      </div>
    );
  }

  return (
    <>
      <Title>
        {selectedElement.name}
        <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
      </Title>

      {showSummaryTable ? (
        <DetailsContainerSingleTable>
          {content}

          <br />
          <UploadHistoryTable
            uploadModalIsOpen={uploadModalIsOpen}
            context={ImportContext.sctm}
            runId={runId.runIdDefault}
            setRunId={setRunIdDefault}
          />
        </DetailsContainerSingleTable>
      ) : (
        <></>
      )}

      {uploadModalIsOpen &&
        systemModals.customModal(
          "SCTMFUllDetails",
          uploadModalIsOpen,
          setUploadModalIsOpen,
          <Wizard
            setUploadModalIsOpen={setUploadModalIsOpen}
            projectId={projectId}
            sctmId={sctmId}
            context={ImportContext.sctm}
          />,
          "SCTM Importer"
        )}

      {exportModalIsOpen &&
        systemModals.customModal(
          "SCTMFUllDetails",
          exportModalIsOpen,
          setExportModalIsOpen,
          <ExportWizard
            filePrefix={currentProject.name}
            setExportModalIsOpen={setExportModalIsOpen}
            projectId={projectId}
            sctmId={sctmId}
            exportContext={ExportContext.sctm}
          />,
          "SCTM Exporter"
        )}

      <DetailsContainerParent showSummaryTable={showSummaryTable}>
        <DetailsContainer id="Sctm_detailsPanel">
          {selectedElement.id && <SctmTab selectedElement={selectedElement} />}
        </DetailsContainer>
      </DetailsContainerParent>
    </>
  );
}

export const globalThreatMetaKeys = {
  all: ["globalThreatMeta"] as const,
  meta: (name?: string, type?: any) => [...globalThreatMetaKeys.all, name, type] as const,
};

export const affectedPlatformKeys = {
  all: ["affectedPlatforms"] as const,
  catalog: (catalog?: string) => [...affectedPlatformKeys.all, "catalog", catalog] as const,
  platform: (id: string) => [...affectedPlatformKeys.all, id] as const,
};

export const offensiveTacticKeys = {
  all: ["offensiveTactics"] as const,
  catalog: (catalog?: string) => [...offensiveTacticKeys.all, "catalog", catalog] as const,
  tactic: (id: string) => [...offensiveTacticKeys.all, "tactic", id] as const,
};

export const offensiveTechniques = {
  all: ["offensiveTechniques"] as const,
  catalog: (catalog?: string) => [...offensiveTechniques.all, "catalog", catalog] as const,
  technique: (id: string) => [...offensiveTechniques.all, id] as const,
};

export const targetAssets = {
  all: ["targetAssets"] as const,
  catalog: (catalog?: string) => [...targetAssets.all, "catalog", catalog] as const,
  target: (id: string) => [...targetAssets.all, id] as const,
};

export const threatSoftware = {
  all: ["threatSoftware"] as const,
  catalog: (catalog?: string) => [...threatSoftware.all, "catalog", catalog] as const,
  software: (id: string) => [...threatSoftware.all, id] as const,
};

export const threatDataSources = {
  all: ["threatDataSources"] as const,
  catalog: (catalog?: string) => [...threatDataSources.all, "catalog", catalog] as const,
  dataSource: (id?: string) => [...threatDataSources.all, id] as const,
};

export const threatCampaign = {
  all: ["threatCampaign"] as const,
  catalog: (catalog?: string) => [...threatCampaign.all, "catalog", catalog] as const,
  campaign: (id?: string) => [...threatCampaign.all, id] as const,
};

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { controlCatalogIdState } from "atoms/atoms-global-control";
import { useRecoilState } from "recoil";
import { GlobalControlApi, DetailTableType, ImportContext, useRoles, useRun } from "features/brm";
import systemModals from "components/modals/Modals";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { COMMON } from "constants/brm";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { UploadButton, MergeButton, LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { Wizard } from "features/importer";
import { UploadHistoryTable } from "features/admin";
import * as S from "brm/styles/details-table.styles";
import { StandardBaselineTab } from "features/global-control/components/tabs/StandardBaselineTab";
import { MergeBaselineModal } from "features/global-control/components/modals/MergeBaselineModal";
import { RoutePath } from "routes/route-paths";
import { useControlCatalog } from "../../hooks";

export function StandardBaseline() {
  const { isControlsCoordinator } = useRoles();
  const elementNameInUrl = DetailTableType.STANDARD_BASELINE.key;
  const [controlCatalogId] = useRecoilState(controlCatalogIdState);
  const [selectedElement, setSelectedElement] = useState(null);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const { id } = useParams();
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [controlFamilyTypeId, setControlFamilyTypeId] = useState(id);
  const navigate = useNavigate();
  const { isReadOnlyCatalog } = useControlCatalog();
  const [runId, { setRunIdDefault }] = useRun();

  const { data: controlFammilyBaseLineData } = GlobalControlApi.useStandardBaselines({
    controlCatalogId,
    options: { enabled: !!controlCatalogId && isControlsCoordinator },
  });

  const { data, isError, error } = GlobalControlApi.useStandardBaseline({
    baselineId: controlFamilyTypeId,
    options: { enabled: !!controlFamilyTypeId && isControlsCoordinator },
  });

  useEffect(() => {
    if (controlFammilyBaseLineData && controlFammilyBaseLineData.length > 0) {
      let isControlfamilyTypeFound = false;
      for (let i = 0; i < controlFammilyBaseLineData.length; i += 1) {
        const selectedElementName = selectedElement?.name || "";
        if (controlFammilyBaseLineData[i].name === selectedElementName) {
          setControlFamilyTypeId(controlFammilyBaseLineData[i].id);
          isControlfamilyTypeFound = true;
        }
      }
      if (!isControlfamilyTypeFound && selectedElement) {
        navigate(RoutePath.StandardBaselines);
      }
    }
  }, [controlFammilyBaseLineData, navigate, selectedElement]);

  useEffect(() => {
    if (data && data.length) {
      const selectedControlType = data[0];
      setSelectedElement(selectedControlType);
    }
  }, [data]);

  if (isError) {
    return <ErrorDisplay msg={error?.statusText} />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="StandardBaselineFullDetails">
        <Title>
          {selectedElement?.name || ""}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />

          <Buttons>
            {isReadOnlyCatalog ? null : (
              <>
                <MergeButton onClick={() => setIsMergeModalOpen(true)} mergeTitle="Merge Baselines" />
                <UploadButton onClick={() => setUploadModalIsOpen(true)} uploadTitle="Import Controls" />
              </>
            )}
          </Buttons>
        </Title>

        <MergeBaselineModal
          currentBaselineId={id}
          isVisible={isMergeModalOpen}
          onClose={() => setIsMergeModalOpen(false)}
        />

        {/* modal for adding instance */}
        {uploadModalIsOpen &&
          systemModals.customModal(
            "StandardBaselineFullDetails",
            uploadModalIsOpen,
            setUploadModalIsOpen,
            <Wizard
              projectId={COMMON.nullUuid} // Send the current baseline id instead of projectId = COMMON.nullUuid
              setUploadModalIsOpen={setUploadModalIsOpen}
              context={ImportContext.baseline}
              baselineName={selectedElement?.name || ""}
              elementNameInUrl={elementNameInUrl}
            />,
            "Import Control Types"
          )}

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <UploadHistoryTable
              uploadModalIsOpen={uploadModalIsOpen}
              context={ImportContext.baseline}
              runId={runId.runIdDefault}
              setRunId={setRunIdDefault}
            />
          </DetailsContainerSingleTable>
        ) : null}

        {selectedElement?.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer>
              <StandardBaselineTab
                selectedElement={selectedElement}
                setRefresh={setRefresh}
                refresh={refresh}
                elementNameInUrl={elementNameInUrl}
              />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </S.DetailsContainer>
    );
  }

  return <Loading />;
}

import { RoutePath } from "routes/route-paths";

export const CategorizationMenu = {
  categorizedEntity: {
    name: "Categorized Entities",
    path: RoutePath.CategorizedEntities,
  },
  securityObjective: {
    name: "Security Objectives",
    path: RoutePath.SecurityObjectives,
  },
  classificationCategory: {
    name: "Classification Categories",
    path: RoutePath.ClassificationCategories,
  },
};

export const ConfigurationMenu = {
  Metadata: {
    name: "Metadata",
    path: RoutePath.Metadata,
  },
  Module: {
    name: "Module",
    path: RoutePath.Modules,
  },
  System: {
    name: "System",
    path: RoutePath.Systems,
  },
};

export const LocalThreatMenu = {
  Attackers: {
    name: "Attackers",
    path: RoutePath.Attackers,
  },
  AttackerCategories: {
    name: "Attacker Categories",
    path: RoutePath.AttackerCategories,
  },
};

export const GlobalThreatMenu = {
  ThreatTier: {
    name: "Threat Tiers",
    path: RoutePath.ThreatTiers,
  },
  ThreatCatalog: {
    name: "Threat Catalogs",
    path: RoutePath.ThreatCatalogs,
  },
  ThreatGroup: {
    name: "Threat Groups",
    path: RoutePath.ThreatGroups,
  },
  /* TTP: {
    name: "TTP",
    path: RoutePath.Ttps,
  }, */
  ThreatCampaign: {
    name: "Threat Campaigns",
    path: RoutePath.ThreatCampaigns,
  },
  ThreatSoftware: {
    name: "Threat Software",
    path: RoutePath.ThreatSoftware,
  },
  ThreatDataSource: {
    name: "Threat DataSources",
    path: RoutePath.ThreatDataSources,
  },
  OffensiveTactics: {
    name: "Offensive Tactics",
    path: RoutePath.OffensiveTactics,
  },
  OffensiveTechniques: {
    name: "Offensive Techniques",
    path: RoutePath.OffensiveTechniques,
  },
  AffectedPlatforms: {
    name: "Affected Platforms",
    path: RoutePath.AffectedPlatforms,
  },
  TargetAssets: {
    name: "Target Assets",
    path: RoutePath.TargetAssets,
  },
};

export const MitreD3fendMenu = {
  MitreD3fend: {
    name: "D3FEND Catalog",
    path: RoutePath.DefendCatalog,
  },
  DefensiveTactics: {
    name: "Defensive Tactics",
    path: RoutePath.DefensiveTactics,
  },
  DefensiveTechniques: {
    name: "Defensive Techniques",
    path: RoutePath.DefensiveTechniques,
  },
  DigitalArtifacts: {
    name: "Digital Artifacts",
    path: RoutePath.DigitalArtifacts,
  },
  CciControls: {
    name: "CCI Controls",
    path: RoutePath.CciControls,
  },
  NistControls: {
    name: "NIST Controls",
    path: RoutePath.NistControls,
  },
};

export const SystemFactsMenu = {
  ElementCategory: {
    name: "Element Categories",
    path: RoutePath.ElementCategories,
  },
  StructuralCategory: {
    name: "Structural Categories",
    path: RoutePath.StructuralCategories,
  },
  Nodes: {
    name: "Nodes",
    path: RoutePath.Nodes,
  },
  Buses: {
    name: "Buses",
    path: RoutePath.Buses,
  },
  Links: {
    name: "Links",
    path: RoutePath.Links,
  },
  Exchanges: {
    name: "Exchanges",
    path: RoutePath.Exchanges,
  },
  Datatypes: {
    name: "Datatypes",
    path: RoutePath.Datatypes,
  },
  Resources: {
    name: "Resources",
    path: RoutePath.Resources,
  },
  Persons: {
    name: "Persons",
    path: RoutePath.Persons,
  },
  Dataflows: {
    name: "Dataflows",
    path: RoutePath.Dataflows,
  },
  Capabilities: {
    name: "Capabilities",
    path: RoutePath.Capabilities,
  },
  Activities: {
    name: "Activities",
    path: RoutePath.Activities,
  },
  Missions: {
    name: "Missions",
    path: RoutePath.Missions,
  },
  Groups: {
    name: "Groups",
    path: RoutePath.Groups,
  },
  Channels: {
    name: "Channels",
    path: RoutePath.Channels,
  },
};

export const SystemAssetsMenu = {
  SystemAsset: {
    name: "System Assets",
    path: RoutePath.SystemAssets,
  },
  SystemAssetType: {
    name: "System Asset Types",
    path: RoutePath.SystemAssetTypes,
  },
  SystemAssetCategory: {
    name: "System Asset Categories",
    path: RoutePath.SystemAssetCategories,
  },
};

export const AccessMenu = {
  EntryPoints: {
    name: "Entry Points",
    path: RoutePath.EntryPoints,
  },
  EntryPointCategory: {
    name: "Entry Point Categories",
    path: RoutePath.EntryPointCategories,
  },
  AttackVectors: {
    name: "Attack Vectors",
    path: RoutePath.AttackVectors,
  },
  AttackVectorCategory: {
    name: "Attack Vector Categories",
    path: RoutePath.AttackVectorCategories,
  },
  AttackSurface: {
    name: "Attack Surfaces",
    path: RoutePath.AttackSurfaces,
  },
  AttackSurfaceCategory: {
    name: "Attack Surface Categories",
    path: RoutePath.AttackSurfaceCategories,
  },
  AccessPoint: {
    name: "Access Points",
    path: RoutePath.AccessPoints,
  },
  AccessPointCategory: {
    name: "Access Point Categories",
    path: RoutePath.AccessPointCategories,
  },
  NodeBoundary: {
    name: "Boundary Nodes",
    path: RoutePath.Boundaries,
  },
  ActivityBoundary: {
    name: "Boundary Activities",
    path: RoutePath.BoundariesActivities,
  },
};

export const AssetMenu = {
  AssetLists: {
    name: "Assets",
    path: RoutePath.Assets,
  },
  AssetCategory: {
    name: "Asset Categories",
    path: RoutePath.AssetCategories,
  },
};

export const ImpactMenu = {
  UndesiredEvents: {
    name: "Undesired Events",
    path: RoutePath.UndesiredEvents,
  },
  HarmCategory: {
    name: "Harm Categories",
    path: RoutePath.Harms,
  },
};

export const RiskMenu = {
  RiskList: {
    name: "Risks",
    path: RoutePath.Risks,
  },
  RiskCategory: {
    name: "Risk Categories",
    path: RoutePath.RiskCategories,
  },
};

export const ThreatMenu = {
  ThreatEvents: {
    name: "Threat Events",
    path: RoutePath.ThreatEvents,
  },
  ThreatEventCategory: {
    name: "Threat Event Categories",
    path: RoutePath.ThreatEventCategories,
  },
  ThreatEventPatterns: {
    name: "Threat Event Patterns",
    path: RoutePath.ThreatEventPatterns,
  },
};

export const AttackMenu = {
  Attacks: {
    name: "Attacks",
    path: RoutePath.Attacks,
  },
  AttackKind: {
    name: "Attack Kinds",
    path: RoutePath.AttackKinds,
  },
  AttackTactic: {
    name: "Attack Tactics",
    path: RoutePath.AttackTactics,
  },
  AttackMechanism: {
    name: "Attack Mechanisms",
    path: RoutePath.AttackMechanisms,
  },
};

export const VulnerabilityMenu = {
  Vulnerability: {
    name: "Vulnerability Conditions",
    path: RoutePath.Vulnerabilities,
  },
  VulnerabilityCategory: {
    name: "Vulnerability Categories",
    path: RoutePath.VulnerabilityCategories,
  },
  SoftwareVulnerability: {
    name: "Vulnerability Findings",
    path: RoutePath.SoftwareVulnerabilities,
  },
  CveFinding: {
    name: "CVE Findings",
    path: RoutePath.CveFindings,
  },
};

export const KnowledgebaseMenu = {
  KBList: {
    name: "Knowledgebases",
    path: RoutePath.Knowledgebases,
  },
  OrgKBCOnfiguration: {
    name: "Organization KB Configuration",
    path: RoutePath.KbOrganizations,
  },
  ProjectKBConfiguration: {
    name: "Project Configuration",
    path: RoutePath.KbProjects,
  },
};

export const MitigationMenu = {
  AllocatedControls: {
    name: "Allocated Controls",
    path: RoutePath.AllocatedControls,
  },
  sctm: {
    name: "SCTM",
    path: RoutePath.Sctm,
  },
  Variant: {
    name: "Variant",
    path: RoutePath.Variants,
  },
};

export const GlobalControlMenu = {
  ControlCatalog: {
    name: "Control Catalogs",
    path: RoutePath.ControlCatalogs,
  },
  ControlType: {
    name: "Control Types",
    path: RoutePath.ControlTypes,
  },
  ControlFamily: {
    name: "Control Families",
    path: RoutePath.ControlFamilies,
  },
  StandardBaseline: {
    name: "Baselines",
    path: RoutePath.StandardBaselines,
  },
};
